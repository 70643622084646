import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Button,
  Divider,
  Skeleton,
  AutoComplete,
  Select,
  Input,
} from "antd";

const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const StayGuide = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const token = localStorage.getItem("token");
  const user_email = localStorage.getItem("email");

  const [property, setProperty] = useState([]);

  const [form, setForm] = useState({});

  useEffect(() => {}, []);

  const onFinish = async (values) => {
    const postData = {
      ...values,
      ...form,
      added_by: user_email,
      timestamp: Date.now(),
    };
    console.log(postData);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeProperty = async (name) => {
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/public/listing/search/${name}`
      );
      const data = await res.data;
      console.log(data, "dfdaf");
      setProperty(data);
    } catch (err) {
      console.log(err);
    }

    const getId = property.filter((x) => x.property_title === name);
    const id = getId.length > 0 ? getId[0].listing_id : "";
    setForm({ ...form, property_id: id, property_name: name });
  };

  return (
    <Fragment>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col lg={17}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>Stay Guide</Text>
              </Title>
            }
            bordered={true}
          >
            <Row justify="center">
              <Col lg={15}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <Col lg={24} style={{ padding: "8px" }}>
                    <AutoComplete
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Select Property"
                      onChange={onChangeProperty}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    >
                      {property.map((x, i) => {
                        return (
                          <Option key={i} value={x.property_title}>
                            {x.property_title}
                          </Option>
                        );
                      })}
                    </AutoComplete>
                  </Col>
                  <Col lg={24} style={{ margin: "8px" }}>
                    <h2>Where to Eat</h2>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      name="where_to_eat"
                      rules={[
                        {
                          required: true,
                          message: "Please input Testimonial Title",
                        },
                      ]}
                    >
                      <TextArea placeholder="Type Something.." size="large" />
                    </Form.Item>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <h2>Where to Shop</h2>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      name="where_to_shop"
                      rules={[
                        {
                          required: true,
                          message: "Please input Testimonial Title",
                        },
                      ]}
                    >
                      <TextArea placeholder="Type Something.." size="large" />
                    </Form.Item>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <h2>Where to Party</h2>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      name="where_to_party"
                      rules={[
                        {
                          required: true,
                          message: "Please input Testimonial Title",
                        },
                      ]}
                    >
                      <TextArea placeholder="Type Something.." size="large" />
                    </Form.Item>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <h2>Emergency Details</h2>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      name="emergency"
                      rules={[
                        {
                          required: true,
                          message: "Please input Testimonial Title",
                        },
                      ]}
                    >
                      <TextArea placeholder="Type Something.." size="large" />
                    </Form.Item>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <h2>Local Service Providers</h2>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      name="local_contacts"
                      rules={[
                        {
                          required: true,
                          message: "Please input Testimonial Title",
                        },
                      ]}
                    >
                      <TextArea placeholder="Type Something.." size="large" />
                    </Form.Item>
                  </Col>
                  <Col
                    lg={24}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Form.Item
                      style={{
                        margin: "8px",
                      }}
                    >
                      <Button
                        htmlType="submit"
                        size="large"
                        type="primary"
                        loading={buttonLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(StayGuide);
