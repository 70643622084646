import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Button,
  Divider,
  Skeleton,
  AutoComplete,
  Select,
  Table,
  Badge,
  Input,
} from "antd";

import Data from "../jsons/payoutView.json";
import moment from "moment";

const { Text, Title } = Typography;
const { Option } = Select;

const PayoutInfo = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {}, []);

  const [form, setForm] = useState({});

  const onFinish = async (values) => {
    const postData = {
      payout_id: Data.payout_id,
      ...values,
      ...form,
    };
    console.log(postData, "Post");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "property name",
      dataIndex: "property_name",
      key: "property_name",
    },
    {
      title: "checkin",
      dataIndex: "checkin",
      key: "checkin",
      render: (x) => {
        return moment(x).format("L");
      },
    },
    {
      title: "checkout",
      dataIndex: "checkout",
      key: "checkout",
      render: (x) => {
        return moment(x).format("L");
      },
    },
    {
      title: "Billed amount",
      dataIndex: "billed_amount",
      key: "billed_amount",
    },
    {
      title: "roamhome commision",
      dataIndex: "roamhome_commision",
      key: "roamhome_commision",
    },
    {
      title: "Homeowner payout",
      dataIndex: "homeowner_payout",
      key: "homeowner_payout",
    },
  ];

  console.log(form, "form");
  return (
    <Fragment>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col lg={24}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>Add Payout Information</Text>
              </Title>
            }
            bordered={true}
          >
            <Row justify="center">
              <Col lg={14}>
                <Row justify="space-between">
                  <div>
                    <h1>{Data.home_owner_name}</h1>
                    <p> #{Data.payout_id}</p>
                  </div>
                  <Badge
                    status={
                      Data.payout_status === "pending"
                        ? "processing"
                        : "success"
                    }
                    text={Data.payout_status}
                  />
                </Row>
              </Col>
              <Col lg={14}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <p>
                    Payouts for Checkin between{" "}
                    <b>{moment(Data.payout_from).format("L")}</b> -
                    <b> {moment(Data.payout_to).format("L")}</b>
                  </p>
                  <Col lg={24}>
                    <div>
                      <Table
                        dataSource={Data.payout_detail}
                        columns={columns}
                      />
                    </div>
                  </Col>
                  <p>
                    {" "}
                    Total Payout : <b> Rs. {Data.payout_amount}</b>
                  </p>
                  <Col lg={24} style={{ margin: "8px" }}>
                    <Row align="middle" style={{ width: "98%" }}>
                      <Col lg={6}>Payment Mode</Col>
                      <Col lg={18}>
                        <Select
                          size="medium"
                          style={{ width: "98%" }}
                          placeholder="select mode.."
                          optionFilterProp="children"
                          onChange={(e) => setForm({ payment_mode: e })}
                        >
                          <Option value="debit">Debit</Option>
                          <Option value="NEFT">NEFT</Option>
                          <Option value="UP">UPI</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} style={{ margin: "8px" }}>
                    <Row align="middle" style={{ width: "98%" }}>
                      <Col lg={6}>Transaction ID</Col>
                      <Col lg={18}>
                        <Form.Item
                          name="transaction_id"
                          style={{ width: "98%" }}
                          rules={[
                            {
                              required: true,
                              message: "please input something",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Transaction Id." />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <Row align="middle" style={{ width: "98%" }}>
                      <Col lg={6}>Paid to Account</Col>
                      <Col lg={18}>
                        <Select
                          name="account_id"
                          size="medium"
                          style={{ width: "98%" }}
                          optionFilterProp="children"
                          placeholder="select account"
                          onChange={(e) => setForm({ ...form, account_id: e })}
                        >
                          <Option value="male">RoamHome</Option>
                          <Option value="female">ThirdParty</Option>
                          <Option value="none">to me</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <Row align="middle" style={{ width: "98%" }}>
                      <Col lg={6}>Payout status</Col>
                      <Col lg={18}>
                        <Select
                          name="payout_status"
                          size="medium"
                          style={{ width: "98%" }}
                          optionFilterProp="children"
                          placeholder="select status"
                          onChange={(e) =>
                            setForm({ ...form, payout_status: e })
                          }
                        >
                          <Option value="paid">Paid</Option>
                          <Option value="pending">Pending</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={24} style={{ margin: "8px" }}>
                    <Row align="middle" style={{ width: "98%" }}>
                      <Col lg={6}>Message</Col>
                      <Col lg={18}>
                        <Form.Item name="message" style={{ width: "98%" }}>
                          <Input placeholder="Type something.." />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={24}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Form.Item
                      style={{
                        margin: "8px",
                      }}
                    >
                      <Button
                        htmlType="submit"
                        size="large"
                        type="primary"
                        loading={buttonLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(PayoutInfo);
