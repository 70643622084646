import React, { useState, Fragment, useEffect } from "react";
import uuid from "uuid/v4";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  Col,
  Select as Select1,
  Card,
  Skeleton,
  Button,
} from "antd";

import AddIcon from "@material-ui/icons/Add";

import { StepperBedroomsCreate } from "../../../../../../Redux/actions/stepper_actions";

const Bedroom = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [forms, changeForm] = useState({
    step_5: [
      {
        number_guests: "",
        number_beds: "",
        bed_type: [],
      },
    ],
  });
  const token = localStorage.getItem("token");

  async function fetchAPI() {
    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/preview/5`,
        config
      );
      const data = await res.data;
      console.log(data, "data");

      changeForm({ step_5: res.data.step_5.bedroom });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  const bed_types = [
    "King",
    "Queen",
    "California King",
    "Double",
    "Triple",
    "Bunk",
    "Floor Mattress",
    "Sofa Cum Bed",
    "Single",
    "Twin",
  ];

  useEffect(() => {
    fetchAPI();
  }, []);

  function handleAdd(e) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5 = [
      ...copy.step_5,
      {
        number_guests: "",
        number_beds: "",
        bed_type: [],
      },
    ];
    changeForm(copy);
  }

  function handleGuests(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5[i].number_guests = parseInt(e.target.value);
    changeForm(copy);
  }

  function handleBeds(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5[i].number_beds = parseInt(e.target.value);
    changeForm(copy);
  }

  function handleBedType(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5[i].bed_type = [e];
    changeForm(copy);
  }

  const onFinish = (values) => {
    const dataTo = {
      step: 5,
      ...forms,
    };
    dispatch(StepperBedroomsCreate(dataTo));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Card
        style={{ margin: "15px", borderRadius: "5px" }}
        loading={loading ? <Skeleton active /> : null}
      >
        {forms.step_5.map((x, index) => (
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
            key={uuid()}
          >
            <Col lg={24}>
              <h1>Bedroom #{index + 1}</h1>
            </Col>
            <Col lg={7} style={{ padding: "8px" }} key={uuid()}>
              <div style={{ textAlign: "left" }}>
                <label>Enter Guests</label>
              </div>{" "}
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `Please input Number Of Guests!`,
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  value={x.number_guests}
                  placeholder="Number Of Guests"
                  onChange={(e) => handleGuests(e, index)}
                />
              </Form.Item>
            </Col>
            <Col lg={7} style={{ padding: "8px" }} key={uuid()}>
              <div style={{ textAlign: "left" }}>
                <label>Enter Beds</label>
              </div>{" "}
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `Please input Number Of Beds!`,
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  value={x.number_beds}
                  placeholder="Number Of Beds"
                  onChange={(e) => handleBeds(e, index)}
                />
              </Form.Item>
            </Col>
            <Col lg={7} style={{ padding: "8px" }} key={uuid()}>
              <div style={{ textAlign: "left" }}>
                <label>Enter Bed Type</label>
              </div>{" "}
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `Please input Bed Type!`,
                  },
                ]}
              >
                <Select1
                  style={{ width: "100%" }}
                  placeholder="Bed Type"
                  value={x.bed_type}
                  onChange={(e) => handleBedType(e, index)}
                >
                  {bed_types.map((x, i) => (
                    <Select1.Option value={x}>{x}</Select1.Option>
                  ))}
                </Select1>
              </Form.Item>
            </Col>
            <Col
              lg={3}
              style={{ padding: "8px", textAlign: "center" }}
              key={uuid()}
            >
              <Button type="primary" onClick={handleAdd}>
                Add
              </Button>
            </Col>
          </Form>
        ))}
        <Form.Item>
          <Button type="submit" htmltype="submit" onClick={onFinish}>
            Submit
          </Button>
        </Form.Item>
      </Card>
    </Fragment>
  );
};

export default Bedroom;
