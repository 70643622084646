/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import uuid from "uuid/v4";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Card, Skeleton, Typography, Row, Col, Empty, message } from "antd";
import { Upload, Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { StepperMediaCreate } from "../../../../../../Redux/actions/stepper_actions";
import RLDD from "react-list-drag-and-drop/lib/RLDD";

const { Title, Text } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const Image = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [featured, setFeatured] = useState();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });

  const [call, setCall] = useState({
    feat: false,
    basic: false,
  });

  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);

  async function fetchAPI() {
    setLoading(true);
    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/preview/3`,
        config
      );
      const data = await res.data;
      const array = data.step_3.imgs.img_1920.map((item) => {
        return {
          uid: uuid(),
          name: item.id,
          status: "done",
          url: item.url,
        };
      });
      setState({
        fileList: array,
      });
      const listData = data.step_3.imgs.img_1920.map((x, i) => {
        return { id: i, url: x.url, key: x.id };
      });
      setList(listData);
      const array2 = data.step_3.featured_img.img_1920.map((y) => {
        return { imageUrl: y.url };
      });
      setImage(...array2);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchAPI();
  }, []);

  const handleCancel = () => setState({ previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleChange = ({ fileList }) => {
    setCall({ ...call, basic: true });
    setState({ fileList });
  };

  const { previewVisible, previewImage, fileList } = state;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleFeaturedChange = (info) => {
    setFeatured(info.file.originFileObj);
    setCall({ ...call, feat: true });
    getBase64(info.file.originFileObj, (imageUrl) => setImage({ imageUrl }));
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    const newList = fileList.filter((x) => x.type === "image/jpeg");
    if (newList.length > 0) {
      formData.append("step", 3);
      newList.map((x) => {
        if (x.originFileObj) {
          formData.append("upl", x.originFileObj);
        } else {
          formData.append("upl", x);
        }
      });
    }
    if (call.basic) {
      dispatch(StepperMediaCreate(formData));
    }

    if (call.feat) {
      console.log("going feat");
      const formData2 = new FormData();
      formData2.append("step", 3);
      formData2.append("upl", featured);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
          listing_id: localStorage.getItem("listing_id"),
        },
      };
      try {
        const res = await axios.post(
          "http://18.217.42.86:8000/api/v1/private/listing/create_draft/individual/uploadSingle",
          formData2,
          config
        );
        const data = await res.data;
        console.log(data, "featured");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onRemove = async (e) => {
    if (!e.lastModified) {
      const dataTo = {
        image_url: e.name,
      };
      const config = {
        headers: {
          listing_id: localStorage.getItem("listing_id"),
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const res = await axios.post(
          `http://18.217.42.86:8000/api/v1/private/listing/create_draft/image/delete`,
          dataTo,
          config
        );
        const data = await res.data;
        console.log(data, "deleted");
      } catch (err) {
        console.log(err);
      }
      console.log(e);
    }
  };

  // drag and drop and reflecting the array-------
  const handleRLDDChange = async (val) => {
    console.log(val);
    setList(val);
  };

  const handleSort = async () => {
    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    const sortList = list.map((x) => x.key);

    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/change_img_pos`,
        { image_order: sortList },
        config
      );
      const resData = res.data;
      if (resData.status) {
        message.success(resData.remarks);
        fetchAPI();
        setModal(false);
      }
      console.log(resData);
    } catch (e) {
      console.log(e);
      message.error("Action Failed");
    }
  };

  const ModalContainer = () => {
    return (
      <Modal
        style={{ top: 0 }}
        title="Change Image Order"
        visible={modal}
        onOk={() => handleSort()}
        onCancel={() => setModal(false)}
      >
        <div>
          <RLDD
            items={list}
            layout="horizontal"
            itemRenderer={(x, i) =>
              list.length > 0 ? (
                <Col key={i} lg={6} style={{ marginBottom: "1rem" }}>
                  <img src={x.url} alt="test" height="100px" />
                </Col>
              ) : (
                <Empty />
              )
            }
            onChange={handleRLDDChange}
          />
        </div>
      </Modal>
    );
  };

  return (
    <Card
      style={{ margin: "15px", borderRadius: "5px" }}
      loading={loading ? <Skeleton active /> : null}
    >
      <Title>
        <Text>Multiple Image</Text>
      </Title>
      <div className="clearfix">
        <div style={{ marginBottom: "1rem" }}>
          <Button type="primary" onClick={() => setModal(true)}>
            Rearrange Images
          </Button>
          {ModalContainer()}
        </div>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          multiple={true}
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={onRemove}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
      <br />
      <br />
      <Title>
        <Text>Featured Image</Text>
      </Title>
      <Upload
        className="avatar-uploader"
        name="avatar"
        showUploadList={false}
        onChange={handleFeaturedChange}
      >
        {image ? (
          <img src={image.imageUrl} alt="" className="avatar" />
        ) : (
          <PlusOutlined type="plus" className="avatar-uploader-trigger" />
        )}
      </Upload>
      <Button type="submit" htmltype="submit" onClick={handleSubmit}>
        Submit
      </Button>{" "}
    </Card>
  );
};

export default Image;
