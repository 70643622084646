import React, { useState, useEffect, Fragment } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const { Title, Text } = Typography;

function PublishedListings() {
  const user = localStorage.getItem("user_level");

  const [posts, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(false);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  useEffect(() => {
    fetchAPI();
    localStorage.setItem("listing_id", "");
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/homeowner/get_properties`,
        config
      );
      const data = await res.data;

      setPost(data);
      console.log(data, "response published");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    message.success("Published Listings Loaded Successfully.");
  };

  const handleReview = async (x) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/action`,
        { property_id: x.listing_id, in_review: true, action: false },
        config
      );
      const data = await res.data;

      //   setPost(data);
      console.log(data, "response published");
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisable = async (x) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/action`,
        { property_id: x.listing_id, in_review: false, action: true },
        config
      );
      const data = await res.data;

      //   setPost(data);
      console.log(data, "response published");
    } catch (err) {
      console.log(err);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Property Title",
      dataIndex: "property_title",
      key: "property_title",
      className: "table",
      align: "center",
      width: "20%",
      ...getColumnSearchProps("property_title"),
    },
    {
      title: "Listing ID",
      dataIndex: "listing_id",
      key: "listing_id",
      className: "table",
      align: "center",

      width: "30%",
      ...getColumnSearchProps("listing_id"),
    },
    {
      title: "Is Individual",
      dataIndex: "is_individual",
      className: "table",
      align: "center",
      filters: [
        { text: "Latest", value: "latest" },
        { text: "In Review", value: "in_review" },
        { text: "Oldest", value: "oldest" },
      ],
      key: "is_individual",
      //   ...getColumnSearchProps("is_individual"),
      render: (dataIndex) => {
        if (dataIndex) {
          return <p>True</p>;
        } else {
          return <p>False</p>;
        }
      },
    },
    {
      title: "Actions",
      key: "action",
      className: "table",
      align: "center",
      render: (x) => {
        if (x) {
          return (
            <Row justify="space-around">
              {" "}
              <Link to={`/dashboard/${user}/edit/published/${x.listing_id}`}>
                <Button1
                  type="primary"
                  onClick={() => {
                    localStorage.setItem("listing_id", x.listing_id);
                  }}
                >
                  View
                </Button1>
              </Link>
              <Link to={`/dashboard/${user}/block-dates/${x.listing_id}`}>
                <Button1 type="default">Block Dates</Button1>
              </Link>
            </Row>
          );
        }
      },
    },
  ];

  const handleTableChange = async (filters, sorter) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/list/${sorter.is_individual}/10/0`,
        config
      );
      const data = await res.data;
      setPost(data);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Fragment>
      <Card
        title={
          <Title level={2}>
            <Text strong>Published Listings</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
      >
        <Row>
          <Col lg={24} md={24}>
            <AntdTable
              columns={columns}
              dataSource={posts}
              onChange={handleTableChange}
              bordered
            />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}
export default PublishedListings;
