import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  message,
  Button,
  Badge,
  Divider,
  Skeleton,
} from "antd";

const { Text, Title } = Typography;

const OtaMaster = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/ota_master/list",
        config
      );
      const data = await res.data;
      setData(data);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
        ota_id: id,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/ota_master/delete",
        { delete: "ota" },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        // const filteredData = data.filter(item => item.ota_id !== id);
        // setData(filteredData);
        message.success("Successfully deleted OTA.");
      }
    } catch (err) {
      console.log(err);
      message.success("Error while deleting OTA.");
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/ota_master",
        values,
        config
      );
      const data = await res.data;
      console.log(data);
      if (data.status === 200) {
        message.success("OTA added successfully.");
        fetchAPI();
      }
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={24}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>OTA Master</Text>
              </Title>
            }
            bordered={true}
          >
            <Row>
              <Col lg={24}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="ota_name"
                    rules={[
                      { required: true, message: "Please enter OTA Name!" },
                    ]}
                  >
                    <Input placeholder="enter OTA Name" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="ota_path"
                    rules={[
                      { required: true, message: "Please enter OTA Link!" },
                    ]}
                  >
                    <Input placeholder="OTA Link" size="large" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="large"
                      loading={buttonLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Divider />
            {loading ? (
              <Skeleton active />
            ) : (
              <Row>
                {data.map((x) => {
                  return (
                    <Col lg={24}>
                      <Row
                        style={{
                          padding: "1rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col lg={3} style={{ fontSize: "15px" }}>
                          {x.ota_enabled ? (
                            <Badge
                              status="success"
                              text={x.ota_name.toUpperCase()}
                            />
                          ) : (
                            <Badge status="error" text={x.ota_name} />
                          )}{" "}
                        </Col>
                        <Col lg={12}>
                          <Button disabled>{x.ota_path}</Button>
                        </Col>
                        <Col lg={4}>
                          <Text>Synchronised with {x.ota_sync_num}</Text>{" "}
                        </Col>
                        <Col lg={3}>
                          <Button danger onClick={() => handleDelete(x.ota_id)}>
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(OtaMaster);
