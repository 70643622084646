import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import moment from "moment";
import {
  Input,
  Button,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
  Select,
  DatePicker,
  Modal,
  InputNumber,
  AutoComplete,
} from "antd";

import { withRouter } from "react-router-dom";
import { TreeSelect } from "antd";

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { Title, Text } = Typography;

const CreateCoupon = ({ history }) => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user_level");

  const [propertySearch, setPropertySearch] = useState([]);

  const property_types = [
    "Apartment",
    "Bed & Breakfast",
    "Boutique Room",
    "Cabin",
    "Castle / Fortress / Palace",
    "Cottage",
    "Farmhouse",
    "Hotel",
    "House",
    "Island",
    "Lodge",
    "Studio",
    "Tent",
    "Villa",
    "Tree House",
  ];

  const fetchAllLocations = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    const fetch = await axios.get(
      "http://18.217.42.86:8000/api/v1/private/misc/location/countries/all",
      config
    );
    const res = await fetch;
    console.log(res, "all location");
    settree(renderData(res.data));
  };

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");

  useEffect(() => {
    fetchAllLocations();
    getPropertyList();
    // render();
  }, []);

  const [toggle, handleToggle] = useState(false);
  const [max, setMax] = useState("");

  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const [treedata, settree] = useState([]);

  const renderData = (data) => {
    return data.map((c) => {
      return {
        title: c.country,
        value: c.country_id,
        children: c.country_entities.map((s) => {
          return {
            title: s.state,
            value: s.state,
            children: s.state_entities.map((a) => {
              return {
                title: a.area,
                value: a.area,
              };
            }),
          };
        }),
      };
    });
  };

  const [state, setState] = useState({
    value: [],
  });

  const [coupon, setCoupon] = useState("");
  const [type, setType] = useState();

  const [json, setJson] = useState();
  const [modal1, setModal1] = useState({
    ModalText: "City / State / Country",
    visible: false,
    confirmLoading: false,
  });

  const [modal2, setModal2] = useState({
    ModalText: "Property Type",
    visible: false,
    confirmLoading: false,
  });

  const [modal3, setModal3] = useState({
    ModalText: "List of Properties",
    visible: false,
    confirmLoading: false,
  });

  const [properType, setProperty] = useState({
    property_type: [],
  });

  const [json2, setJson2] = useState();

  const [propJson, setPropjson] = useState({});
  const [label, setLabel] = useState("Value 2");

  const handlePostData = async (desiredJson) => {
    setButton(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    console.log(desiredJson, "desiredJson");

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/booking/coupon/create",
        desiredJson,
        config
      );
      const data = await res;
      console.log(data, "post response");
      if (data.status === 200) {
        message.success("Coupon Succussfully Generated.");
        history.push(`/dashboard/${user}/coupons`);
      }
    } catch (err) {
      console.log(err);
      setButton(false);
    }

    setButton(false);
  };

  const handle = () => {
    if (type.applicable_type.name === "City/State/Country") {
      let desiredJson = {
        ...coupon,
        ...name,
        ...code,
        ...start,
        ...end,
        ...max,
        ...value,
        ...value2,
        ...type,
        list_area: [...json],
      };
      handlePostData(desiredJson);
    }
    if (type.applicable_type.name === "List Of Properties") {
      let desiredJson = {
        ...coupon,
        ...name,
        ...code,
        ...start,
        ...end,
        ...max,
        ...value,
        ...value2,
        ...type,
        // ...list_properties
      };
      handlePostData(desiredJson);
    }
    if (type.applicable_type.name === "Property Type") {
      let json = [];
      properType.map((x) => {
        json2.map((y) => {
          json.push({
            property_type: x,
            property_type_id: x,
            country_id: y.country_id,
            state: y.state,
            country: y.country,
            state_id: y.state_id,
            area_name: y.area_name,
            area_id: y.area_id,
          });
        });
      });
      let desiredJson = {
        ...coupon,
        ...name,
        ...code,
        ...start,
        ...end,
        ...max,
        ...value,
        ...value2,
        ...type,
        list_property_type: json,
      };
      handlePostData(desiredJson);
    }
  };

  const tProps = {
    value: state.value,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Please select",
  };

  const handleDrop = (value) => {
    let [id, coupon] = value.split("-");
    if (id === "3") {
      handleToggle(false);
    } else {
      handleToggle(true);
    }
    if (id === "4") {
      setLabel("Duration");
    }
    setCoupon({ coupon_type: { id: parseInt(id), name: coupon } });
  };

  const showModal = (id) => {
    if (id === "1") {
      setModal1({
        visible: true,
      });
      console.log(modal1, "click");
    } else if (id === "2") {
      setModal2({
        visible: true,
      });
    } else {
      setModal3({
        visible: true,
      });
    }
  };

  const handleType = (value) => {
    let [id, coupon] = value.split("-");
    showModal(id);
    if (id == "1") {
      setType({
        applicable_type: { id: parseInt(id), name: coupon },
        is_applicable_type_properties: false,
        is_applicable_type_area: true,
        is_applicable_type_property_type: false,
      });
    } else if (id == "2") {
      setType({
        applicable_type: { id: parseInt(id), name: coupon },
        is_applicable_type_properties: false,
        is_applicable_type_area: false,
        is_applicable_type_property_type: true,
      });
    } else {
      setType({
        applicable_type: { id: parseInt(id), name: coupon },
        is_applicable_type_properties: true,
        is_applicable_type_area: false,
        is_applicable_type_property_type: false,
      });
    }
  };

  const handleOk = () => {
    handle();
  };

  const handleCancel = (id) => {
    if (id == 1) {
      setModal1({
        visible: false,
      });
      console.log(modal1, "click");
    } else if (id == 2) {
      setModal2({
        visible: false,
      });
    } else {
      setModal3({
        visible: false,
      });
    }
  };

  const onChange1 = (value) => {
    console.log("onChange ", value);
    let con = [];
    treedata.map((c) => {
      if (value.includes(c.value)) {
        con.push({
          country: c.title,
          country_id: c.value,
          state: "0",
          state_id: "0",
          area_name: "0",
          area_id: "0",
        });
      }
      c.children.map((s) => {
        if (value.includes(s.value)) {
          con.push({
            country: c.title,
            country_id: c.value,
            state: s.title,
            state_id: s.value,
            area_name: "0",
            area_id: "0",
          });
        }
        s.children.map((a) => {
          if (value.includes(a.value)) {
            con.push({
              country: c.title,
              country_id: c.value,
              state: s.title,
              state_id: s.value,
              area_name: a.title,
              area_id: a.value,
            });
          }
        });
      });
    });
    setState({ value });
    setJson(con);
  };

  const onChange2 = (value) => {
    console.log("onChange ", value);
    let con = [];
    treedata.map((c) => {
      if (value.includes(c.value)) {
        con.push({
          country: c.title,
          country_id: c.value,
          state: "0",
          state_id: "0",
          area_name: "0",
          area_id: "0",
        });
      }
      c.children.map((s) => {
        if (value.includes(s.value)) {
          con.push({
            country: c.title,
            country_id: c.value,
            state: s.title,
            state_id: s.value,
            area_name: "0",
            area_id: "0",
          });
        }
        s.children.map((a) => {
          if (value.includes(a.value)) {
            con.push({
              country: c.title,
              country_id: c.value,
              state: s.title,
              state_id: s.value,
              area_name: a.title,
              area_id: a.value,
            });
          }
        });
      });
    });
    setState({ value });
    setJson2(con);
  };

  const handleSelect = (value) => {
    setProperty(value);
  };

  if (loading) {
    return <h1>Loading</h1>;
  }

  const getPropertyList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/list/latest/10/0`,
        config
      );
      const data = await res.data;
      console.log(data, ";popropropeop");
      setPropertySearch(data);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeProperty = async (name) => {
    console.log(name, "name");
  };
  return (
    <div>
      <Modal
        confirmLoading={button}
        title="Coupon for area wise "
        visible={modal1.visible}
        onOk={handleOk}
        onCancel={() => handleCancel("1")}
        okText="Submit"
      >
        <div style={{ padding: "2rem 5rem 5rem 2rem", height: "300px" }}>
          <h2>Select City / State / Country</h2>
          <TreeSelect
            treeData={treedata}
            onChange={onChange1}
            style={{ width: "400px" }}
            {...tProps}
          />
        </div>
      </Modal>
      <Modal
        confirmLoading={button}
        title="Property Type"
        visible={modal2.visible}
        onOk={handleOk}
        onCancel={() => handleCancel("2")}
        okText="Submit"
      >
        <div
          style={{
            margin: "0 auto",
            height: "300px",
          }}
        >
          <h1>Property Type</h1>
          <Row>
            <Col lg={20} style={{ margin: "10px" }}>
              <Select
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                onChange={handleSelect}
                placeholder="Property Type"
              >
                {property_types.map((x, i) => (
                  <Option key={i} value={x}>
                    {x}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={20} style={{ margin: "10px" }}>
              <TreeSelect
                style={{ width: "100%" }}
                size="large"
                treeData={treedata}
                onChange={onChange2}
                {...tProps}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="List of Properties"
        visible={modal3.visible}
        onOk={handleOk}
        confirmLoading={button}
        onCancel={() => handleCancel("3")}
        okText="Submit"
      >
        <div
          style={{
            margin: "0 auto",
            height: "300px",
          }}
        >
          <p>List of Properties</p>
          <Col lg={24} style={{ margin: "8px" }}>
            <AutoComplete
              style={{ width: "100%" }}
              size="large"
              placeholder="Select Property"
              onChange={onChangeProperty}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            >
              {propertySearch.map((x, i) => {
                return (
                  <Option key={i} value={x.property_title}>
                    {x.property_title}
                  </Option>
                );
              })}
            </AutoComplete>
          </Col>
        </div>
      </Modal>
      <Fragment>
        <Card
          title={
            <Title level={2}>
              <Text strong>Create Promos</Text>
            </Title>
          }
          style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
        >
          <Row>
            <Col lg={8} md={8} style={{ padding: "8px" }}>
              <Select
                size="large"
                style={{ width: "100%" }}
                onChange={handleDrop}
                placeholder="Promo Type"
              >
                <Option value="1-Buy X Get Y">Buy X Get Y</Option>
                <Option value="2-Discount Value By %">
                  Discount Value By %
                </Option>
                <Option value="3-Discount Value By Value">
                  Discount Value By Value
                </Option>
                <Option value="4-Last Minute Deals">Last Minute Deals</Option>
              </Select>
            </Col>
            <Col lg={8} md={8} style={{ padding: "8px" }}>
              <Input
                placeholder="Promo Name"
                size="large"
                name="coupon_name"
                onChange={(e) => {
                  setName({ coupon_name: e.target.value });
                }}
              />
            </Col>
            <Col lg={8} md={8} style={{ padding: "8px" }}>
              <Input
                placeholder="Promo Code"
                size="large"
                name="coupon_code"
                onChange={(e) => {
                  setCode({ coupon_code: e.target.value });
                }}
              />
            </Col>
            <Col lg={8} md={8} style={{ padding: "8px" }}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Promo Start"
                size="large"
                onChange={(date, dateString) =>
                  setStart({ coupon_start: moment(date).format() })
                }
              />
            </Col>
            <Col lg={8} md={8} style={{ padding: "8px", width: "100%" }}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Promo End"
                size="large"
                onChange={(date, dateString) =>
                  setEnd({ coupon_end: moment(date).format() })
                }
              />
            </Col>
            <Col lg={8} md={8} style={{ padding: "8px" }}>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Maximum Promo codes"
                size="large"
                name="max_coupon_usable_for"
                onChange={(value) => {
                  setMax({ max_coupon_usable_for: value });
                }}
              />
            </Col>
            <Col lg={8} md={8} style={{ padding: "8px" }}>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Promo Value"
                size="large"
                name="coupon_value"
                onChange={(value) => {
                  setValue({ coupon_value: value.toString() });
                }}
              />
            </Col>
            {!toggle ? (
              ""
            ) : (
              <Col lg={8} md={8} style={{ padding: "8px" }}>
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={label}
                  size="large"
                  name="coupon_value_2"
                  onChange={(value) => {
                    setValue2({ coupon_value_2: value.toString() });
                  }}
                />
              </Col>
            )}
            <Col lg={18} md={8} style={{ padding: "8px" }}>
              <Select
                size="large"
                style={{ width: "100%" }}
                onChange={handleType}
                placeholder="Applicable Type"
              >
                <Option value="1-City/State/Country">
                  City / State / Country
                </Option>
                <Option value="2-Property Type">Property Type</Option>
                <Option value="3-List Of Properties">List Of Properties</Option>
              </Select>
            </Col>
          </Row>
        </Card>
      </Fragment>{" "}
    </div>
  );
};

export default withRouter(CreateCoupon);
