import React from "react";
import { Row, Col } from "antd";
import Calendar from "../../components/Calendar";
import QuickListing from "../../components/QuickListing";
import Shortcut from "../../components/shortcut";
import Todo from "../../components/todoList";

const Home = () => {
  return (
    <div>
      <Row justify="space-around">
        <Col className="col" xs={{ span: 22 }} lg={{ span: 10 }}>
          <h1>Bookings</h1>
          <h1>3250</h1>
        </Col>

        <Col className="col" xs={{ span: 22 }} lg={{ span: 10 }}>
          <h1>House Keeping</h1>
          <h1>1500 /-</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Calendar />
        </Col>
      </Row>

      <Row style={{ marginTop: "2rem" }} justify="space-around">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <QuickListing />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
