import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Card, Skeleton, message, Button } from "antd";
import { useDispatch } from "react-redux";
import axios from "axios";
import { StepperAmmenitiesCreate } from "../../../../../../Redux/actions/stepper_actions";
import Select from "react-select";

const Ammenities = () => {
  const [amen, setAmen] = useState([]);
  const [faci, setFaci] = useState([]);
  const [loading, setLoading] = useState(true);
  const [amenities, setAmenities] = useState([]);
  const [facilities, setFacilities] = useState([]);

  async function fetchAPI() {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      const resAmmen = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/amenities`,
        config
      );
      const resFaci = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/facilities`,
        config
      );
      const dataFaci = await resFaci.data;

      const dataAmmen = await resAmmen.data;

      console.log(dataAmmen, "ammenities");
      console.log(dataFaci, "facilities");

      await setAmenities(dataAmmen.amenities);
      await setFacilities(dataFaci.facilities);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    message.success("Ammenities & Facilities Loaded Successfully.");
  }

  function render(data) {
    return data.map((x) => {
      return {
        value: x.name,
        label: x.name,
      };
    });
  }

  const renderAmmen = render(amenities);
  const renderFacili = render(facilities);

  useEffect(() => {
    fetchAPI();
  }, []);

  const dispatch = useDispatch();

  const sendData = (data) => {
    return data.map((x) => {
      return x.value;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit");
    const arrayAmmen = sendData(amen);
    const arrayFaci = sendData(faci);
    const dataTo = {
      amenities: arrayAmmen,
      facilities: arrayFaci,
    };

    dispatch(StepperAmmenitiesCreate(dataTo));
  };

  const onSelectAmmenities = (e) => {
    setAmen(e);
  };

  const onSelectFacilities = (e) => {
    setFaci(e);
  };

  return (
    <Fragment>
      <Card
        style={{ margin: "15px", borderRadius: "5px" }}
        loading={loading ? <Skeleton active /> : null}
      >
        <Row gutter={16}>
          <Col offset={6} lg={12} style={{ padding: "16px" }}>
            <div style={{ textAlign: "left" }}>
              <label>Select Amenities</label>
            </div>{" "}
            <Select
              isMulti
              closeMenuOnSelect={false}
              name="colors"
              options={renderAmmen}
              className="basic-multi-select"
              classNamePrefix="Select Ammenities"
              onChange={(e) => onSelectAmmenities(e)}
            />
          </Col>
          <Col offset={6} lg={12} style={{ padding: "16px" }}>
            <div style={{ textAlign: "left" }}>
              <label>Select Facilities</label>
            </div>{" "}
            <Select
              isMulti
              name="colors"
              closeMenuOnSelect={false}
              options={renderFacili}
              className="basic-multi-select"
              classNamePrefix="Select Facilities"
              onChange={(e) => onSelectFacilities(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={20} className="stepper-submit">
            <Button type="primary" onClick={handleSubmit}>
              Submit details
            </Button>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default Ammenities;
