import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  Skeleton,
  Button,
  DatePicker,
  InputNumber,
  Divider,
  message,
} from "antd";
import moment from "moment";
import { Select } from "antd";
const { Option } = Select;
const { Text, Title } = Typography;

const ModifyBooking = (props) => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user_level");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/booking/booking/get_booking/${props.match.params.id}`,
        config
      );
      const data = await res.data;
      setState(data);
      setLoading(false);
      console.log(data[props.match.params.index], "search");
    } catch (err) {
      console.log(err);
    }
  };

  const sendData = async (bookingIndex) => {
    console.log(bookingIndex, "bookingIndex");
    const stateData = state[bookingIndex];

    let postData = {
      booking_id: stateData.booking_id,
      date_from: stateData.date_from,
      date_to: stateData.date_to,
      payment_agent: stateData.payment_agent,
      booking_status: "",
    };

    console.log(postData, "postData");

    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/booking/booking/modify`,
        postData,
        config
      );
      const data = res.data;
      if (data.status === 200) {
        message.success(data.remarks);
        props.history.push(
          `/dashboard/${user}/booking/${props.match.params.id}`
        );
      } else {
        message.error(data.remarks);
      }
      console.log(data, "response");
    } catch (error) {
      message.error(error);
    }
  };

  const dateFormat = "YYYY/MM/DD";

  function handleChangeDate(i, event, name) {
    const values = JSON.parse(JSON.stringify(state));
    values[i][name] = event;

    setState(values);
  }

  function handleChangePay(i, event, name, bookingIndex) {
    const values = JSON.parse(JSON.stringify(state));
    values[bookingIndex].payment_agent[i][name] = event;
    if (name === "payment_amount") {
      values[bookingIndex].payment_agent[i][name] = parseInt(
        event.target.value
      );
    }
    if (name === "trasaction_id") {
      values[bookingIndex].payment_agent[i][name] = event.target.value;
    }
    setState(values);
  }

  function handleAddPay(bookingIndex) {
    let copy = JSON.parse(JSON.stringify(state));
    copy[bookingIndex].payment_agent = [
      ...copy[bookingIndex].payment_agent,
      {
        payment_by: "",
        payment_amount: "",
        trasaction_id: "",
      },
    ];
    setState(copy);
  }

  function handleRemovePay(i, bookingIndex) {
    let copy = JSON.parse(JSON.stringify(state));
    console.log(copy, "copy");
    copy[bookingIndex].payment_agent.splice(i, 1);
    setState(copy);
  }

  const classes = (x) => {
    let classes = "default  ";
    if (x === "cancelled") {
      classes += "cancel";
    }
    if (x === "waiting_payment") {
      classes += "waiting";
    }
    return classes;
  };

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{ margin: "15px", borderRadius: "5px" }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Modify Booking</Text>
          </Title>
        }
      >
        <Row justify="center">
          <Col lg={24}>
            <Form>
              <Row justify="space-around">
                {state.map((x, bookingIndex) => (
                  <Col
                    lg={11}
                    style={{
                      padding: "2rem 2.5rem",
                      border: "1px solid",
                      borderRadius: "10px",
                    }}
                  >
                    <div key={bookingIndex}>
                      <Row justify="space-between">
                        <h1>
                          Booking <span> #{x.booking_id} </span>
                        </h1>
                        <h1>
                          <span className={classes(x.booking_status)}>
                            {" "}
                            {x.booking_status === "waiting_payment"
                              ? "Payment Pending"
                              : x.booking_status}{" "}
                          </span>
                        </h1>
                      </Row>
                      <Row justify="space-between">
                        <h1
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Place : <span> {x.property_name} </span>
                        </h1>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ padding: "15px 10px 15px 0px" }}>
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Check in"
                            size="large"
                            defaultValue={moment(x.date_from, dateFormat)}
                            format={dateFormat}
                            onChange={(date, dateString) =>
                              handleChangeDate(
                                bookingIndex,
                                dateString,
                                "date_from"
                              )
                            }
                          />{" "}
                        </Col>
                        <Col lg={12} style={{ padding: "15px 0px" }}>
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Check Out"
                            size="large"
                            defaultValue={moment(x.date_to, dateFormat)}
                            format={dateFormat}
                            onChange={(date, dateString) =>
                              handleChangeDate(
                                bookingIndex,
                                dateString,
                                "date_to"
                              )
                            }
                          />{" "}
                        </Col>
                      </Row>
                      <div>
                        <Divider orientation="left">Payment Details</Divider>
                        <Button
                          type="primary"
                          style={{ margin: "0rem 0rem 0rem 2rem " }}
                          onClick={() => handleAddPay(bookingIndex)}
                        >
                          + add payment
                        </Button>
                        {x.payment_agent.length > 0 ? (
                          x.payment_agent.map((y, i) => (
                            <Row
                              justify="center"
                              style={{ marginTop: "1rem " }}
                            >
                              <h1>Payment detail #{i + 1}</h1>
                              <Col lg={20} style={{ padding: "15px 0px" }}>
                                <Input
                                  value={y.payment_amount}
                                  name="payment_amount"
                                  style={{ width: "100%" }}
                                  placeholder="payment_amount"
                                  size="large"
                                  onChange={(e) =>
                                    handleChangePay(
                                      i,
                                      e,
                                      "payment_amount",
                                      bookingIndex
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={20} style={{ padding: "15px 0px" }}>
                                <Input
                                  value={y.trasaction_id}
                                  name="trasaction_id"
                                  style={{ width: "100%" }}
                                  placeholder="trasaction_id"
                                  size="large"
                                  onChange={(e) =>
                                    handleChangePay(
                                      i,
                                      e,
                                      "trasaction_id",
                                      bookingIndex
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={20} style={{ padding: "15px 0px" }}>
                                <Select
                                  style={{ width: "100%" }}
                                  size="large"
                                  defaultValue={y.payment_by}
                                  onChange={(e) =>
                                    handleChangePay(
                                      i,
                                      e,
                                      "payment_by",
                                      bookingIndex
                                    )
                                  }
                                  placeholder="Payment By"
                                >
                                  <Option value="cash">Cash</Option>
                                  <Option value="card">Card</Option>
                                </Select>
                              </Col>
                              <Col>
                                <Button
                                  disabled={i === 0 ? true : false}
                                  type="default"
                                  onClick={() =>
                                    handleRemovePay(i, bookingIndex)
                                  }
                                >
                                  X Remove Pay
                                </Button>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <Col style={{ marginTop: "2rem" }}>
                            <Row justify="center">
                              <div>
                                <h1>No Payment History :)</h1>
                              </div>
                            </Row>
                          </Col>
                        )}
                        <Divider />

                        <Form.Item>
                          <Row justify="space-between">
                            <Button
                              type="primary"
                              onClick={() => sendData(bookingIndex)}
                            >
                              Submit
                            </Button>
                          </Row>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};
export default ModifyBooking;
