import React, { useEffect, useState } from "react";
import DetailJson from "./getDetials.json";
import {
  Skeleton,
  Card,
  Row,
  Typography,
  Col,
  message,
  Divider,
  Rate,
} from "antd";
import axios from "axios";
import Data from "../CMS/data.json";
import moment from "moment";

const { Title, Text } = Typography;

const Testimonials = (props) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/testimonial/entire/${props.match.params.id}`,
        config
      );
      const data = await res.data;
      if (res.status === 200) {
        setData(data[0]);
        console.log(data, "data");
        message.success("testimonial Loaded Successfully.");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Testimonials View </Text>
          </Title>
        }
        bordered={true}
      >
        <Row justify="center">
          <Col lg={14} md={24}>
            <div>
              <h1>{data["property_name"]}</h1>
              <p>{data["location"]}</p>
            </div>
            <Divider />
            <div>
              <h1>{data["description"]}</h1>
            </div>
            <Divider />
            <Row justify="space-between">
              <h1>
                <Rate defaultValue={data.rating} disabled />
              </h1>

              <h1>{`${data.reviewer_type} - ${data.name}`}</h1>

              <h1>
                {`  ${moment(data.stay_from).format("ll")} -
                  ${moment(data.stay_to).format("ll")} `}
              </h1>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default Testimonials;
