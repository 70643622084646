import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Tax from "../../components/Tax/Table";
import TaxCreate from "../../components/Tax/CreateTax";
import GST from "../../components/GstMaster";
import GSTList from "../../components/GstMaster/table";
import Home from "./homeLayout";

const Routes = () => {
  const user = localStorage.getItem("user_level");
  return (
    <Switch>
      {" "}
      {/* dashboard home routes for every user_level */}
      <Route exact path={`/dashboard/${user}/home`} component={Home} />
      <Route exact path={`/dashboard/${user}/taxes`} component={Tax} />
      <Route
        exact
        path={`/dashboard/${user}/tax/create`}
        component={TaxCreate}
      />
      <Route exact path={`/dashboard/${user}/gst`} component={GSTList} />
      <Route exact path={`/dashboard/${user}/gst/create`} component={GST} />
    </Switch>
  );
};

export default Routes;
