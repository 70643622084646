//Start with creating store.js
//store.js configures all the middlewares and devtools extension
//Import rootreducer into store.js which is the root to all the redcuers
//RootReducer is a combine reducers that combines all the reducer
//Import to index and provide it as a store to the PROVIDER

import {createStore , applyMiddleware} from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from './reducers/index';


const initialState = {};

const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;