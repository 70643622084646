import React, { useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import { Row, Col, Card, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import axios from "axios";
import { StepperAmmenitiesCreate } from "../../../../../../Redux/actions/stepper_actions";
import Select from "react-select";

const Ammenities = () => {
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  const [data, setData] = useState({
    facilities: [],
    amenities: [],
  });
  const [defaults, setDefaults] = useState({
    facilities: [],
    amenities: [],
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  async function fetchAPI() {
    setLoading(true);

    const config2 = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const resDefault = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/preview/2`,
        config2
      );
      const defaultData = await resDefault.data;
      console.log(defaultData, "data");

      const resAmen = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/amenities`,
        config
      );
      const resFacili = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/facilities`,
        config
      );
      const amenitiesData = await resAmen.data.amenities;
      const facilitiesData = await resFacili.data.facilities;
      setData({
        facilities: facilitiesData,
        amenities: amenitiesData,
      });

      setDefaults({
        facilities: defaultData.step_2.facilities,
        amenities: defaultData.step_2.amenities,
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const renderDef = (data) => {
    if (data) {
      return data.map((x) => {
        return {
          value: x,
          label: x,
        };
      });
    } else {
      return [];
    }
  };

  const render = (data) => {
    return data.map((x) => {
      return {
        value: x.name,
        label: x.name,
      };
    });
  };

  const getDefFacilities = renderDef(defaults.facilities);
  const getDefAmenities = renderDef(defaults.amenities);
  const renderAmmenities = render(data.amenities);
  const renderFacilities = render(data.facilities);

  useEffect(() => {
    fetchAPI();
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(defaults, "defaults in submit");
    const arrayAmmen = defaults.amenities;
    const arrayFaci = defaults.facilities;
    const dataTo = {
      amenities: arrayAmmen,
      facilities: arrayFaci,
    };

    dispatch(StepperAmmenitiesCreate(dataTo));

    console.log(dataTo, "submit");
  };
  const onSelectAmmenities = (e) => {
    const val = e.map((x) => x.value);
    setDefaults({ ...defaults, amenities: val });
  };

  const onSelectFacilities = (e) => {
    const val = e.map((x) => x.value);
    setDefaults({ ...defaults, facilities: val });
  };

  console.log(defaults, "defaults");

  return (
    <Fragment>
      <Card
        style={{ margin: "15px", borderRadius: "5px" }}
        loading={loading ? <Skeleton active /> : null}
      >
        <Row gutter={16}>
          <Col offset={6} lg={12} style={{ padding: "16px" }}>
            <div style={{ textAlign: "left" }}>
              <label>Select Ammenities</label>
            </div>
            <Select
              defaultValue={getDefAmenities}
              isMulti
              name="colors"
              options={renderAmmenities}
              className="basic-multi-select"
              classNamePrefix="Select Ammenities"
              onChange={(e) => onSelectAmmenities(e)}
            />
          </Col>
          <Col offset={6} lg={12} style={{ padding: "16px" }}>
            <label>Select Ammenities</label>
            <Select
              defaultValue={getDefFacilities}
              isMulti
              name="colors"
              options={renderFacilities}
              className="basic-multi-select"
              classNamePrefix="Select Facilities"
              onChange={(e) => onSelectFacilities(e)}
            />
          </Col>
        </Row>
        <Row>
          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Row>
      </Card>
    </Fragment>
  );
};

export default Ammenities;
