import React, { useState } from "react";
import { Steps, Button, message, Row } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import Basic from "./Basic/Basic";
import Ammenities from "./Ammenities/Ammenities";
import Image from "./Media/Media";
import Location from "./Location/Location";
import Bedroom from "./Bedrooms/Bedroom";
import Pricing from "./Pricing/Pricing";
const { Step } = Steps;
const Stepper = () => {
  const statee = useSelector((statee) => statee.stepper);
  const [state, setState] = useState({
    current: 0,
  });
  const [disable, setDisable] = useState(true);
  // console.log(statee.disable, "sateee");
  const steps = [
    {
      title: "Basic",
      content: <Basic />,
    },
    {
      title: "Ammenities & Facilities",
      content: <Ammenities />,
    },
    {
      title: "Media",
      content: <Image />,
    },
    {
      title: "Location",
      content: <Location />,
    },
    {
      title: "Bedrooms  ",
      content: <Bedroom />,
    },
    {
      title: "Pricing",
      content: <Pricing />,
    },
  ];
  const next = () => {
    const current = state.current + 1;
    setState({ current });
  };
  const prev = () => {
    const current = state.current + -1;
    setState({ current });
  };
  const handlePublish = async () => {
    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/publish`,
        { publish: true },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
      }
      console.log(data, "data");
    } catch (e) {
      console.log(e);
      message.error("Something went wrong with our server.");
    }
  };
  const { current } = state;
  return (
    <div>
      <Steps size="small" current={current} labelPlacement="vertical">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <Row justify="center" align="middle" className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Go to Next Step..
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => handlePublish()}>
            Done or Publish
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: 8 }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </Row>
    </div>
  );
};
export default Stepper;
