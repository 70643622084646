import React, { useState, useEffect, Fragment } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Form,
  Card,
  Typography,
  Select as Select1,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const { Title, Text } = Typography;
const { confirm } = Modal;

function PublishedListings() {
  const user = localStorage.getItem("user_level");
  const [data, setData] = useState([]);
  const [property_id, setProperty_id] = useState();
  const [posts, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("latest");
  const [modal, setModal] = useState({
    visible: false,
  });
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });
  const token = localStorage.getItem("token");
  const [action, setAction] = useState();

  useEffect(() => {
    fetchAPI();
    fetchData();
    localStorage.setItem("listing_id", "");
  }, []);

  const fetchAPI = async (val) => {
    let sortBy = "";
    if (val) {
      sortBy = val;
    } else {
      sortBy = "latest";
    }
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/list/${sortBy}/500/0`,
        config
      );
      const data = await res.data;

      setPost(data);
      console.log(data, "response published");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/public/misc/label/get_all",
        config
      );
      const data = await res.data;
      if (res.status === 200) {
        setData(data);
      }
    } catch (err) {
      console.log(err);
      message.error("Network error, please login again");
    }
  };

  const handleReview = async (x) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    const send = {
      property_id: x.listing_id,
      in_review: sort === "in_review" ? false : true,
      action: false,
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/action`,
        send,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
        fetchAPI(sort);
      } else {
        message.info(data.remarks);
      }
      console.log(data, "response published");
    } catch (err) {
      message.error("network error, login again");
      console.log(err);
    }
  };

  const handleDisable = async (x) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/action`,
        { property_id: x.listing_id, in_review: false, action: true },
        config
      );
      const data = await res.data;

      //   setPost(data);
      console.log(data, "response published");
    } catch (err) {
      console.log(err);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const showModal = (id) => {
    setModal({
      visible: true,
    });
    setProperty_id(id);
  };

  const handleCancel = (e) => {
    setModal({
      visible: false,
    });
  };

  const columns = [
    {
      title: "Property Title",
      dataIndex: "property_title",
      key: "property_title",
      className: "table",
      align: "center",
      width: "20%",
      ...getColumnSearchProps("property_title"),
    },
    {
      title: "Actions",
      key: "action",
      className: "table",
      align: "center",
      render: (x) => {
        if (x) {
          return (
            <div>
              {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No"> */}{" "}
              <Link
                to={`/dashboard/${user}/edit/published/${x.listing_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EditOutlinedIcon
                  onClick={() => {
                    localStorage.setItem("listing_id", x.listing_id);
                  }}
                  cursor="pointer"
                  style={{ color: "green" }}
                />
              </Link>
              {/* </Popconfirm> */}
            </div>
          );
        }
      },
    },
    {
      title: "Status",
      key: "status",
      className: "table",
      align: "center",
      render: (x) => {
        if (x) {
          return (
            <Row justify="space-between">
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => handleReview(x)}
                okText="Yes"
                cancelText="No"
              >
                {" "}
                <Button1 cursor="pointer">
                  {sort === "in_review" ? "reviewed" : "Put in Review"}
                </Button1>
              </Popconfirm>

              <Popconfirm
                title="Are you sure？"
                onConfirm={() => handleDisable(x)}
                okText="Yes"
                cancelText="No"
              >
                <Button1 cursor="pointer" danger>
                  Disable
                </Button1>
              </Popconfirm>
              <Button1 onClick={() => success(x.listing_id)}>Ical Link</Button1>
              <Button1 type="primary" onClick={() => showModal(x.listing_id)}>
                Add/Remove Labels
              </Button1>
            </Row>
          );
        }
      },
    },
  ];

  function success(property_id) {
    Modal.info({
      title: "ICAL Link ",
      content: `http://18.217.42.86:8000/api/v1/public/misc/ical/${property_id}`,
    });
  }

  // const handleTableChange = async (filters, sorter) => {
  //   setLoading(true);
  //   const token = localStorage.getItem("token");
  //   const config = {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/json",
  //       token,
  //     },
  //   };
  //   try {
  //     const res = await axios.get(
  //       `http://18.217.42.86:8000/api/v1/private/listing/create_draft/list/${sorter.is_individual}/10/0`,
  //       config
  //     );
  //     const data = await res.data;
  //     setPost(data);
  //     console.log(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setLoading(false);
  // };

  const handleLabel = async (data) => {
    const addData = {
      ...data,
      property_id,
    };

    const remove = {
      remove_label: data.labels[0],
      property_id,
    };

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    if (action) {
      console.log(addData);
      try {
        const res = await axios.post(
          "http://18.217.42.86:8000/api/v1/private/misc/label/add_to_properties",
          addData,
          config
        );
        const data = await res.data;
        message.success(data.remarks);
      } catch (err) {
        console.log(err);
        message.error("Network error, please login again");
      }
    } else {
      confirm({
        title: "Are you sure delete this Label?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes, delete",
        okType: "danger",
        cancelText: "No, keep",
        async onOk() {
          console.log(remove);
          try {
            const res = await axios.post(
              "http://18.217.42.86:8000/api/v1/private/misc/label/remove_from_property",
              remove,
              config
            );
            const data = await res.data;
            message.success(data.remarks);
          } catch (err) {
            console.log(err);
            message.error("Network error, please login again");
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
    setModal({
      visible: false,
    });
  };

  return (
    <Fragment>
      <Card
        loading={loading}
        title={
          <Title level={2}>
            <Text strong>Published Listings</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
      >
        <Row>
          <Col lg={24}>
            <Row justify="end">
              <Select1
                style={{ margin: "10px 0px", width: 120 }}
                placeholder="Sort By"
                value={sort}
                onChange={(e) => {
                  setSort(e);
                  fetchAPI(e);
                }}
              >
                <Select1.Option value="latest">Latest</Select1.Option>
                <Select1.Option value="in_review">In-Review</Select1.Option>
                <Select1.Option value="recently_published">
                  Recently Published
                </Select1.Option>
              </Select1>
            </Row>
          </Col>
          <Col lg={24} md={24}>
            <AntdTable
              columns={columns}
              dataSource={posts}
              // onChange={handleTableChange}
              bordered
            />
          </Col>
        </Row>
        <Modal
          title={
            <Title level={3} style={{ textAlign: "center" }}>
              <Text strong>Add/Remove Labels </Text>
            </Title>
          }
          closable={false}
          visible={modal.visible}
          id="myForm"
          footer={[
            <div>
              <Button1 onClick={handleCancel} key="submit" htmlType="submit">
                Cancel
              </Button1>
              <Button1
                form="myForm"
                key="delete"
                htmlType="submit"
                danger
                onClick={() => setAction(false)}
              >
                Delete Label
              </Button1>
              <Button1
                form="myForm"
                key="add"
                htmlType="submit"
                type="primary"
                onClick={() => setAction(true)}
              >
                Add Label
              </Button1>
            </div>,
          ]}
        >
          <Form id="myForm" onFinish={handleLabel} size="large">
            <p style={{ textAlign: "center" }}>
              <b>Note</b>: Don't select multiple labels if you're deleting
            </p>
            <Row justify="center" style={{ padding: "1rem 5rem 5rem 5rem" }}>
              <Col lg={22}>
                <Form.Item name="labels">
                  <Select1 placeholder="Labels" mode="multiple">
                    {data.map((x, i) => (
                      <Select1.Option key={i} value={x.label_name}>
                        {" "}
                        {x.label_name}
                      </Select1.Option>
                    ))}
                  </Select1>
                </Form.Item>
              </Col>{" "}
            </Row>
          </Form>
        </Modal>
      </Card>
    </Fragment>
  );
}
export default PublishedListings;
