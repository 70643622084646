import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select as Select1 } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select1;

export default function States({ getStatesVal, stateVal }) {
  const [state, setState] = useState([]);
  useEffect(() => {
    getStates();
  }, []);
  const [value, setValue] = useState("");

  const getStates = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/location/states/104de4f0-eb60-4464-b74e-2e25390cd703`,
        config
      );
      const data = await res.data;
      await setState(data);
      console.log(data, "states");
    } catch (err) {
      console.log(err);
    }
    // setStateLoading(false);
  };

  function onChangeState(value) {
    getStatesVal(value);
    setValue(value);
  }
  return (
    <>
      <label>State</label>
      <Select1
        showSearch
        value={value ? value : stateVal}
        suffix={<SearchOutlined />}
        style={{ width: "100%" }}
        placeholder="Select State"
        onChange={onChangeState}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {state.map((state) => {
          return (
            <Option key={state._id} value={state.state}>
              {state.state}
            </Option>
          );
        })}
      </Select1>
    </>
  );
}
