import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Col, Select as Select1, Card, Button } from "antd";

import AddIcon from "@material-ui/icons/Add";

import { StepperBedroomsCreate } from "../../../../../../Redux/actions/stepper_actions";

const Bedroom = () => {
  const numBeds = localStorage.getItem("beds");
  console.log(numBeds, "numBeds");
  let beds_num = [];

  for (let i = 0; i < numBeds; i++) {
    beds_num.push({
      number_guests: "",
      number_beds: "",
      bed_type: [],
    });
  }
  const dispatch = useDispatch();
  const [forms, changeForm] = useState({
    step_5: beds_num,
  });

  // function handleAdd(e) {
  //   let copy = JSON.parse(JSON.stringify(forms));
  //   copy.step_5 = [
  //     ...copy.step_5,
  //     {
  //       number_guests: 0,
  //       number_beds: 0,
  //       bed_type: [],
  //     },
  //   ];
  //   changeForm(copy);
  // }

  function handleGuests(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5[i].number_guests = parseInt(e.target.value);
    changeForm(copy);
  }

  function handleBeds(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5[i].number_beds = parseInt(e.target.value);
    changeForm(copy);
  }

  function handleBedType(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.step_5[i].bed_type = e;
    changeForm(copy);
  }

  const onFinish = (values) => {
    // console.log(forms, "forms");
    dispatch(StepperBedroomsCreate({ step: "5", ...forms }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const bed_types = [
    "King",
    "Queen",
    "California King",
    "Double",
    "Triple",
    "Bunk",
    "Floor Mattress",
    "Sofa Cum Bed",
    "Single",
    "Twin",
  ];

  return (
    <Fragment>
      <Card style={{ margin: "15px", borderRadius: "5px" }}>
        {numBeds
          ? forms.step_5.map((x, index) => (
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="inline"
              >
                <Col lg={24}>
                  <h1>Bedroom #{index + 1}</h1>
                </Col>
                <Col lg={7} style={{ padding: "8px" }}>
                  <div style={{ textAlign: "left" }}>
                    <label>Enter Guests</label>
                  </div>{" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Please input Number Of Guests!`,
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      value={x.number_guests}
                      placeholder="Number Of Guests"
                      onChange={(e) => handleGuests(e, index)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={7} style={{ padding: "8px" }}>
                  <div style={{ textAlign: "left" }}>
                    <label>Enter Beds</label>
                  </div>{" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Please input Number Of Beds!`,
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      value={x.number_beds}
                      placeholder="Number Of Beds"
                      onChange={(e) => handleBeds(e, index)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={7} style={{ padding: "8px" }}>
                  <div style={{ textAlign: "left" }}>
                    <label>Enter Bed Type</label>
                  </div>{" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Please input Bed Type!`,
                      },
                    ]}
                  >
                    <Select1
                      style={{ width: "100%" }}
                      placeholder="Bed Type"
                      value={x.bed_type}
                      mode="multiple"
                      onChange={(e) => handleBedType(e, index)}
                    >
                      {bed_types.map((x, i) => (
                        <Select1.Option value={x}>{x}</Select1.Option>
                      ))}
                    </Select1>
                  </Form.Item>
                </Col>
                <Col
                  lg={3}
                  style={{
                    padding: "8px",
                    textAlign: "center",
                    marginTop: "1.3rem",
                  }}
                >
                  {/* <Button type="primary" onClick={handleAdd}>
                    Add bed room
                  </Button>{" "} */}
                </Col>
              </Form>
            ))
          : "No Beds Selected in step 1"}
        <Form.Item>
          <Button
            style={{ marginTop: "1rem" }}
            type="primary"
            htmlType="submit"
            onClick={onFinish}
          >
            Submit
          </Button>
        </Form.Item>
      </Card>
    </Fragment>
  );
};

export default Bedroom;
