import React from "react";
import { Row, Col, Card } from "antd";

const Home = () => {
  return (
    <div>
      <Row justify="space-around">
        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>Bookings</h1>
          <h1>3250</h1>
        </Col>

        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>Revenue</h1>
          <h1>1500 /-</h1>
        </Col>

        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>TurnOver</h1>
          <h1>150000 /-</h1>
        </Col>
      </Row>

      <Row style={{ marginTop: "2rem" }} justify="space-around">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Card>
            <h1>Payout Information</h1>
            <p>Table Loading...</p>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 11 }}>
          <Card>
            <h1>Reservation Reports</h1>
            <p>Table Loading...</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
