import React from "react";
import { Typography } from "antd";

const PageFooter = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <p>RoamHome Design ©2020 Created by RoamHome Team.</p>{" "}
    </div>
  );
};

export default PageFooter;
