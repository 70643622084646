import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

export default function unAuthorized() {
  const user = localStorage.getItem("user_level");

  return (
    <div>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <a href={`/`}>
            <Button type="primary">Please Login to Access</Button>
          </a>
        }
      />
    </div>
  );
}
