export const LOGIN_USER = "LOGIN_USER";
export const STEPPER_GET_ALL = "STEPPER_GET_ALL";
export const STEPPER_GET_ALL_FAIL = "STEPPER_GET_ALL_FAIL";
export const STEPPER_CREATE_BASIC_FAIL = "STEPPER_CREATE_BASIC_FAIL";
export const STEPPER_CREATE_BASIC = "STEPPER_CREATE_BASIC";
export const STEPPER_GET_BASIC = "STEPPER_GET_BASIC";
export const STEPPER_GET_BASIC_FAIL = "STEPPER_GET_BASIC_FAIL";
export const STEPPER_CREATE_AMMENITIES = "STEPPER_CREATE_AMMENITIES";
export const STEPPER_CREATE_AMMENITIES_FAIL = "STEPPER_CREATE_AMMENITIES_FAIL";
export const STEPPER_GET_AMMENITIES = "STEPPER_GET_AMMENITIES";
export const STEPPER_GET_AMMENITIES_FAIL = "STEPPER_GET_AMMENITIES_FAIL";
export const STEPPER_GET_BEDROOMS = "STEPPER_GET_BEDROOMS";
export const STEPPER_GET_BEDROOMS_FAIL = "STEPPER_GET_BEDROOMS_FAIL";
export const STEPPER_CREATE_MEDIA = "STEPPER_CREATE_MEDIA";
export const STEPPER_CREATE_MEDIA_FAIL = "STEPPER_CREATE_MEDIA_FAIL";
export const STEPPER_GET_MEDIA_FAIL = "STEPPER_GET_MEDIA_FAIL";
export const STEPPER_GET_MEDIA = "STEPPER_GET_MEDIA";
export const STEPPER_CREATE_BEDROOMS = "STEPPER_CREATE_BEDROOMS";
export const STEPPER_CREATE_BEDROOMS_FAIL = "STEPPER_CREATE_BEDROOMS_FAIL";
export const STEPPER_CREATE_LOCATION = "STEPPER_CREATE_LOCATION";
export const STEPPER_CREATE_LOCATION_FAIL = "STEPPER_CREATE_LOCATION_FAIL";
export const STEPPER_GET_LOCATION_FAIL = "STEPPER_GET_LOCATION_FAIL";
export const STEPPER_GET_LOCATION = "STEPPER_GET_LOCATION";
export const STEPPER_GET_PRICING = "STEPPER_GET_PRICING";
export const STEPPER_GET_PRICING_FAIL = "STEPPER_GET_PRICING_FAIL";
export const STEPPER_CREATE_PRICING_FAIL = "STEPPER_CREATE_PRICING_FAIL";
export const STEPPER_CREATE_PRICING = "STEPPER_CREATE_PRICING";
export const STEPPER_INDEX = "stepper_index";
export const STEPPER_EDIT_BASIC = "stepper_edit_basic";
