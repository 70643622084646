import React from "react";
import PageHeader from "./Components/Header_Footer/Header";
import Login from "./Components/LoginPage/Login";
import PageFooter from "./Components/Header_Footer/Footer";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
const { Header, Content, Footer } = Layout;

function App() {
  return (
    <div>
      <Layout>
        <Header className="header">
          <PageHeader />
        </Header>{" "}
        <Content>
          <Login />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <PageFooter />
        </Footer>{" "}
      </Layout>
    </div>
  );
}

export default withRouter(App);
