import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  Button,
  message,
  Skeleton,
  InputNumber,
} from "antd";

import { Select } from "antd";

const { Option } = Select;

const { Text, Title } = Typography;

const CreateGst = ({ history }) => {
  const [country, setCountry] = useState({ country: "", country_id: "" });
  const [state, setState] = useState({ state: "", state_id: "" });
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingState, setStateLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("email");

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/location/countries",
        config
      );
      const data = await res.data;
      await setCountryOptions(data);
      console.log(data, "country");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    let dataTo = {
      ...state,
      ...country,
      ...values,
      added_by_user: user,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    console.log(dataTo, "dadda");
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/gst_master",
        dataTo,
        config
      );
      const data = await res.data;
      console.log(data);
      if (data.status === 200) {
        message.success("GST added successfully.");
      }
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getStates = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/location/states/${id}`,
        config
      );
      const data = await res.data;
      await setStateOptions(data);
      console.log(data, "states");
    } catch (err) {
      console.log(err);
    }
    setStateLoading(false);
  };

  function onChangeCountry(values) {
    let [id, country] = values.split("---");
    setCountry({ country: country, country_id: id });
    getStates(id);
  }

  function onChangeState(values) {
    let [id, state] = values.split("---");
    setState({ state: state, state_id: id });
  }

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title
            level={2}
            style={{
              textAlign: "center",
            }}
          >
            <Text strong>Create GST</Text>
          </Title>
        }
        bordered={true}
      >
        <Row>
          <Col offset={8} lg={8} style={{ padding: "8px" }}>
            <Select
              showSearch
              placeholder="Select a Country"
              style={{ width: "100%" }}
              onChange={(e) => onChangeCountry(e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              size="large"
            >
              {countryOptions.map((country) => {
                return (
                  <Option
                    key={country.country_id}
                    value={`${country.country_id}---${country.country}`}
                  >
                    {country.country}
                  </Option>
                );
              })}
            </Select>
          </Col>

          <Col offset={8} lg={8} style={{ padding: "8px" }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a State"
              onChange={onChangeState}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingState}
              size="large"
            >
              {stateOptions.map((state) => {
                return (
                  <Option
                    key={state.state_id}
                    value={`${state.state_id}---${state.state}`}
                  >
                    {state.state}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col offset={8} lg={8} style={{ padding: "8px" }}>
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                name="address"
                rules={[{ required: true, message: "Please enter Address!" }]}
              >
                <Input.TextArea placeholder="Address" size="large" />
              </Form.Item>
              <Form.Item
                name="gstin"
                rules={[{ required: true, message: "Please enter GST!" }]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="GST Number"
                  size="large"
                />
              </Form.Item>{" "}
              <Form.Item>
                <Button htmlType="submit" loading={buttonLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default withRouter(CreateGst);
