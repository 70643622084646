import React, { useState } from "react";
import { Layout, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import Sidebar from "./Sidebar";
import PageFooter from "../../../Header_Footer/Footer";
import DashboardRoutes from "./Routes";
import { PoweroffOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { removeSession } from "../../../../Utils/session";
const { Header, Content, Footer } = Layout;

const Dashboard = () => {
  const [state, setState] = useState({
    collapsed: false,
  });

  const toggle = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };
  const user_email = localStorage.getItem("email");
  return (
    <div>
      <Layout>
        <Sidebar toggle={state.collapsed} />
        <Layout>
          <Header className="header-dashboard d-flex justify-content-between align-items-center">
            {React.createElement(
              state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggle,
              }
            )}
            <div
              style={{
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ margin: "0.1rem 1rem 0 0" }}>
                Hello! <b> {user_email} </b>
              </p>
              <a href="/">
                <Button
                  type="primary"
                  icon={<PoweroffOutlined />}
                  shape={"circle"}
                  size="medium"
                  onClick={removeSession}
                >
                  {/* Logout */}
                </Button>
              </a>
            </div>
          </Header>
          <Content style={{ margin: "20px", minHeight: "500px" }}>
            <DashboardRoutes />
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <PageFooter />
          </Footer>{" "}
        </Layout>
      </Layout>
    </div>
  );
};

export default Dashboard;
