import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  message,
  Card,
  Input,
  Button as Button1,
  Table as AntdTable,
  Row,
  Col,
  Divider,
  Typography,
} from "antd";
import AddLabel from "./create";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

function LabelContainer() {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/public/misc/label/get_all"
      );
      const data = await res.data;
      console.log(data, "res data");
      if (res.status === 200) {
        setData(data);
      }
    } catch (err) {
      console.log(err);
      message.error("Network error, please login again");
    }
  };

  const deleteLabel = async (id) => {
    console.log(id);
    setLoading(true);
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/label/delete",
        { _id: id },
        config
      );
      const data = await res.data;
      if (res.status === 200) {
        console.log(data, "data");
        message.success("deleted Successfully.");
        fetchData();
      } else {
        message.error("delete failed.");
      }
    } catch (error) {
      console.log(error);
      message.error("Netowrk error, Try again later");
    }
    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Label Image",
      dataIndex: "label_img",
      key: "label_img",
      className: "table",
      ...getColumnSearchProps("label_img"),
      render: (x) => {
        return (
          <div>
            <img
              src={`https://roamhometechtest.s3.us-east-2.amazonaws.com/${x}`}
              alt="thumbnail"
              height="75px"
            />
          </div>
        );
      },
    },
    {
      title: "Lable Name",
      dataIndex: "label_name",
      key: "label_name",
      className: "table",
      ...getColumnSearchProps("label_name"),
    },
    {
      title: "Action",
      key: "action",
      className: "table",
      render: (x) => {
        return (
          <div>
            <Button1
              type="link"
              style={{ color: "red" }}
              onClick={() => deleteLabel(x._id)}
            >
              Delete
            </Button1>
          </div>
        );
      },
    },
  ];

  console.log(data, "state data");

  return (
    <Card
      style={{ margin: "15px", borderRadius: "5px" }}
      title={
        <Title level={2} style={{ textAlign: "center" }}>
          <Text strong style={{ marginRight: "3rem" }}>
            Labels
          </Text>
        </Title>
      }
      bordered={true}
    >
      <AddLabel />
      <Divider />

      <Row>
        <Col lg={24}>
          <AntdTable
            loading={loading}
            columns={columns}
            dataSource={data}
            bordered
          />
        </Col>
      </Row>
    </Card>
  );
}
export default LabelContainer;
