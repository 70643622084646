import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  UnorderedListOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import Logo from "../../../../assets/images/black_logo.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ toggle }) => {
  const user = localStorage.getItem("user_level");
  return (
    <Sider trigger={null} collapsible collapsed={toggle} className="sidebar">
      <div className="logo d-flex">
        <img
          src={Logo}
          alt="logo"
          height="30px"
          style={{ marginRight: "0.5rem" }}
        />
        {!toggle ? <h1 style={{ marginTop: "0.2rem" }}>Dashboard</h1> : ""}
      </div>
      <Menu mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="Home">
          <Link to={`/dashboard/${user}/home`}>
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="Tax"
          title={
            <span>
              <MoneyCollectOutlined />
              <span>Tax</span>
            </span>
          }
        >
          <Menu.Item key="create-tax">
            <Link to={`/dashboard/${user}/tax/create`}>Create Tax</Link>
          </Menu.Item>
          <Menu.Item key="taxes">
            <Link to={`/dashboard/${user}/taxes`}>Check All Taxes</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="GST"
          title={
            <span>
              <UnorderedListOutlined />
              <span>GST</span>
            </span>
          }
        >
          <Menu.Item key="create-gst">
            <Link to={`/dashboard/${user}/gst/create`}>Create GST</Link>
          </Menu.Item>
          <Menu.Item key="gst">
            <Link to={`/dashboard/${user}/gst`}>Check GST List</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
