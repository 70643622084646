import React, { useState } from "react";
import { Steps, Button, message } from "antd";
import { Link } from "react-router-dom";
import Basic from "./Basic/Basic";
import Ammenities from "./Ammenities/Ammenities";
import Image from "./Media/Media";
import Location from "./Location/Location";
import Bedroom from "./Bedrooms/Bedroom";
import Pricing from "./Pricing/Pricing";
import axios from "axios";

const { Step } = Steps;

const steps = [
  {
    title: "Basic",
    content: <Basic />,
  },
  {
    title: "Ammenities & Facilities",
    content: <Ammenities />,
  },
  {
    title: "Media",
    content: <Image />,
  },
  {
    title: "Location",
    content: <Location />,
  },
  {
    title: "Bedrooms",
    content: <Bedroom />,
  },
  {
    title: "Pricing",
    content: <Pricing />,
  },
];

const Stepper = () => {
  const [state, setState] = useState({
    current: 0,
  });

  const next = () => {
    const current = state.current + 1;
    setState({ current });
  };

  const prev = () => {
    const current = state.current + -1;
    setState({ current });
  };
  const handlePublish = async () => {
    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/publish`,
        { publish: true },
        config
      );
      const data = await res.data;
      if (data.status == "200") {
        message.success("Listing Published.");
      }
      console.log(data, "data");
    } catch (e) {
      console.log(e);
      message.error("Something went wrong with our server.");
    }
  };

  const { current } = state;
  return (
    <div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => handlePublish()}>
            Done or Publish
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: 8 }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

export default Stepper;
