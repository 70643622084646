import React, { useState, useEffect, Fragment } from "react";
import tableJson from "../jsons/bookings.json";
import { Link } from "react-router-dom";

import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Switch,
  Card,
  Typography,
  Tabs,
  Badge,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Text, Title } = Typography;

function BookingListWaiting() {
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  const [awaiting, setAwaiting] = useState([]);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  useEffect(() => {
    fetchAwaitingData();
  }, []);

  const fetchAwaitingData = async () => {
    setLoading(true);
    try {
      const url = `http://18.217.42.86:8000/api/v1/private/booking/booking/awaiting_approvals/list/200/0`;
      const res = await axios.get(url, config);
      console.log(res, "aaitng ees");
      if (res.status === 200) {
        const resData = await res.data;
        let array = [];
        resData.map((x) => {
          return x.properties.map((y) => {
            return array.push({
              order_request_id: x.order_request_id,
              date_from: y.date_from,
              date_to: y.date_to,
              property_name: y.property_name,
              status: x.status,
            });
          });
        });
        setAwaiting(array);
      }
    } catch (error) {
      message.error("Session Expired, PLease Login again.");
    }
    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleApproval = async (id) => {
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/booking/booking/awaiting_approvals/action`,
        { order_request_id: id, status: "approved" },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success("Booking Approved Successfull");
        fetchAwaitingData();
      }
      console.log(data, "approved res");
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async (id) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/booking/booking/awaiting_approvals/action`,
        { order_request_id: id, status: "rejected" },
        config
      );
      const data = await res.data;
      console.log(data, "approved res");
      fetchAwaitingData();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns2 = [
    {
      title: "Order ID",
      dataIndex: "order_request_id",
      key: "order_request_id",
      className: "table",
      width: "20%",
    },
    {
      title: "Property Name",
      dataIndex: "property_name",
      key: "property_name",
      className: "table",
      width: "20%",
    },
    {
      title: "Date From",
      dataIndex: "date_from",
      className: "table",
      key: "date_from",
      ...getColumnSearchProps("date_from"),
      render: (x) => {
        return moment(x).format("ll");
      },
    },
    {
      title: "Date To",
      dataIndex: "date_to",
      className: "table",
      key: "date_to",
      ...getColumnSearchProps("date_to"),
      render: (x) => {
        return moment(x).format("ll");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "table",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (x) => {
        return x === "approved" ? (
          <span
            style={{
              height: 35,
              color: "#52c41a",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              padding: "6px 12px",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              {" "}
              <CheckCircleOutlined />
            </span>{" "}
            Approved
          </span>
        ) : x === "rejected" ? (
          <span
            style={{
              height: 35,
              color: "#EC7063 ",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              padding: "6px 12px",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              {" "}
              <CloseCircleOutlined />
            </span>{" "}
            Rejected
          </span>
        ) : (
          <span
            style={{
              height: 35,
              color: "#3498DB ",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              padding: "6px 12px",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              {" "}
              <ExclamationCircleOutlined />
            </span>{" "}
            On Hold
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      className: "table",
      render: (x) => {
        return (
          <Row justify="space-around">
            {x.status === "approved" ? (
              ""
            ) : (
              <Button1
                type="primary"
                size="small"
                icon={<CheckOutlined />}
                onClick={() => handleApproval(x.order_request_id)}
              >
                Approve
              </Button1>
            )}
            {x.status === "rejected" ? (
              ""
            ) : (
              <Button1
                type="danger"
                size="small"
                icon={<CloseOutlined />}
                onClick={() => handleReject(x.order_request_id)}
              >
                Reject
              </Button1>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Card
        loading={loading}
        style={{
          borderRadius: "5px",
        }}
        title={
          <Title level={4} style={{ textAlign: "center" }}>
            <Text strong>Awaiting Approval</Text>
          </Title>
        }
      >
        <Col lg={24} md={24}>
          <AntdTable columns={columns2} dataSource={awaiting} bordered />
        </Col>
      </Card>
    </Fragment>
  );
}

export default BookingListWaiting;
