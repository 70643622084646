import React, { useState, useEffect, Fragment } from "react";
import tableJson from "../jsons/bookings.json";
import { Link } from "react-router-dom";

import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Col,
  Card,
  Typography,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const { Text, Title } = Typography;

function BookingList() {
  const user = localStorage.getItem("user_level");
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  const [cancelled, setCancelled] = useState([]);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  useEffect(() => {
    fetchCancelled();
  }, []);

  const fetchCancelled = async () => {
    setLoading(true);

    try {
      const url = `http://18.217.42.86:8000/api/v1/private/booking/booking/get_all/cancelled/200/0`;
      const res = await axios.get(url, config);
      if (res.status === 200) {
        const resData = await res.data;
        console.log(resData, "resData");
        setCancelled(resData);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Booking Id",
      dataIndex: "booking_id",
      key: "booking_id",
      className: "table",
      width: "20%",
      ...getColumnSearchProps("booking_id"),
    },
    {
      title: "Property Name",
      dataIndex: "property_name",
      key: "property_name",
      className: "table",
      width: "20%",
      ...getColumnSearchProps("property_name"),
    },
    {
      title: "Date From",
      dataIndex: "date_from",
      className: "table",
      key: "date_from",
      ...getColumnSearchProps("date_from"),
      render: (x) => {
        return moment(x).format("ll");
      },
    },
    {
      title: "Date To",
      dataIndex: "date_to",
      className: "table",
      key: "date_to",
      ...getColumnSearchProps("date_to"),
      render: (x) => {
        return moment(x).format("ll");
      },
    },
    {
      title: "Booking amount",
      dataIndex: "grand_total_amount",
      className: "table",
      key: "grand_total_amount",
      ...getColumnSearchProps("grand_total_amount"),
    },
    {
      title: "Action",
      key: "action",
      className: "table",
      render: (x) => {
        return (
          <div>
            <Link to={`/dashboard/${user}/booking/view/${x.booking_id}`}>
              View
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Card
        loading={loading}
        style={{
          // margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={4} style={{ textAlign: "center" }}>
            <Text strong>Cancelled Bookings List</Text>
          </Title>
        }
      >
        <Col lg={24} md={24}>
          <AntdTable columns={columns} dataSource={cancelled} bordered />
        </Col>
      </Card>
    </Fragment>
  );
}

export default BookingList;
