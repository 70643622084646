import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  TreeSelect,
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  Select as Select1,
  Button as Button1,
  Modal,
  message,
} from "antd";

const { Text, Title } = Typography;
const { SHOW_PARENT } = TreeSelect;

const layout = {
  labelCol: { span: 3, offset: 4 },
  wrapperCol: { span: 12 },
};

const form = {
  width: "100%",
};

function CreateUser({ history }) {
  const [modal, setModal] = useState({
    visible: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [treedata, settree] = useState([]);

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user_level");

  useEffect(() => {
    fetchAllLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllLocations = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    const fetch = await axios.get(
      "http://18.217.42.86:8000/api/v1/private/misc/location/countries/all",
      config
    );
    const res = await fetch;
    console.log(res, "all location");
    settree(renderData(res.data));
  };

  const renderData = (data) => {
    return data.map((c) => {
      return {
        title: c.country,
        value: c.country_id,
        children: c.country_entities.map((s) => {
          return {
            title: s.state,
            value: s.state,
            children: s.state_entities.map((a) => {
              return {
                title: a.area,
                value: a.area,
              };
            }),
          };
        }),
      };
    });
  };

  const [state, setState] = useState({
    value: [],
  });

  const [json, setJson] = useState();

  const onChange = (value) => {
    console.log("onChange ", value);
    let con = [];
    treedata.map((c) => {
      if (value.includes(c.value)) {
        con.push({
          country: c.title,
          country_id: c.value,
          state: "0",
          state_id: "0",
          area: "0",
          area_id: "0",
        });
      }
      c.children.map((s) => {
        if (value.includes(s.value)) {
          con.push({
            country: c.title,
            country_id: c.value,
            state: s.title,
            state_id: s.value,
            area: "0",
            area_id: "0",
          });
        }
        s.children.map((a) => {
          console.log(a, "a");
          if (value.includes(a.value)) {
            con.push({
              country: c.title,
              country_id: c.value,
              state: s.title,
              state_id: s.value,
              area: a.title,
              area_id: a.value,
            });
          }
        });
      });
    });
    setState({ value });
    setJson(con);
  };

  const handleResend = async (email) => {
    const dataTo = { email: email };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    console.log(dataTo, "values");

    const res = await axios.post(
      "http://18.217.42.86:8000/api/v1/private/auth/user/resend_otp",
      dataTo,
      config
    );
    const data = await res.data;
    if (data.status === 200) {
      message.success(data.remarks);
    } else {
      message.error(data.remarks);
    }
    console.log(data, "resp");
  };

  const tProps = {
    value: state.value,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };

  if (loading) {
    return <h1>Loading</h1>;
  }

  const onFinish = async (values) => {
    setButtonLoading(true);
    setEmail(values.email);

    const dataTo = {
      ...values,
      gender: "Male",
      access_area: [...json],
    };
    console.log(dataTo, "dataTo");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/auth/user/create",
        dataTo,
        config
      );
      const data = await res.data;
      console.log(data, "data");
      if (data.status === 200) {
        showModal();
      }
      if (data.remarks === "User Account Already Exists") {
        showModal();
        handleResend(values.email);
        console.log("inside user", email);
      }
    } catch (error) {
      console.log(error);
      message.error("OOPS! Something went wrong with our server.");
    }

    setButtonLoading(false);
  };

  const verifyOTP = async (values) => {
    const dataTo = {
      email,
      otp: parseInt(values.otp),
      password: values.password,
    };
    console.log("dataTo:", dataTo);

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    const res = await axios.post(
      "http://18.217.42.86:8000/api/v1/public/auth/verify_account",
      dataTo,
      config
    );
    const data = await res.data;

    if (data.status === 200) {
      message.success(data.remarks);
      history.push(`/dashboard/${user}/list-user`);
    } else {
      message.error(data.remarks);
    }
    setModal({
      visible: false,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setModal({
      visible: true,
    });
  };

  const handleCancel = (e) => {
    console.log(e);
    setModal({
      visible: false,
    });
  };

  return (
    <Fragment>
      <Card
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Create User</Text>
          </Title>
        }
        bordered={true}
      >
        <Row>
          <Col offset={6} lg={20}>
            <Form
              {...layout}
              style={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size="large"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                  { type: "email" },
                ]}
                labelAlign="left"
              >
                <Input allowClear placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please input your Name!" }]}
                labelAlign="left"
              >
                <Input allowClear placeholder="Name" />
              </Form.Item>

              <Form.Item
                name="user_level_name"
                rules={[{ required: true, message: "Please input your Role!" }]}
                labelAlign="left"
              >
                <Select1 placeholder="Role">
                  <Select1.Option value="Super Admin">
                    Super Admin
                  </Select1.Option>
                  <Select1.Option value="Admin">Admin</Select1.Option>
                  <Select1.Option value="Finance">Finance</Select1.Option>
                  <Select1.Option value="Key Accounts">
                    Key Accounts
                  </Select1.Option>
                  <Select1.Option value="Operations">Operation</Select1.Option>
                  <Select1.Option value="Auditor">Auditor</Select1.Option>
                  <Select1.Option value="Cleaner">Cleaner</Select1.Option>
                  <Select1.Option value="Homeowner">Home Owner</Select1.Option>
                  <Select1.Option value="User"> User</Select1.Option>
                  <Select1.Option value="Technical Writer">
                    Technical Writer/Marketing
                  </Select1.Option>
                </Select1>
              </Form.Item>
              {/* <Form.Item
                name="gender"
                rules={[
                  { required: true, message: "Please input your Gender!" },
                ]}
                labelAlign="left"
              >
                <Select1 placeholder="Gender">
                  <Select1.Option value="Male">Male</Select1.Option>
                  <Select1.Option value="Female">Female</Select1.Option>
                  <Select1.Option value="Third">Transgender</Select1.Option>
                  <Select1.Option value="Nomention">
                    Prefer Not To Say
                  </Select1.Option>
                </Select1>
              </Form.Item> */}

              <Form.Item
                name="mobile"
                rules={[
                  { required: true, message: "Please input your Number!" },
                  { min: 10 },
                ]}
                labelAlign="left"
              >
                <Input allowClear placeholder="Mobile Number" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your Area!",
                  },
                ]}
                labelAlign="left"
              >
                <TreeSelect
                  placeholder="Access Area"
                  treeData={treedata}
                  onChange={onChange}
                  {...tProps}
                />
              </Form.Item>

              <Button1 htmlType="submit" loading={buttonLoading}>
                Submit
              </Button1>
            </Form>
          </Col>
        </Row>
      </Card>{" "}
      <Modal
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Verify Account</Text>
          </Title>
        }
        visible={modal.visible}
        id="myForm"
        footer={[
          <div>
            <Button1 onClick={handleCancel} key="submit" htmlType="submit">
              Cancel
            </Button1>
            <Button1 onClick={() => handleResend}>Resend OTP</Button1>
            <Button1 form="myForm" key="submit" htmlType="submit">
              Submit
            </Button1>
          </div>,
        ]}
      >
        <Form
          {...layout}
          style={form}
          id="myForm"
          onFinish={verifyOTP}
          onFinishFailed={onFinishFailed}
          size="large"
        >
          <Row justify="center">
            <Col lg={18}>
              <Form.Item
                name="otp"
                rules={[{ required: true, message: "Please input your OTP!" }]}
              >
                <Input placeholder="enter your OTP" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={18}>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password placeholder="enter Password" />
              </Form.Item>
            </Col>{" "}
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default withRouter(CreateUser);
