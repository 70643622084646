import React, { useState, useEffect } from "react";
import { Button, Typography, Tooltip, Skeleton, Row } from "antd";
import axios from "axios";
import "./styles/style.scss";
import "./styles/days.scss";
import "./styles/duration.scss";
import Month from "./components/monthDropdown";
import jsonData from "./calendar.json";
import moment from "moment";
import Airbnb from "./logos/Airbnb.png";
import Bcom from "./logos/Bcom.png";
import Roamhome from "./logos/Roamhome.svg";
import MMT from "./logos/makemytrip.png";
import Agoda from "./logos/Agodaa.png";
import Blocked from "./logos/blocked.png";

import { Link } from "react-router-dom";

function Calendar() {
  const { Title } = Typography;

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user_level");
  const [apiData, setApiData] = useState([]);

  const [loading, setLoading] = useState(false);

  const currentMonth = new Date().getMonth();
  var monthNumber = ("0" + (currentMonth + 1)).slice(-2);
  const [month, setMonth] = useState(monthNumber);
  // const [year, setYear] = useState(0);

  function getDaysArray(year, month) {
    var daysInWeek, daysIndex, index, i, l, daysArray;

    // numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
    index = daysIndex[new Date(year, month - 1, 1).toString().split(" ")[0]];
    daysArray = [];

    for (i = 0, l = new Date(year, month, 0).getDate(); i < l; i++) {
      const date = `${month}/${i + 1}/${year}`;
      const dayName = daysInWeek[index++];
      daysArray.push({
        fullDate: date,
        day: i + 1,
        dayName,
      });

      if (index === 7) index = 0;
    }
    return daysArray;
  }

  const handleMonth = (val) => {
    setMonth(val);
  };

  const currentDate = moment().format("L");
  const dayHeader = (date) => {
    const validateDate = moment(date).format("L");
    let todayClass =
      validateDate.toString() === currentDate.toString()
        ? "dates-list today"
        : "dates-list";
    return todayClass;
  };

  const durationStrip = (from, to, vendor) => {
    let classes = ` `;
    const convertFrom = moment(from).format("L");
    const convertTO = moment(to).format("L");
    //from
    const fromDateSplit = convertFrom.split("/");
    const fromDay = fromDateSplit[1];
    const fromMonth = fromDateSplit[0];

    //to
    const toDateSplit = convertTO.split("/");
    const toDay = toDateSplit[1];
    const toMonth = toDateSplit[0];

    //calculate difference btn two days
    var a = moment([fromMonth, 0, fromDay]);
    var b = moment([toMonth, 0, toDay]);
    const diff = b.diff(a, "days");
    // console.log(diff, fromDay);
    let display = "";
    var isMonth = ("0" + month).slice(-2);
    //check if it is current month
    if (fromMonth.toString() === isMonth.toString()) {
      display = true;
    } else {
      display = false;
    }

    //display or not----------
    if (display) {
      classes += `duration day-${fromDay} dur-${diff} ${vendor}`;
    } else {
      classes += "d-none";
    }

    return classes;
  };

  const data = getDaysArray(2020, month);

  const getVendorImg = (vendor) => {
    let imgUrl = "";
    if (vendor === "Airbnb") {
      imgUrl = Airbnb;
    }
    if (vendor === "Bcom") {
      imgUrl = Bcom;
    }
    if (vendor === "Roamhome") {
      imgUrl = Roamhome;
    }
    if (vendor === "MakeMyTrip") {
      imgUrl = MMT;
    }
    if (vendor === "Agoda") {
      imgUrl = Agoda;
    }
    if (vendor === "blocked") {
      imgUrl = Blocked;
    }

    return imgUrl;
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/booking/calendar/sync/get/200/0`,
        config
      );
      const data = await res.data;
      console.log(data, "calendar/sync/get/10/0");
      setApiData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchData = (searchText) => {
    const regex = new RegExp(searchText, "gi");
    const calData = jsonData.filter((val) => {
      return val.property_name.match(regex);
    });

    setApiData(calData);
  };

  return (
    <div className="calendar-container">
      <div className="card">
        <div className="days-container">
          <div className="property-wrapper">
            <div className="property-header">
              <Month
                handleMonth={handleMonth}
                myMonth={month}
                handleSearchData={handleSearchData}
              />
            </div>
            <div className="property-list">
              {apiData.map((x, i) => (
                <div key={i} className="property-name">
                  <Tooltip placement="topLeft" title={x.property_name}>
                    {x.property_name}
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
          <div className="days-wrapper">
            <div className="list-wrapper">
              {data.map((x, index) => (
                <div key={index} className={dayHeader(x.fullDate)}>
                  <div className="day-cell">
                    <div className="day-name">{x.dayName}</div>
                    <div className="date">{x.day}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="activity-row">
              {!loading ? (
                apiData.map((x, i) => (
                  <div key={i} className="activity">
                    <div className="day-rows">
                      {data.map((x, index) => (
                        <div key={index} className={`dates-list ${x.fullDate}`}>
                          {/* {x.fullDate} */}
                        </div>
                      ))}
                    </div>
                    {x.property_values.length > 0 && (
                      <div className="duration-wrapper">
                        {x.property_values.map((b, i) => (
                          <Link
                            to={`/dashboard/${user}/booking/view/${b.event_id}`}
                          >
                            <div
                              key={i}
                              className={`${durationStrip(
                                b.ota_date_from,
                                b.ota_date_to,
                                b.ota_type_name
                              )}`}
                            >
                              <div className="vendor-logo">
                                <img
                                  src={getVendorImg(b.ota_type_name)}
                                  alt="airbnb"
                                />
                              </div>
                              <div className="name-pay">{b.ota_type_name}</div>
                              {/* <div className="name-pay">
                            <span>{b.ota_date_from}</span>
                            <span className="amount">
                              INR {b.ota_date_from}
                            </span>
                          </div> */}
                              {/* <div className="booking-details">
                              <span className="guest_from">{b.guest_from}</span>
                              <span>
                                {b.completed === "true"
                                  ? `completed`
                                  : "upcoming"}
                              </span>
                            </div> */}
                              {/* {b.full_name !== undefined ? (
                              <Tooltip title={`call ${b.full_name}`}>
                                <Button shape="circle" icon={<PhoneFilled />} />
                              </Tooltip>
                            ) : (
                              ""
                            )} */}
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <Skeleton active />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
