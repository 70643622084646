import React, { useState, useEffect, Fragment } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const { Title, Text } = Typography;

function Listings() {
  const [posts, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  const user_level = localStorage.getItem("user_level");

  useEffect(() => {
    fetchAPI();
    localStorage.setItem("listing_id", "");
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/list/latest/500/0`,
        config
      );
      const data = await res.data;

      setPost(data);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    message.success("Listings Loaded Successfully.");
  };

  const handelDelete = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        listing_id: id,
      },
    };
    try {
      const body = { delete: "listing" };
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/listing/create_draft/delete",
        body,
        config
      );
      const data = await res;
      if (data.status === 200) {
        const filteredData = posts.filter((item) => item.listing_id !== id);
        setPost(filteredData);
        message.success("Successfully deleted listing.");
      }
    } catch (err) {
      console.log(err);
      message.success("Error while deleting listing.");
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Property Title",
      dataIndex: "property_title",
      key: "property_title",
      className: "table",
      align: "center",
      width: "20%",
      ...getColumnSearchProps("property_title"),
    },
    {
      title: "Listing ID",
      dataIndex: "listing_id",
      key: "listing_id",
      className: "table",
      align: "center",

      width: "30%",
      ...getColumnSearchProps("listing_id"),
    },
    {
      title: "Is Individual",
      dataIndex: "is_individual",
      className: "table",
      align: "center",

      key: "is_individual",
      ...getColumnSearchProps("is_individual"),
      render: (dataIndex) => {
        if (dataIndex) {
          return <p>True</p>;
        } else {
          return <p>False</p>;
        }
      },
    },
    {
      title: "Actions",
      key: "action",
      className: "table",
      align: "center",
      filters: [
        { text: "Latest", value: "latest" },
        { text: "Oldest", value: "oldest" },
        { text: "Individual", value: "individual" },
        { text: "Cluster", value: "cluster" },
      ],
      render: (x) => {
        if (x) {
          return (
            <Row justify="space-around" align="middle">
              <Popconfirm title="Are you sure？" okText="Yes" cancelText="No">
                {" "}
                <Link
                  to={`/dashboard/${user_level}/edit/listing/${x.listing_id}`}
                >
                  <EditOutlinedIcon
                    onClick={() => {
                      localStorage.setItem("listing_id", x.listing_id);
                    }}
                    cursor="pointer"
                    style={{ color: "green" }}
                  />
                </Link>
              </Popconfirm>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handelDelete(x.listing_id)}
              >
                <DeleteOutlineOutlinedIcon
                  cursor="pointer"
                  style={{ color: "red" }}
                />
              </Popconfirm>

              {x.step === 6 ? (
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handlePublish(x.listing_id)}
                >
                  <Button shape="round" type="primary">
                    Publish
                  </Button>
                </Popconfirm>
              ) : (
                <Button disabled shape="round" type="primary">
                  can't Publish
                </Button>
              )}
            </Row>
          );
        }
      },
    },
  ];

  const handlePublish = async (listing_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
        listing_id,
      },
    };
    setLoading(true);
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/publish`,
        { publish: true },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
        fetchAPI();
      }
      console.log(data, "data");
    } catch (e) {
      console.log(e);
      message.error("Something went wrong with our server.");
    }
    setLoading(false);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Fragment>
      <Card
        title={
          <Title level={2}>
            <Text strong>Listings</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
      >
        <Row>
          <Col lg={24} md={24}>
            <AntdTable
              columns={columns}
              dataSource={posts}
              // onChange={handleTableChange}
              bordered
            />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}
export default Listings;
