import React, { Fragment, memo, useState } from "react";
import { useDispatch } from "react-redux";
import { StepperBasicCreate } from "../../../../../../Redux/actions/stepper_actions";
import { Typography, Button } from "antd";
import {
  Form,
  Input,
  Select as Select1,
  Divider,
  Card,
  InputNumber,
  Row,
  Col,
} from "antd";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { setBeds } from "../../../../../../Utils/session";

const { Text, Title } = Typography;

const Basic = () => {
  const [pdesc, setPdesc] = useState("");

  const dispatch = useDispatch();
  const array = [
    // { name: "Author", value: "author" },
    // { name: "Author ID", value: "author_id", type: "number" },
    { name: "SEO Title", value: "seo_title", type: "string" },
    { name: "Keywords", value: "keywords", type: "string" },
    { name: "SEO Description", value: "seo_description", type: "string" },
  ];

  const nestedArray = [
    { name: "Property Title", value: "property_title", type: "string" },
    { name: "Place", value: "type_property", type: "dropdown" },
    { name: "Property Description", value: "property_desc", type: "textarea" },
    { name: "Type Of Listing", value: "type_of_listing", type: "dropdown1" },
    { name: "Number Of Bedrooms", value: "num_of_bedroom", type: "dropdown3" },
    { name: "Number Of Guests", value: "num_of_guests", type: "dropdown3" },
    { name: "Number Of Beds", value: "num_of_beds", type: "dropdown3" },
    {
      name: "Number Of Bathrooms",
      value: "num_of_bathrooms",
      type: "dropdown3",
    },
    { name: "Number Of Rooms", value: "num_of_rooms", type: "dropdown3" },
    { name: "Size Of Property", value: "size_of_rooms", type: "number" },
    {
      name: "Measurement Of Room",
      value: "unit_of_measure",
      type: "dropdown2",
    },
  ];

  const prop_type_vals = [
    "Apartment",
    "Bed & Breakfast",
    "Boutique Room",
    "Cabin",
    "Castle / Fortress / Palace",
    "Cottage",
    "Farmhouse",
    "Hotel",
    "House",
    "Island",
    "Lodge",
    "Studio",
    "Tent",
    "Villa",
    "Tree House",
  ];

  const unit_of_measureVal = ["sq/ft", "sq/m"];

  const type_of_listing = ["Entire Place", "Shared", "Private Room"];

  let array20 = [];
  for (let i = 1; i <= 20; i++) {
    array20.push(i);
  }

  const onFinish = (values) => {
    const {
      // author,
      // author_id,
      seo_description,
      seo_title,
      keywords,
      property_title,
      type_property,
      type_of_listing,
      num_of_bathrooms,
      num_of_bedroom,
      num_of_beds,
      num_of_guests,
      num_of_rooms,
      unit_of_measure,
      size_of_rooms,
    } = values;
    const dataTo = {
      step: 1,
      // author,
      // author_id,
      seo_title,
      keywords,
      seo_description,
      step_1: {
        property_title,
        type_property,
        property_desc: pdesc,
        type_of_listing,
        num_of_bedroom,
        num_of_guests,
        num_of_beds,
        num_of_bathrooms,
        num_of_rooms,
        size_of_rooms,
        unit_of_measure,
      },
    };
    dispatch(StepperBasicCreate(dataTo));
    setBeds(num_of_beds);
    // console.log(dataTo, "datato");
    // console.log("Success:", { author: email, author_id: uuid(), ...dataTo });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Card
        title={
          <Title level={2}>
            <Text strong>Create Listing</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
      >
        <Row>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            {array.map((x) => {
              if (x.type === "string") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
            })}

            <Divider />
            {nestedArray.map((x) => {
              if (x.type === "string") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>

                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>

                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }

              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    {/* <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    > */}
                    <CKEditor
                      editor={ClassicEditor}
                      // data="<p>Hello from CKEditor 5!</p>"
                      onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setPdesc(data);
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                    {/* </Form.Item> */}
                  </Col>
                );
              }

              if (x.type === "dropdown") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Property Type</label>
                    </div>
                    <Form.Item
                      name="type_property"
                      rules={[
                        {
                          required: true,
                          message: `Please input Place!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Property Type">
                        {prop_type_vals.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown1") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Listing Type</label>
                    </div>
                    <Form.Item
                      name="type_of_listing"
                      rules={[
                        {
                          required: true,
                          message: `Please input type_of_listing!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Type OF Listing">
                        {type_of_listing.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown2") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Units of measurement</label>
                    </div>
                    <Form.Item
                      name="unit_of_measure"
                      rules={[
                        {
                          required: true,
                          message: `Please input unit_of_measure!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="unit of measure">
                        {unit_of_measureVal.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }

              if (x.type === "dropdown3") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.value}`,
                        },
                      ]}
                    >
                      <Select1 placeholder={x.name}>
                        {array20.map((x, i) => (
                          <Select1.Option key={i} value={parseInt(x)}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Col lg={24} className="stepper-submit">
              <Form.Item>
                <Button
                  className="stepper-submit-btn"
                  type="primary"
                  htmlType="submit"
                >
                  Submit details
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Card>
    </Fragment>
  );
};

export default memo(Basic);
