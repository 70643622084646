import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import {
  Table,
  Skeleton,
  message,
  Button,
  Select,
  InputNumber,
  Popconfirm,
  Typography,
  Card,
} from "antd";
import { Modal, Form } from "antd";

const { Title, Text } = Typography;

const Tablee = () => {
  const [dataa, setData] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [coupon, setCoupon] = useState([]);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/tax_class/all`,
        config
      );
      const data = await res.data;
      setCoupon(data);
      const aa = data.map((x) => {
        return x.taxes;
      });
      setData(aa);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("Coupons Loaded Successfully.");
  };

  const expandedRowRender = (value, taxes) => {
    const columns = [
      { title: "Type", dataIndex: "type", key: "type" },
      {
        title: "Applicable For",
        dataIndex: "applicable_on",
        key: "applicable",
      },
      {
        title: "Percentage",
        key: "percentage",
        dataIndex: "percentage",
      },
      {
        title: "Action",
        key: "delete",
        render: (x) => (
          <Popconfirm
            title="Are you sure delete this task?"
            onConfirm={() => handleDelete(value.key, x.tax_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        ),
      },
    ];
    return <Table columns={columns} dataSource={taxes} pagination={false} />;
  };

  const columns = [
    {
      title: "Tax Class Name",
      dataIndex: "tax_class_name",
      key: "tax_class_name",
    },
    { title: "Price Start", dataIndex: "price_start", key: "price_start" },
    { title: "Price End", dataIndex: "price_end", key: "price_end" },
    { title: "Country", dataIndex: "country", key: "country" },
    {
      title: "Action",
      key: "action",
      render: (x) => (
        <Button
          onClick={() => {
            setId({ tax_class_id: x.key });
            console.log(x.key);
            setVisible(true);
          }}
        >
          Add Child Tax
        </Button>
      ),
    },
  ];

  const data = [];
  coupon.map((x) => {
    data.push({
      key: x.tax_class_id,
      tax_class_name: x.tax_class_name,
      price_start: x.price_start,
      price_end: x.price_end,
      country: x.country,
      taxes: x.taxes,
    });
  });

  const handleDelete = async (key, id) => {
    setLoading(true);
    const dataTo = { tax_class_id: key, tax_id: id };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/tax_class/remove_child",
        dataTo,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        fetchAPI();
        message.success("Deleted Successfully");
      }
      if (data.status === 400) {
        message.error("Some error while creating");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        confirmLoading={buttonLoading}
        visible={visible}
        title="Add Tax Class Child"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form}>
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: "Please input the title of collection!",
              },
            ]}
          >
            <Select style={{ width: "100%" }} placeholder="GST">
              <Select.Option value="IGST">IGST</Select.Option>
              <Select.Option value="SGST">SGST</Select.Option>
              <Select.Option value="CGST">CGST</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="percentage">
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              style={{ width: "100%" }}
              placeholder="Percentage"
            />
          </Form.Item>
          <Form.Item name="applicable_on">
            <Select style={{ width: "100%" }} placeholder="Area">
              <Select.Option value="Inter">Inter</Select.Option>
              <Select.Option value="Intra">Intra</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const [visible, setVisible] = useState(false);

  const onCreate = async (values) => {
    const dataTo = {
      ...id,
      taxes: [
        {
          type: values.type,
          percentage: values.percentage,
          applicable_on: values.applicable_on,
        },
      ],
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/tax_class/insert_child",
        dataTo,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
        fetchAPI();
      }
    } catch (err) {
      if (err) {
        message.error(data.remarks);
      }
      setLoading(false);
      setVisible(false);
    }
    setVisible(false);
  };

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : ""}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Taxes List</Text>
          </Title>
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          expandedRowRender={(value) => expandedRowRender(value, value.taxes)}
          bordered
        />

        <CollectionCreateForm
          visible={visible}
          onCreate={onCreate}
          onCancel={() => {
            setVisible(false);
          }}
        />
      </Card>
    </Fragment>
  );
};

export default Tablee;
