import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
  Modal,
  Form,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const { Text, Title } = Typography;

function UserList() {
  const [loading, setLoading] = useState(false);
  const [posts, setPost] = useState([]);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  const [verify] = useState({
    banned: false,
  });
  const user = localStorage.getItem("user_level");
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/auth/user/list/200/0`,
        config
      );
      const data = await res.data;
      setPost(res.data.results);
      console.log(data, "data");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handelBan = async (id) => {
    console.log("banned", id);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    const bannedUser = {
      id: id,
      banned: verify.banned,
    };

    const res = await axios.post(
      "http://18.217.42.86:8000/api/v1/private/auth/user/ban",
      bannedUser,
      config
    );
    const data = await res.data;
    window.location.reload();
  };

  const handelUnban = async (id) => {
    console.log("UnBan", id);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    const bannedUser = {
      id: id,
      banned: verify.banned,
    };

    const res = await axios.post(
      "http://18.217.42.86:8000/api/v1/private/auth/user/unban",
      bannedUser,
      config
    );
    const data = await res.data;
    window.location.reload();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table",
      width: "20%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "table",

      width: "20%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Role",
      dataIndex: "user_level_name",
      className: "table",

      key: "role",
      ...getColumnSearchProps("user_level_name"),
    },
    {
      title: "OTP Verified",
      className: "table",
      key: "otp_verified",
      render: (x) => (
        <Row justify="space-around">
          {/* <div>{x.email}</div> */}
          <div>
            {x.otp_verified ? (
              "Verified"
            ) : (
              <Button1 type="primary" onClick={() => resendOtp(x.email)}>
                Verify
              </Button1>
            )}
          </div>
        </Row>
      ),
    },
    {
      title: "Status",
      key: "status",
      className: "table",

      render: (x) => {
        if (x.banned) {
          return (
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handelUnban(x.id)}
            >
              <Button1>Unban</Button1>
            </Popconfirm>
          );
        } else {
          return (
            <Row justify="space-between">
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handelBan(x.id)}
              >
                <Button1 danger>Ban</Button1>{" "}
              </Popconfirm>
              {x.user_level === 8 ? (
                <Link
                  to={`/dashboard/${user}/propperty-mapper/${x.email}/${x.name}`}
                >
                  <Button1 type="primary">Add Property</Button1>
                </Link>
              ) : (
                ""
              )}
            </Row>
          );
        }
      },
    },
  ];

  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");

  const resendOtp = async (email) => {
    setEmail(email);
    console.log(email, "email");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/auth/user/resend_otp",
        { email },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        setVisible(true);
      }
      console.log(data, "otp data");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleVerify = async (values) => {
    console.log(values, "valuses");
    console.log(email, " otpemail");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/public/auth/verify_account",
        { password: values.password, otp: parseInt(values.otp), email },
        config
      );
      const data = await res.data;
      console.log(data, "otp data");
      setVisible(false);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinishFailed = (e) => {
    console.log(e);
  };

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Users List</Text>
          </Title>
        }
        bordered={true}
      >
        <Row>
          <Col lg={24} md={24}>
            <AntdTable columns={columns} dataSource={posts} bordered />
          </Col>
        </Row>
      </Card>

      <Modal visible={visible} title="Title" footer={null}>
        <Form
          id="myForm"
          onFinish={handleVerify}
          onFinishFailed={onFinishFailed}
          size="large"
        >
          <Row justify="center">
            <Col lg={18}>
              <Form.Item
                name="otp"
                rules={[{ required: true, message: "Please input your OTP!" }]}
              >
                <Input placeholder="enter your OTP" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={18}>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password placeholder="enter Password" />
              </Form.Item>
            </Col>{" "}
          </Row>
          <Row justify="end">
            <Button1 key="back" onClick={() => setVisible(false)}>
              cancel
            </Button1>

            <Button1 key="submit" type="primary" htmlType="submit">
              Submit
            </Button1>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default UserList;
