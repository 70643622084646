import React, { useEffect, useState } from "react";
import { Row, Col, message, Button } from "antd";
import Calendar from "../../components/Calendar";
import QuickListing from "../../components/QuickListing";
import Shortcut from "../../components/shortcut";
import Todo from "../../components/todoList";
import axios from "axios";
import { SyncOutlined } from "@ant-design/icons";
const Home = () => {
  const [state, setState] = useState({});
  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/dashboard`,
        config
      );
      const data = res.data;
      if (res.status === 200) {
        message.success("content Loaded Successfully.");
        console.log(data, "dashbord adat");
        setState(data);
      }
    } catch (error) {
      message.error("Network error, please try again later.");
    }
  };

  const syncCalendar = async () => {
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/booking/calendar/sync`
      );
      const data = await res.data;
      console.log(data, "calendar/sync/");
      if (data.status === 200) {
        message.success(data.remarks);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ marginTop: "-15px" }}>
      <Row justify="space-between" align="middle">
        <Col lg={20}>
          <Row align="middle">
            <Col xs={{ span: 22 }} lg={{ span: 4 }} style={{ margin: "5px" }}>
              <div className="col">
                <p>New Users</p>
                <h1>{state.new_users ? `${state.new_users}` : `NA`}</h1>
              </div>
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 4 }} style={{ margin: "5px" }}>
              <div className="col">
                <p>Bookings</p>
                <h1>
                  {state.no_of_bookings ? `${state.no_of_bookings}` : `NA`}
                </h1>{" "}
              </div>
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 5 }} style={{ margin: "5px" }}>
              <div className="col">
                <p>Revenue</p>
                <h1>
                  Rs. {state.total_billed ? `${state.total_billed} ` : `NA`}
                </h1>{" "}
              </div>
            </Col>

            <Col xs={{ span: 22 }} lg={{ span: 5 }} style={{ margin: "5px" }}>
              <div className="col">
                <p>Commission</p>
                <h1> Rs. {state.commision ? `${state.commision} ` : `NA`}</h1>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={2}>
          <Button
            type="primary"
            shape="round"
            size="small"
            icon={<SyncOutlined />}
            onClick={() => syncCalendar()}
            style={{ marginRight: "1rem" }}
          >
            sync
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "-10px" }}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Calendar />
        </Col>
      </Row>

      <Row style={{ marginTop: "2rem" }} justify="space-around">
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <QuickListing />
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 11 }}>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Shortcut />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Todo />{" "}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
