import React, { useState, useEffect, Fragment } from "react";
import DetailList from "./testimonialDetail";
import { Link } from "react-router-dom";

import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

function TestimonialList() {
  const user = localStorage.getItem("user_level");
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);

    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/testimonial/get_table/all",
        config
      );
      const data = await res.data;
      if (res.status === 200) {
        setData(res.data);
        console.log(data, "data");
        message.success("testimonials Loaded Successfully.");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteTestimonial = async (id) => {
    console.log(id);
    setLoading(true);
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/testimonial/delete",
        { testmonial_id: id },
        config
      );
      const data = await res.data;
      if (res.status === 200) {
        console.log(data, "data");
        message.success("testimonials deleted Successfully.");
        fetchAPI();
      } else {
        message.error("delete failed.");
      }
    } catch (error) {
      console.log(error);
      message.error("Try again later");
    }
    setLoading(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "table",
      width: "20%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Property Name",
      dataIndex: "property_name",
      key: "property_name",
      className: "table",

      width: "20%",
      ...getColumnSearchProps("property_name"),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      className: "table",
      key: "rating",
      ...getColumnSearchProps("rating"),
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer_type",
      className: "table",
      key: "reviewer_type",
      ...getColumnSearchProps("reviewer_type"),
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      className: "table",
      key: "timestamp",
      ...getColumnSearchProps("timestamp"),
    },
    {
      title: "Action",
      key: "action",
      className: "table",
      render: (x) => {
        return (
          <div>
            <Link to={`/dashboard/${user}/testimonials/${x.property_id}`}>
              View
            </Link>
            <Button1
              type="link"
              style={{ color: "red" }}
              onClick={() => deleteTestimonial(x.testmonial_id)}
            >
              Delete
            </Button1>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Testimonial List</Text>
          </Title>
        }
        bordered={true}
      >
        <Row>
          <Col lg={24} md={24}>
            <AntdTable columns={columns} dataSource={data} bordered />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}

export default TestimonialList;
