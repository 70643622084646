import React, { useState, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  Checkbox,
  Button,
  DatePicker,
  InputNumber,
  Divider,
  message,
  Result,
} from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Select } from "antd";

const { Option } = Select;

const { Text, Title } = Typography;

const Booking = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [property, setProperty] = useState("");
  const [search, setSearch] = useState([]);
  const [gst, setGst] = useState(false);
  const [payment, setPayment] = useState(false);

  const [isBooked, setIsBooked] = useState(false);

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user_level");

  function onChangeGst(e) {
    setGst(e.target.checked);
  }

  function onChangePayment(e) {
    setPayment(e.target.checked);
  }

  const handleSearch = async (search) => {
    console.log(search);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/public/listing/search/${search}`,
        config
      );
      const data = await res.data;
      setSearch(data);
      console.log(data, "search");
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values) => {
    let dataTo = {
      properties: booking,
      ...start,
      ...end,
      ...values,
      coupon_applied: values.coupon_applied ? values.coupon_applied : "0",
      payment_agent: payment ? payFields : [],
      booking_initiator_name: values.billed_to,
      booking_initiator_email: values.billed_to_email,
      booking_initiator_phone: values.billed_to_phone,
      agent: true,
    };
    let finalJson = { ...dataTo };
    if (!gst) {
      finalJson = {
        ...dataTo,
        gst_number_invoice: "0",
        gst_company_name: "0",
        gst_company_address: "0",
        gst_company_pin: "0",
        gst_company_state: "0",
      };
    }

    console.log(finalJson, "finalddd");

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/booking/booking/agent/create",
        finalJson,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        setIsBooked(true);
      } else {
        message.error(data.remarks);
      }
      console.log(data, "res");
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [payFields, setPayFields] = useState([
    {
      payment_by: "",
      payment_amount: "",
      trasaction_id: "",
    },
  ]);

  function handleChangePay(i, event, name) {
    const values = [...payFields];
    values[i][name] = event;
    if (name === "payment_amount") {
      values[i][name] = parseInt(event.target.value);
    }
    if (name === "trasaction_id") {
      values[i][name] = event.target.value;
    }
    setPayFields(values);
  }

  function handleAddPay() {
    const values = [...payFields];
    values.push({});
    setPayFields(values);
  }

  function handleRemovePay(i) {
    const values = [...payFields];
    values.splice(i, 1);
    setPayFields(values);
  }

  const handleChangeSearch = (value) => {
    setProperty(value);
  };

  const [booking, setbooking] = useState([
    {
      date_from: "",
      date_to: "",
      num_guests: 0,
      children: 0,
      property_id: "",
      guests: [],
    },
  ]);

  function handleChangeProp(i, event, name) {
    const values = [...booking];
    values[event][name] = i;
    setbooking(values);
  }

  function handleChangePropNums(i, event, name) {
    const values = [...booking];
    values[i][name] = event;
    setbooking(values);
  }

  function handleChangeDate(i, event, name) {
    const values = [...booking];
    values[i][name] = event;
    setbooking(values);
  }

  function handleAddProp() {
    const values = [...booking];
    values.push({
      date_from: "",
      date_to: "",
      num_guests: 0,
      children: 0,
      property_id: "",
      guests: [],
    });
    setbooking(values);
  }

  function handleRemProp(i) {
    const values = [...booking];
    values.splice(i, 1);
    setbooking(values);
  }

  console.log(booking, "booking");

  return (
    <Fragment>
      <Card
        style={{ margin: "15px", borderRadius: "5px" }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Create Booking</Text>
          </Title>
        }
      >
        {!isBooked ? (
          <Row justify="center">
            <Col lg={15}>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                  travelling_for_work: false,
                  payment_initiated: false,
                  need_invoice: false,
                }}
              >
                <Row>
                  <Row>
                    <Col lg={3} style={{ padding: "8px" }}>
                      <Button type="primary" onClick={() => handleAddProp()}>
                        {" "}
                        Add Property
                      </Button>
                    </Col>
                  </Row>
                  {booking.map((field, i) => (
                    <Row justify="center">
                      <Col lg={24}>
                        <Divider orientation="center">
                          Property #{i + 1}
                        </Divider>
                        <div key={`${field}-${i}`}>
                          <Form>
                            <Row>
                              <Col lg={24} style={{ padding: "8px" }}>
                                <Form.Item name="property_id">
                                  <Select
                                    showSearch
                                    placeholder="Property Name/ID"
                                    size="large"
                                    onSearch={(e) => handleSearch(e)}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onChange={(e) =>
                                      handleChangeProp(e, i, "property_id")
                                    }
                                    notFoundContent={null}
                                  >
                                    {search.map((x) => {
                                      return (
                                        <Option key={x.listing_id}>
                                          {x.property_title}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col lg={12} style={{ padding: "8px" }}>
                                <Form.Item name="num_guests">
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    value={field.value || ""}
                                    onChange={(e) =>
                                      handleChangePropNums(i, e, "num_guests")
                                    }
                                    placeholder="Guests"
                                    size="large"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} style={{ padding: "8px" }}>
                                <Form.Item name="children">
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    value={field.value || ""}
                                    onChange={(e) =>
                                      handleChangePropNums(i, e, "children")
                                    }
                                    placeholder="Children"
                                    size="large"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} style={{ padding: "8px" }}>
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="Check In"
                                  size="large"
                                  onChange={(date, dateString) =>
                                    handleChangeDate(i, dateString, "date_from")
                                  }
                                />{" "}
                              </Col>
                              <Col lg={12} style={{ padding: "8px" }}>
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="Check Out"
                                  size="large"
                                  onChange={(date, dateString) =>
                                    handleChangeDate(i, dateString, "date_to")
                                  }
                                />{" "}
                              </Col>
                              <Col lg={24}>
                                <Row justify="end">
                                  <Col lg={2} style={{ padding: "8px 0px" }}>
                                    <Button
                                      type="danger"
                                      disabled={i === 0 ? true : false}
                                      onClick={() => handleRemProp(i)}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  ))}

                  <Col lg={12} style={{ padding: "8px" }}>
                    <Form.Item name="coupon_applied">
                      <Input placeholder="Apply Coupon" size="large" />
                    </Form.Item>
                  </Col>
                  <Col lg={24} style={{ padding: "8px" }}>
                    <Form.Item
                      name="message_to_host"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Number of Children!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Message To Host"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} style={{ padding: "8px" }}>
                    <Form.Item
                      name="travelling_for_work"
                      valuePropName="checked"
                    >
                      <Checkbox>Travelling For Work</Checkbox>
                    </Form.Item>
                  </Col>

                  <Col lg={8} style={{ padding: "8px" }}></Col>
                  <Divider orientation="left">Guest Details</Divider>
                  <Col lg={8} style={{ padding: "8px" }}>
                    <Form.Item
                      name="billed_to"
                      rules={[
                        { required: true, message: "Please enter Name!" },
                      ]}
                    >
                      <Input placeholder="Name" size="large" />
                    </Form.Item>
                  </Col>
                  <Col lg={8} style={{ padding: "8px" }}>
                    <Form.Item
                      name="billed_to_email"
                      rules={[
                        { required: true, message: "Please enter Email!" },
                      ]}
                    >
                      <Input placeholder="Email" size="large" />
                    </Form.Item>
                  </Col>
                  <Col lg={8} style={{ padding: "8px" }}>
                    <Form.Item
                      name="billed_to_phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Number!",
                        },
                      ]}
                    >
                      <Input placeholder="Mobile Number" size="large" />
                    </Form.Item>
                  </Col>

                  <Divider orientation="left">
                    <Form.Item name="need_invoice" valuePropName="checked">
                      <Checkbox onChange={onChangeGst}>Need Invoice</Checkbox>
                    </Form.Item>
                  </Divider>
                  {gst ? (
                    <Col lg={24}>
                      <Row>
                        <Col lg={8} style={{ padding: "8px" }}>
                          <Form.Item name="gst_number_invoice">
                            <Input
                              style={{ width: "100%" }}
                              placeholder="GST Number Invoice"
                              size="large"
                              va
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} style={{ padding: "8px" }}>
                          <Form.Item name="gst_company_name">
                            <Input
                              placeholder="GST Company Name"
                              size="large"
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={8} style={{ padding: "8px" }}>
                          <Form.Item name="gst_company_address">
                            <Input.TextArea
                              placeholder="GST Company Address"
                              size="large"
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={8} style={{ padding: "8px" }}>
                          <Form.Item name="gst_company_pin">
                            <Input
                              style={{ width: "100%" }}
                              placeholder="GST Company PIN"
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={8} style={{ padding: "8px" }}>
                          <Form.Item name="gst_company_state">
                            <Select
                              size="large"
                              placeholder="GST Company State"
                            >
                              <Option value="Mah">Maharashta</Option>
                              <Option value="WB">West Bengal</Option>
                              <Option value="AP">Andhra Pradesh</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Divider orientation="left">
                    <Form.Item name="payment_initiated" valuePropName="checked">
                      <Checkbox onChange={onChangePayment}>
                        Payment Information
                      </Checkbox>
                    </Form.Item>
                  </Divider>
                  {payment
                    ? payFields.map((field, idx) => (
                        <Col lg={24}>
                          <div key={`${field}-${idx}`}>
                            <Form>
                              <Row>
                                <Col lg={12} style={{ padding: "8px" }}>
                                  <Form.Item name="trasaction_id">
                                    <Input
                                      style={{ width: "100%" }}
                                      value={field.value || "0"}
                                      onChange={(e) =>
                                        handleChangePay(idx, e, "trasaction_id")
                                      }
                                      placeholder="Transaction ID"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={12} style={{ padding: "8px" }}>
                                  <Form.Item name="payment_amount">
                                    <Input
                                      style={{ width: "100%" }}
                                      value={field.value || 0}
                                      onChange={(e) =>
                                        handleChangePay(
                                          idx,
                                          e,
                                          "payment_amount"
                                        )
                                      }
                                      placeholder="Payment Amount"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={12} style={{ padding: "8px" }}>
                                  <Form.Item name="payment_by">
                                    <Select
                                      size="large"
                                      value={field.value || "0"}
                                      onChange={(e) =>
                                        handleChangePay(idx, e, "payment_by")
                                      }
                                      placeholder="Payment By"
                                    >
                                      <Option value="cash">Cash</Option>
                                      <Option value="card">Card</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Col lg={1} style={{ padding: "8px" }}>
                                  <PlusCircleOutlined
                                    onClick={() => handleAddPay()}
                                  />
                                </Col>
                                <Col
                                  lg={1}
                                  style={{ padding: "8px", size: "10px" }}
                                >
                                  <MinusCircleOutlined
                                    onClick={() => handleRemovePay(idx)}
                                  />
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      ))
                    : ""}

                  <Form.Item>
                    <Button htmlType="submit">Submit</Button>
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <Result
            status="success"
            title="Booking is Successfully Initiated!"
            subTitle="will get confirmed in a while, please wait."
            extra={[
              <Link to={`/dashboard/${user}/bookings`}>
                <Button type="primary">Go to Bookings List</Button>
              </Link>,
            ]}
          ></Result>
        )}
      </Card>
    </Fragment>
  );
};

export default Booking;
