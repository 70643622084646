export const setSession = (authResult) => {
  // Set the time that the access token will expire at
  // let expiresAt = JSON.stringify(
  //   authResult.expiresIn * 1000 + new Date().getTime()
  // );
  localStorage.setItem("token", authResult.token);
  localStorage.setItem("email", authResult.email);
  localStorage.setItem("user_level", authResult.user_level);
};

export const removeSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("email");
  localStorage.removeItem("user_level");
};

export const setBeds = (num) => {
  localStorage.setItem("beds", num);
};
