import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Input,
  Empty,
  Select,
  Button,
  AutoComplete,
  Typography,
  Card,
  Badge,
  message,
  Skeleton,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

function QuickListing() {
  const { Option } = Select;
  const { Title, Text } = Typography;
  const [property, setProperty] = useState([]);

  const [form, setForm] = useState({});
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  const getPropertyDetails = async () => {
    setLoading(false);

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/dashboard/quick_listing/${property[0].listing_id}`,
        config
      );
      const data = await res.data;
      setLoading(true);

      if (res.status === 200) {
        setData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeProperty = async (name) => {
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/public/listing/search/${name}`
      );
      const data = await res.data;
      console.log(data, "dfdaf");
      setProperty(data);
    } catch (err) {
      console.log(err);
    }

    const getId = property.filter((x) => x.property_title === name);
    const id = getId.length > 0 ? getId[0].listing_id : "";
    setForm({ ...form, property_id: id, property_name: name });
  };

  const toggleVisibility = async (id, visibility) => {
    const dataTO = {
      property_id: id,
      action: !visibility,
      in_review: false,
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/action`,
        dataTO,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success("Action Applied");
        getPropertyDetails();
      } else {
        message.info(data.remarks);
      }
      console.log(data, "listings/action");
    } catch (err) {
      console.log(err);
      message.error("Network error!");
    }
  };

  console.log(data, "state data");
  console.log(loading, "loading data");
  return (
    <Card className="quick-listing">
      <Title level={3}>Quick Listing</Title>
      <Row className="quick-listing-search-field" justify="space-between">
        <Col xs={{ span: 24 }} lg={{ span: 20 }}>
          <AutoComplete
            size="medium"
            style={{ width: "100%" }}
            placeholder="Search for a Property"
            onChange={onChangeProperty}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            {property.map((x, i) => {
              return (
                <Option key={i} value={x.property_title}>
                  {x.property_title}
                </Option>
              );
            })}
          </AutoComplete>
        </Col>

        <Col xs={{ span: 4 }} lg={{ span: 2 }}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => getPropertyDetails()}
          />
        </Col>
      </Row>
      {loading ? (
        <div>
          {data !== null ? (
            <div className="quick-listing-wrapper">
              <Row className="quick-listing-content">
                <Col xs={{ span: 24 }} lg={{ span: 13 }}>
                  <div className="property-title d-flex align-items-center">
                    <Text>
                      <b> {data["property_name"]}, </b>
                    </Text>
                    <span
                      className="badge"
                      style={{
                        marginLeft: "0.5rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "uppercase",
                      }}
                    >
                      #{data.property_id}
                    </span>
                  </div>
                  <div className="property-location">
                    <Text>
                      <b>Location</b> : {data["location"]}
                    </Text>
                  </div>
                  <div className="next-booked">
                    <h3>
                      <b>Next Booked</b>
                    </h3>
                    {data.booking.length > 0
                      ? data.booking.map((x, i) => (
                          <p key={i}>
                            {moment(x["date_from"]).format("Do MMMM YYYY")} -
                            {moment(x["date_to"]).format("Do MMMM YYYY")}
                          </p>
                        ))
                      : "No Future bookings!"}
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    <b>Type</b> : {data["property_type"]}
                  </div>
                  <Row className="place-type" justify="space-around">
                    <Col className="place-type-badge" lg={{ span: 7 }}>
                      Adults <b>{data["guest"]["adults"]}</b>
                    </Col>
                    <Col className="place-type-badge" lg={{ span: 8 }}>
                      Children <b>{data["guest"]["children"] ? "Y" : "N"}</b>
                    </Col>
                    <Col className="place-type-badge" lg={{ span: 8 }}>
                      Pets <b>{data["guest"]["pets"] ? "Y" : "N"}</b>
                    </Col>
                  </Row>
                  <Row className="place-type" justify="space-around">
                    <Col className="place-type-badge" lg={{ span: 10 }}>
                      Extra Guest{" "}
                      <b>
                        {data["guest"]["extra_guest"]
                          ? data["guest"]["extra_guest"]
                          : "-"}
                      </b>
                    </Col>
                    <Col className="place-type-badge" lg={{ span: 9 }}>
                      Bedrooms <b>{data["Bedrooms"]}</b>
                    </Col>
                  </Row>
                  <Row
                    className="place-btn-group"
                    style={{ marginTop: "0.5rem" }}
                    justify="space-between"
                  >
                    <Col className="place-btn-group-btn" lg={{ span: 10 }}>
                      <Button type="primary">Send Auditor</Button>
                    </Col>
                    <Col className="place-btn-group-btn" lg={{ span: 10 }}>
                      <Button type="primary">Block Dates</Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 4 }} lg={{ span: 10, offset: 1 }}>
                  <Title level={4} style={{ textAlign: "center" }}>
                    Image
                  </Title>

                  <img
                    src={
                      data["featured_image"][0]["img_960"][0]
                        ? data["featured_image"][0]["img_960"][0]["url"]
                        : ""
                    }
                    alt="home"
                    height="234px"
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1.5rem" }} justify="space-between">
                <Col className="last-btn" lg={{ span: 6 }} xs={{ span: 22 }}>
                  <div className="visibility">
                    <Badge
                      status={data.visibility ? "error" : "processing"}
                      text={data.visibility ? "disabled" : "Live"}
                    />
                  </div>
                </Col>
                <Col className="last-btn" lg={{ span: 6 }} xs={{ span: 22 }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      toggleVisibility(data.property_id, data.visibility)
                    }
                  >
                    Toggle Visibility
                  </Button>
                </Col>
                <Col className="last-btn" lg={{ span: 6 }} xs={{ span: 22 }}>
                  <Button type="primary">Edit / View</Button>
                </Col>
              </Row>
            </div>
          ) : (
            <Empty style={{ padding: "6.5rem" }} />
          )}
        </div>
      ) : (
        <div>
          <Skeleton active />
          <Skeleton active />
        </div>
      )}
    </Card>
  );
}
export default QuickListing;
