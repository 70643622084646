import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Table,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Divider,
  Typography,
  Badge,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Data from "../jsons/payoutView.json";
import moment from "moment";

const { Text, Title } = Typography;

function PayoutListView(props) {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(Data);

  // useEffect(() => {
  //   fetchAPI();
  // }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/auth/user/list`,
        config
      );
      const data = await res.data;
      setData(res.data);
      console.log(data, "data");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("content Loaded Successfully.");
  };

  const columns = [
    {
      title: "property name",
      dataIndex: "property_name",
      key: "property_name",
    },
    {
      title: "checkin",
      dataIndex: "checkin",
      key: "checkin",
      render: (x) => {
        return moment(x).format("L");
      },
    },
    {
      title: "checkout",
      dataIndex: "checkout",
      key: "checkout",
      render: (x) => {
        return moment(x).format("L");
      },
    },
    {
      title: "Billed amount",
      dataIndex: "billed_amount",
      key: "billed_amount",
    },
    {
      title: "roamhome commision",
      dataIndex: "roamhome_commision",
      key: "roamhome_commision",
    },
    {
      title: "Homeowner payout",
      dataIndex: "homeowner_payout",
      key: "homeowner_payout",
    },
  ];

  return (
    <div>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Payouts Details </Text>
          </Title>
        }
        bordered={true}
      >
        <Row justify="center" align="middle">
          <Col lg={18} md={18}>
            <Row justify="space-between">
              <div>
                <h1>{Data.home_owner_name}</h1>
                <p> #{Data.home_owner_id}</p>
              </div>
              <Badge
                status={
                  Data.payout_status === "pending" ? "processing" : "success"
                }
                text={Data.payout_status}
              />
            </Row>
            <Divider style={{ margin: "0rem 0rem 1rem 0rem" }} />
            <Row justify="space-between">
              <p>
                <span> Payouts between </span>
                <b>{moment(Data.payout_from).format("L")}</b> -
                <b> {moment(Data.payout_to).format("L")}</b>
              </p>

              <Row>
                <p>added by</p>

                <h1 style={{ marginTop: "-3px", marginLeft: "0.5rem" }}>
                  {Data.added_by}
                </h1>
              </Row>
            </Row>

            <div>
              <Table dataSource={Data.payout_detail} columns={columns} />;
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default PayoutListView;
