import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import CreateUsers from "../../components/Users/CreateUsers";
import UsersList from "../../components/Users/UsersList";
import Table from "../../components/Listing/Table";
import StepperCreate from "../../components/Listing/ListingCreate/StepperCreate";
import StepperEdit from "../../components/Listing/ListingEdit/StepperEdit";
import CreateAmenities from "../../components/Create/CreateAmmenities";
import CreateFacilities from "../../components/Create/CreateFacilities";
import CreateLocation from "../../components/Create/CreateLocation";
import CreateCoupon from "../../components/Coupon/CreateCoupon";
import CouponTable from "../../components/Coupon/CouponTable";
import Tax from "../../components/Tax/Table";
import TaxCreate from "../../components/Tax/CreateTax";
import Booking from "../../components/Booking/Booking";
import ConfirmedBooking from "../../components/Booking/confirmed";
import cancelledBooking from "../../components/Booking/cancelled";
import pendingBooking from "../../components/Booking/pending";
import waiting from "../../components/Booking/waiting";
import BookingView from "../../components/Booking/BookingView";
import BookingModify from "../../components/Booking/modifyBooking";
import Testimonials from "../../components/Testimonials";
import TestimonialsTable from "../../components/Testimonials/table";
import TestimonialsDetail from "../../components/Testimonials/testimonialDetail";
import CMS from "../../components/CMS";
import Messages from "../../components/Messages";
import Home from "./homeLayout";
import CreateGst from "../../components/GstMaster/";
import GstTable from "../../components/GstMaster/table";
import OtaMaster from "../../components/OTA/OtaMaster";
import OtaSync from "../../components/OTA/OtaSync";
import PropertMapper from "../../components/HomeOwner/HomeOwnerPropertMapper";
import Payout from "../../components/Payouts/payoutInfo";
import PayoutList from "../../components/Payouts/lists";
import PayoutListView from "../../components/Payouts/listView";
import CMSList from "../../components/CMS/list";
import CMSListView from "../../components/CMS/listView";
import stayGuide from "../../components/StayGuide";
import PublishedListings from "../../components/Published_Listing/PublishedListings";
import PublishedEditListings from "../../components/Published_Listing/PublishedEdit";
import Labels from "../../components/Labels";

import NOPAGE from "../../../../Utils/PageNotFound";

const Routes = () => {
  const user = localStorage.getItem("user_level");
  return (
    <Switch>
      {" "}
      {/* dashboard home routes for every user_level */}
      <Route exact path={`/dashboard/${user}/home`} component={Home} />
      <Route
        exact
        path={`/dashboard/${user}/create-user`}
        component={CreateUsers}
      />
      <Route
        exact
        path={`/dashboard/${user}/list-user`}
        component={UsersList}
      />
      <Route exact path={`/dashboard/${user}/listing`} component={Table} />
      <Route
        exact
        path={`/dashboard/${user}/create-listing`}
        component={StepperCreate}
      />
      <Route
        exact
        path={`/dashboard/${user}/edit/listing/:id`}
        component={StepperEdit}
      />
      <Route
        exact
        path={`/dashboard/${user}/published-listings`}
        component={PublishedListings}
      />
      <Route
        exact
        path={`/dashboard/${user}/edit/published/:id`}
        component={PublishedEditListings}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/ammenities`}
        component={CreateAmenities}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/facilities`}
        component={CreateFacilities}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/location`}
        component={CreateLocation}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/coupons`}
        component={CreateCoupon}
      />
      <Route
        exact
        path={`/dashboard/${user}/coupons`}
        component={CouponTable}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/create`}
        component={Booking}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/confirmed`}
        component={ConfirmedBooking}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/pending`}
        component={pendingBooking}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/cancelled`}
        component={cancelledBooking}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/waiting`}
        component={waiting}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/view/:id`}
        component={BookingView}
      />
      <Route
        exact
        path={`/dashboard/${user}/booking/modify/:id/:index`}
        component={BookingModify}
      />
      <Route
        exact
        path={`/dashboard/${user}/stay-guide`}
        component={stayGuide}
      />
      <Route exact path={`/dashboard/${user}/taxes`} component={Tax} />
      <Route
        exact
        path={`/dashboard/${user}/create/tax`}
        component={TaxCreate}
      />
      <Route
        exact
        path={`/dashboard/${user}/gst/create`}
        component={CreateGst}
      />
      <Route exact path={`/dashboard/${user}/gst`} component={GstTable} />
      <Route
        exact
        path={`/dashboard/${user}/ota/master`}
        component={OtaMaster}
      />
      <Route exact path={`/dashboard/${user}/ota/sync`} component={OtaSync} />
      <Route
        exact
        path={`/dashboard/${user}/testimonials/create`}
        component={Testimonials}
      />
      <Route
        exact
        path={`/dashboard/${user}/testimonials`}
        component={TestimonialsTable}
      />
      <Route
        exact
        path={`/dashboard/${user}/testimonials/:id`}
        component={TestimonialsDetail}
      />
      <Route exact path={`/dashboard/${user}/cms/create`} component={CMS} />
      <Route exact path={`/dashboard/${user}/cms`} component={CMSList} />
      <Route
        exact
        path={`/dashboard/${user}/cms/:id`}
        component={CMSListView}
      />
      <Route exact path={`/dashboard/${user}/chat`} component={Messages} />
      <Route
        exact
        path={`/dashboard/${user}/propperty-mapper/:email/:name`}
        component={PropertMapper}
      />
      <Route
        exact
        path={`/dashboard/${user}/payout/add`}
        component={PayoutListView}
      />
      <Route exact path={`/dashboard/${user}/payout`} component={PayoutList} />
      <Route exact path={`/dashboard/${user}/payout/:id`} component={Payout} />
      <Route exact path={`/dashboard/${user}/labels`} component={Labels} />
      {/* {user ? <Route path="*" component={NOPAGE} /> : ""} */}
    </Switch>
  );
};

export default Routes;
