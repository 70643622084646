import axios from "axios";
import { message } from "antd";

import {
  STEPPER_INDEX,
  STEPPER_GET_ALL,
  STEPPER_GET_ALL_FAIL,
  STEPPER_CREATE_BASIC,
  STEPPER_CREATE_BASIC_FAIL,
  STEPPER_GET_BASIC,
  STEPPER_CREATE_AMMENITIES_FAIL,
  STEPPER_CREATE_AMMENITIES,
  STEPPER_GET_AMMENITIES,
  STEPPER_GET_AMMENITIES_FAIL,
  STEPPER_GET_BEDROOMS,
  STEPPER_GET_BEDROOMS_FAIL,
  STEPPER_CREATE_MEDIA,
  STEPPER_CREATE_MEDIA_FAIL,
  STEPPER_GET_MEDIA_FAIL,
  STEPPER_GET_MEDIA,
  STEPPER_CREATE_BEDROOMS,
  STEPPER_CREATE_BEDROOMS_FAIL,
  STEPPER_CREATE_LOCATION,
  STEPPER_CREATE_LOCATION_FAIL,
  STEPPER_GET_LOCATION,
  STEPPER_GET_LOCATION_FAIL,
  STEPPER_GET_PRICING_FAIL,
  STEPPER_GET_PRICING,
  STEPPER_CREATE_PRICING,
  STEPPER_CREATE_PRICING_FAIL,
  STEPPER_EDIT_BASIC,
} from "./types";
import { SERVER, listing_id } from "../../Utils/misc";
// import store from "../store";
const token = localStorage.getItem("token");
//GET STEPPER TABLE DATA
export const StepperGetAll = () => async (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${SERVER}/list/latest/2/0`, config);

    dispatch({
      type: STEPPER_GET_ALL,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);

    dispatch({
      type: STEPPER_GET_ALL_FAIL,
    });
  }
};

export const StepperIndex = () => async (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.get(`${SERVER}/list/latest/10/0`, config);
    const data = await res.data;
    dispatch({
      type: STEPPER_INDEX,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_GET_ALL_FAIL,
    });
  }
};

//STEP-1 CREATE
export const StepperBasicCreate = (dataToSubmit) => async (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/individual`, dataToSubmit, config);
    const data = await res;
    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }

    dispatch({
      type: STEPPER_CREATE_BASIC,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_BASIC_FAIL,
      payload: error,
    });
    message.error(error);
  }
};

//STEP-1 CREATE
export const StepperBasicEdit = (dataToSubmit) => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/individual`, dataToSubmit, config);
    const data = await res;

    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }

    dispatch({
      type: STEPPER_EDIT_BASIC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_BASIC_FAIL,
    });
  }
};

//STEP-1 GET
export function StepperBasicGet() {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };
  const request = axios
    .get(`${SERVER}/preview/1`, config)
    .then((response) => response.data);

  return {
    type: STEPPER_GET_BASIC,
    payload: request,
  };
}

//STEP-2 CREATE
export const StepperAmmenitiesCreate = (dataToSubmit) => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(
      `${SERVER}/individual`,
      {
        step: 2,
        step_2: {
          ...dataToSubmit,
        },
      },
      config
    );

    const data = await res;

    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }

    dispatch({
      type: STEPPER_CREATE_AMMENITIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_AMMENITIES_FAIL,
    });
  }
};

//STEP-2 GET
export const StepperAmmenitiesGet = () => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/preview/2`, config);

    dispatch({
      type: STEPPER_GET_AMMENITIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_GET_AMMENITIES_FAIL,
    });
  }
};

//STEP-3 CREATE
export const StepperMediaCreate = (dataToSubmit) => async (dispatch) => {
  const config = {
    headers: {
      listing_id: localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };
  try {
    const res = await axios.post(
      `${SERVER}/individual/upload`,
      dataToSubmit,
      config
    );

    const data = await res;

    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }

    dispatch({
      type: STEPPER_CREATE_MEDIA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_MEDIA_FAIL,
    });
  }
};

//STEP-3 GET
export const StepperMediaGet = () => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/preview/3`, config);

    dispatch({
      type: STEPPER_GET_MEDIA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_GET_MEDIA_FAIL,
    });
  }
};

//STEP-4 CREATE
export const StepperBedroomsCreate = (dataToSubmit) => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/individual`, dataToSubmit, config);
    const data = await res;

    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }
    dispatch({
      type: STEPPER_CREATE_BEDROOMS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_BEDROOMS_FAIL,
    });
  }
};

//STEP-4 GET
export const StepperBedroomsGet = () => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/preview/4`, config);

    dispatch({
      type: STEPPER_GET_BEDROOMS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_GET_BEDROOMS_FAIL,
    });
  }
};

//STEP-5 CREATE
export const StepperLocationCreate = (dataToSubmit) => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/individual`, dataToSubmit, config);
    const data = await res;
    console.log(data, "data");

    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }
    dispatch({
      type: STEPPER_CREATE_LOCATION,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_LOCATION_FAIL,
    });
  }
};

//STEP-5 GET
export const StepperLocationGet = () => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/preview/5`, config);

    dispatch({
      type: STEPPER_GET_LOCATION,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_GET_LOCATION_FAIL,
    });
  }
};

//STEP-5 CREATE
export const StepperPricingCreate = (dataToSubmit) => async (dispatch) => {
  console.log("hitting StepperPricingCreate resux");
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  try {
    console.log("StepperPricingCreate inside try");
    const res = await axios.post(
      `${SERVER}/individual`,
      {
        step: 6,
        step_6: {
          ...dataToSubmit,
        },
      },
      config
    );
    const data = await res;

    if (data.status === 200) {
      console.log(data, "res data");
      message.success(`Step ${data.data.step} is completed..`);
    } else {
      message.error(data.data.remarks);
    }
    dispatch({
      type: STEPPER_CREATE_PRICING,
      payload: res.headers,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_CREATE_PRICING_FAIL,
    });
  }
};

//STEP-6 GET
export const StepperPricingGet = () => async (dispatch) => {
  const config = {
    headers: {
      listing_id: listing_id || localStorage.getItem("listing_id"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  try {
    const res = await axios.post(`${SERVER}/preview/6`, config);

    dispatch({
      type: STEPPER_GET_PRICING,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: STEPPER_GET_PRICING_FAIL,
    });
  }
};
