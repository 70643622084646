import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Table as AntdTable,
  Button,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Divider,
  Typography,
} from "antd";

import Data from "./data.json";

const { Text, Title } = Typography;

function CMSListView(props) {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(Data);

  // useEffect(() => {
  //   fetchAPI();
  // }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/auth/user/list`,
        config
      );
      const data = await res.data;
      setData(res.data);
      console.log(data, "data");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("content Loaded Successfully.");
  };
  console.log(props.match.params.id, "adfdafdf");
  return (
    <div>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>CMS View </Text>
          </Title>
        }
        bordered={true}
      >
        <Row justify="center">
          <Col lg={14} md={24}>
            <div>
              <h1>{Data["content-title"]}</h1>
              <p>{Data["content-subtitle"]}</p>
            </div>
            <Divider />
            <div>
              <h1>{Data["content-description"]}</h1>
            </div>
            <Divider />
            <Row justify="space-between">
              <h1>{Data["content-key"]}</h1>

              <h1>{Data["modified-by"]}</h1>

              <h1>{Data["modified-on"]}</h1>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default CMSListView;
