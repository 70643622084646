import React, { useState, useEffect, Fragment } from "react";

import axios from "axios";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

import Data from "./list.json";

import { Link } from "react-router-dom";

const { Text, Title } = Typography;

function CMSList() {
  const token = localStorage.getItem("token");
  const user_level = localStorage.getItem("user_level");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(Data);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  // useEffect(() => {
  //   fetchAPI();
  // }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/auth/user/list`,
        config
      );
      const data = await res.data;
      setData(res.data);
      console.log(data, "data");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("content Loaded Successfully.");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Content Title",
      dataIndex: "content-title",
      key: "content-key",
      className: "table",
      ...getColumnSearchProps("content-title"),
    },
    {
      title: "Content Key",
      dataIndex: "content-key",
      key: "content-key",
      className: "table",
      ...getColumnSearchProps("content-key"),
    },
    {
      title: "Modified On",
      dataIndex: "modified-on",
      className: "table",

      key: "role",
      ...getColumnSearchProps("modified-on"),
    },
    {
      title: "Actions",
      key: "status",
      className: "table",

      render: (x, i) => {
        return (
          <Link to={`/dashboard/${user_level}/cms/${x.content_id}`}>
            <Button1 type="primary">View</Button1>
          </Link>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>CMS List</Text>
          </Title>
        }
        bordered={true}
      >
        <Row justify="center">
          <Col lg={14} md={24}>
            <AntdTable columns={columns} dataSource={data} bordered />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}

export default CMSList;
