import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Table from "../../components/HomeOwner/HomeOwnerListings";
import Booking from "../../components/Booking/Booking";
import PublishedEditListings from "../../components/Published_Listing/PublishedEdit";
import Home from "./homeLayout";
import HomeOwnerLayout from "../../components/HomeOwner/HomeOwnerProfile";
import BlockDates from "../../components/HomeOwner/BlockDates";
import BookingView from "../../components/Booking/BookingView";

const Routes = () => {
  const user = localStorage.getItem("user_level");
  return (
    <Switch>
      <Route exact path={`/dashboard/${user}/home`} component={Home} />
      <Route
        exact
        path={`/dashboard/${user}/profile`}
        component={HomeOwnerLayout}
      />
      <Route exact path={`/dashboard/${user}/listing`} component={Table} />
      <Route
        exact
        path={`/dashboard/${user}/edit/published/:id`}
        component={PublishedEditListings}
      />
      <Route exact path={`/dashboard/${user}/bookings`} component={Booking} />
      <Route
        exact
        path={`/dashboard/${user}/create/booking`}
        component={Booking}
      />
      <Route
        exact
        path={`/dashboard/${user}/block-dates/:property_id`}
        component={BlockDates}
      />
      <Route
        exact={true}
        path={`/dashboard/${user}/booking/:id`}
        component={BookingView}
      />
    </Switch>
  );
};

export default Routes;
