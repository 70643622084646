import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Row, Input, Button, Checkbox, Badge, message } from "antd";
import data from "./jsons/todo.json";
const TodoList = () => {
  const [todo, setTodo] = useState([]);
  const [text, setText] = useState("");
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  const getTodos = async () => {
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/dashboard`,
        config
      );
      const data = await res.data.to_do_items;
      console.log(data, "FRFRFFSADFSAFDSFDSFDS");
      setTodo(data);
    } catch (err) {
      message.error("Network Error.. try again later");
    }
  };

  const addTodo = async () => {
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/misc/todo/create`,
        { content: text },
        config
      );
      const data = await res.data;
      console.log(data, "data");
      if (data.status === 200) {
        message.success("Todo added successfully");
        getTodos();
      }
    } catch (err) {
      message.error("Network Error.. try again later");
    }
  };

  const deleteTodo = async (id) => {
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/misc/todo/delete`,
        { content_id: id },
        config
      );
      const data = await res.data;
      console.log(data, "data");
      if (res.status === 200) {
        message.success("Todo deleted successfully");
        getTodos();
      }
    } catch (err) {
      message.error("Network Error.. try again later");
    }
  };

  useEffect(() => {
    getTodos();
  }, []);

  const handleAdd = () => {
    if (text.length > 0) {
      // setTodo([...todo, { item_title: text }]);
      addTodo();
    }
  };

  return (
    <div className="container-wrapper">
      <div className="todo-list-wrapper">
        <Col>
          <h2>Todo List</h2>
        </Col>
        <Row justify="space-between" style={{ marginBottom: "1rem" }}>
          <Col lg={{ span: 20 }} xs={{ span: 20 }}>
            <Input
              onChange={(e) => setText(e.target.value)}
              placeholder="add todo.."
              required
            />
          </Col>
          <Col lg={{ span: 2 }} xs={{ span: 4 }}>
            <Button type="primary" onClick={handleAdd}>
              Add
            </Button>
          </Col>
        </Row>
        {todo.map((x, i) => (
          <Row
            key={i}
            align="middle"
            justify="space-around"
            style={{
              padding: "0rem 0.2rem",
              border: "1px solid",
              margin: "0.5rem 0rem",
            }}
          >
            <Col lg={{ span: 1 }} xs={{ span: 2 }}>
              <Checkbox onClick={() => deleteTodo(x.content_id)} />
            </Col>
            <Col lg={{ span: 18 }} xs={{ span: 10 }}>
              <p style={{ marginTop: "0.7rem" }}>{x.content}</p>
            </Col>
            <Col lg={{ span: 4 }} xs={{ span: 10 }}>
              <Badge count={"pending"} />
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};
export default TodoList;
