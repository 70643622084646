import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  Button,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Divider,
  Typography,
  Modal,
  Empty,
  Result,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

import Logo from "../../../../assets/images/black_logo.svg";
// import Data from "./data.json";

const { Text, Title } = Typography;
const { confirm } = Modal;

const user = localStorage.getItem("user_level");
const token = localStorage.getItem("token");

function BookingView(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      token,
    },
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/booking/booking/get_booking/${props.match.params.id}`,
        config
      );
      const data = await res.data;
      if (data.status !== 404) {
        setData(res.data);
      }
      console.log(data, "dcfjmk");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("content Loaded Successfully.");
  };

  const handleCancel = async (bookingIndex) => {
    console.log(bookingIndex, "bookingIndex");
    const stateData = data[bookingIndex];

    let postData = {
      booking_id: stateData.booking_id,
      date_from: stateData.date_from,
      date_to: stateData.date_to,
      payment_agent: stateData.payment_agent,
      booking_status: "cancelled",
    };

    console.log(postData, "postData");

    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/booking/booking/modify`,
        postData,
        config
      );
      const data = res.data;
      if (data.status === 200) {
        message.success(data.remarks);
      }
      console.log(data, "cancellation response");
    } catch (error) {
      console.log(error);
    }
  };

  function showConfirm(i) {
    confirm({
      title: "Do you want to Cancel the booking?",
      icon: <ExclamationCircleOutlined />,
      content: "Please read our cancellation policy and refund rules..",
      okText: "Yes, Cancel",
      cancelText: "No, Keep",
      onOk() {
        return handleCancel(i);
      },
      onCancel() {},
    });
  }

  const classes = (x) => {
    let classes = "default  ";
    if (x === "cancelled") {
      classes += "cancel";
    }
    if (x === "waiting_payment") {
      classes += "waiting";
    }
    return classes;
  };

  const taxBy2 = (comp, val) => {
    let result;
    if (comp === "Intra") {
      result = parseInt(val) / 2;
    } else {
      result = val;
    }
    return result;
  };

  return (
    <div>
      {!loading ? (
        data.length > 0 ? (
          data.map((x, i) => (
            <Card
              className="bookingView-card"
              style={{
                margin: "15px",
                borderRadius: "5px",
              }}
              title={
                <Row justify="space-between">
                  <div>
                    <Title level={2}>
                      <Text> Reservation #{x.booking_id} </Text>
                    </Title>
                    <span className={classes(x.booking_status)}>
                      {x.booking_status === "waiting_payment"
                        ? "Payment pending"
                        : x.booking_status}
                    </span>
                  </div>
                  {/* <div className="header-btns">
                <Button type="link">Print</Button>
                <Button type="link">Edit</Button>
                <Button type="link">Message</Button>
                <Button type="link">Delete</Button>
              </div> */}
                </Row>
              }
              bordered={true}
            >
              <Row justify="center">
                <Col lg={23}>
                  <Row justify="left">
                    <div>
                      Date: {moment(x.time_stamp).format("Do MMMM YYYY")}
                    </div>
                    <div
                      className="guest-detials"
                      style={{ marginLeft: "3rem" }}
                    >
                      <ul>
                        <li style={{ textTransform: "capitalize" }}>
                          <b>{x.billed_to}</b>
                        </li>
                        {x.need_invoice ? (
                          <div>
                            <li>
                              {x.gst_company_name ? x.gst_company_name : ""}
                            </li>
                            <li>
                              {x.gst_company_address
                                ? x.gst_company_address
                                : ""}
                            </li>
                            <li>
                              {x.gst_company_state ? x.gst_company_state : ""}
                            </li>
                            <li>
                              <b> Pin</b> :{" "}
                              {x.gst_company_pin ? x.gst_company_pin : ""}
                            </li>
                            <li>
                              <b>GSTIN : </b>{" "}
                              {x.gst_number_invoice ? x.gst_number_invoice : ""}
                            </li>
                          </div>
                        ) : (
                          ""
                        )}
                        <li> {x.billed_to_email}</li>
                        <li> {x.billed_to_phone}</li>
                      </ul>
                    </div>
                  </Row>
                  <Divider />
                  <Col>
                    <h1>
                      <b> Details </b>
                    </h1>
                    <Row>
                      <Col lg={6}>
                        <p>
                          Check-In <b>{moment(x.date_from).format("ll")}</b>
                        </p>
                        <p>
                          Check-Out <b>{moment(x.date_to).format("ll")}</b>
                        </p>
                      </Col>
                      <Col lg={6}>
                        <p>
                          Nights <b>{x.days_to_booked}</b>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Divider />
                  <Col>
                    <h1>
                      <b>Reservation detail</b>
                    </h1>
                    <Row>
                      <Col lg={24}>
                        <Row className="payment-detail">
                          <Col lg={8}>
                            <label>
                              <b> Property Name </b>
                            </label>
                          </Col>
                          <Col lg={4}>
                            <label>
                              {" "}
                              <b> No. of Guests </b>
                            </label>
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <label>
                              <b> Price / night </b>
                            </label>
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <label>
                              <b> Total Price</b>
                            </label>
                          </Col>
                        </Row>
                        <Divider />
                        <Row className="payment-detail">
                          <Col lg={8}>
                            <p>{x.property_name}</p>
                          </Col>
                          <Col lg={4}>
                            <p>{x.num_guests}</p>
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <p>Rs. {x.per_night_average}/ Night</p>
                          </Col>
                          <Col lg={6}>
                            {" "}
                            <p>Rs. {x.base_amount}</p>
                          </Col>
                        </Row>
                        <Row className="payment-detail">
                          <Col lg={8}>
                            <p> Discount </p>
                          </Col>
                          <Col lg={4}>{/* <p>6%</p> */}</Col>
                          <Col lg={6}> {/* <p>Rs. 326/ Night</p> */}</Col>
                          <Col lg={6}>
                            {" "}
                            <p>Rs. {x.discount_value}</p>
                          </Col>
                        </Row>

                        {x.tax_applied.map((y) => (
                          <Row className="payment-detail">
                            <Col lg={8}>
                              <p> {y.type} </p>
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <p>{y.percentage}%</p>
                            </Col>
                            <Col lg={6}>
                              {" "}
                              <p>-</p>
                            </Col>
                            <Col lg={6}>
                              {" "}
                              <p>
                                Rs.{" "}
                                {taxBy2(y.applicable_on, x.grand_total_taxes)}
                              </p>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                    <Divider />
                    <Col lg={19}>
                      <Row justify="space-between">
                        <p>
                          {" "}
                          <b> Total </b>
                        </p>
                        <p>Rs. {x.grand_total_amount}</p>
                      </Row>
                    </Col>
                    <Divider />
                    <Col>
                      <h1>
                        <b>Payment History</b>
                      </h1>
                    </Col>
                    <Col lg={20}>
                      <Row className="payment-detail">
                        <Col lg={6}>
                          <label>
                            <b> Payment type </b>
                          </label>
                        </Col>
                        <Col lg={6}>
                          <label>
                            {" "}
                            <b> Transaction id </b>
                          </label>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <label>
                            <b> Amount </b>
                          </label>
                        </Col>
                      </Row>
                      <Divider />
                      {x.payment_agent.length > 0 ? (
                        x.payment_agent.map((pay, i) => (
                          <Row className="payment-detail">
                            <Col lg={6}>
                              <label>
                                <p> {pay.payment_by} </p>
                              </label>
                            </Col>
                            <Col lg={6}>
                              <label>
                                {" "}
                                <p>{pay.trasaction_id} </p>
                              </label>
                            </Col>
                            <Col lg={6}>
                              {" "}
                              <label>
                                <p>Rs. {pay.payment_amount}</p>
                              </label>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <div style={{ margin: "2rem 0rem 2rem 12rem" }}>
                          <h1>No Payment history!</h1>
                        </div>
                      )}
                    </Col>
                    <Col lg={22}>
                      <Row justify="end">
                        <Button
                          type="primary"
                          size="large"
                          style={{ marginRight: "1rem" }}
                        >
                          <Link
                            to={`/dashboard/${user}/booking/modify/${x.booking_id}/${i}`}
                          >
                            Modify
                          </Link>
                        </Button>

                        <Button
                          type="default"
                          size="large"
                          disabled={
                            x.booking_status === "cancelled" ? true : false
                          }
                          onClick={() => showConfirm(i)}
                        >
                          Cancel Booking
                        </Button>
                      </Row>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <Card loading={loading}>
            <Result
              status="error"
              title="Cannot Open other OTA Bookings here!"
              subTitle="Please Contact Rudra!"
            />
          </Card>
        )
      ) : (
        <Card>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Card>
      )}
    </div>
  );
}

export default BookingView;
