import React, { useState } from "react";
import { Form, Input, Button, Card, Row, Typography, Col, Divider } from "antd";
import { SendOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;

const Messages = () => {
  const { Search } = Input;
  const onFinish = (values) => {
    console.log(values);
  };

  const [send, setSend] = useState([
    { text: "Hello, How  u?" },
    { text: "Hello, How  u?" },
    { text: "Hello, How  u?" },
  ]);
  const [recieve, setRecieve] = useState([
    { text: "Hello, How  u?" },
    { text: "Hello, How  u?" },
    { text: "Hello, How  u?" },
  ]);
  return (
    <Row className="chat-app">
      <Col className="chat-members" xs={6} md={6} lg={6}>
        <Title level={4} className="title">
          <Text strong>Chat Room</Text>
        </Title>
        <Divider />
        <ul className="chat-members-wrapper">
          <li className="user-tab">Noel</li>
          <li className="user-tab">Anmol</li>
          <li className="user-tab">Aron</li>
          <li className="user-tab">Rahil</li>
          <li className="user-tab">Hitesh</li>
          <li className="user-tab">Pranesh</li>
        </ul>
      </Col>
      <Col className="chat-window" xs={19} md={18} lg={18}>
        <Card
          style={{
            borderRadius: "5px",
          }}
          title={
            <Title level={4}>
              <Text strong>Noel Sasikanth</Text>
            </Title>
          }
        >
          <Row className="window-wrapper">
            <Row className="chat-one-wrapper">
              {recieve.map((x) => (
                <Col md={24} className="chat-person-one">
                  <p>{x.text}</p>
                </Col>
              ))}
            </Row>
            <Row className="chat-one-wrapper">
              {send.map((x) => (
                <Col md={24} className="chat-person-two">
                  <p>{x.text}</p>
                </Col>
              ))}
            </Row>
          </Row>
          <Search
            placeholder="type something...."
            enterButton="Send"
            size="large"
            onSearch={(value) => console.log(value)}
          />
        </Card>
      </Col>
    </Row>
  );
};
export default Messages;
