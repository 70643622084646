import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Button,
  Divider,
  Skeleton,
  AutoComplete,
  Select,
  Input,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { Option } = Select;

const HomeOwner = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fields, setFields] = useState([{}]);
  const [bankFields, setBankFields] = useState([{}]);

  const token = localStorage.getItem("token");

  useEffect(() => {}, []);

  const onFinish = async (values) => {
    console.log(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeProperty = (e) => {
    console.log(e, "e");
  };

  const handleAddFields = () => {
    const values = [...fields];
    values.push({});
    setFields(values);
  };

  const handleAddBankFields = () => {
    const values = [...bankFields];
    values.push({});
    setBankFields(values);
  };

  return (
    <Fragment>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col lg={17}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>Home owner Profile</Text>
              </Title>
            }
            bordered={true}
          >
            <Row justify="center">
              <Col lg={15}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <Row align="middle" style={{ width: "100%" }}>
                    <Col lg={8}>
                      <h3>Name</h3>
                    </Col>
                    <Col lg={15} style={{ margin: "8px" }}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "input missing!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Name..." size="medium" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row align="middle" style={{ width: "100%" }}>
                    <Col lg={8}>
                      <h3>Age</h3>
                    </Col>
                    <Col lg={15} style={{ margin: "8px" }}>
                      <Form.Item
                        name="age"
                        rules={[
                          {
                            required: true,
                            message: "input missing!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Age..." size="medium" />
                      </Form.Item>
                    </Col>
                  </Row>{" "}
                  <Row align="middle" style={{ width: "100%" }}>
                    <Col lg={8}>
                      <h3>Gender</h3>
                    </Col>
                    <Col lg={15} style={{ margin: "8px" }}>
                      <Select
                        size="medium"
                        style={{ width: "95%" }}
                        placeholder="Select Gender"
                        optionFilterProp="children"
                        // onChange={onChange}
                      >
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="none">I'm not Saying</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row align="middle" style={{ width: "100%" }}>
                    <Col lg={8}>
                      <h3>Contact Number</h3>
                    </Col>
                    <Col lg={15} style={{ margin: "8px" }}>
                      <Form.Item
                        name="number"
                        rules={[
                          {
                            required: true,
                            message: "input missing!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter number..." size="medium" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row align="middle" style={{ width: "100%" }}>
                    <Col lg={8}>
                      <h3>Company Name</h3>
                    </Col>
                    <Col lg={15} style={{ margin: "8px" }}>
                      <Form.Item name="comapany_name">
                        <Input
                          placeholder="Enter Company Name..."
                          size="medium"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider
                    style={{ background: "#292929", margin: "10px 0px" }}
                  />
                  {fields.map((x, i) => (
                    <Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>Company Address</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="comapany_address">
                            <Input
                              placeholder="Enter Company address..."
                              size="medium"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>GSTIN</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="gstin">
                            <Input placeholder="Enter gstin..." size="medium" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>State</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="state">
                            <Input placeholder="Enter state..." size="medium" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col lg={24}>
                        <Button type="ghost" onClick={() => handleAddFields()}>
                          <PlusCircleOutlined />
                          Add
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Divider
                    style={{ background: "#292929", margin: "10px 0px" }}
                  />
                  {bankFields.map((x, i) => (
                    <Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>Account Number</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="acc_number">
                            <Input
                              placeholder="Enter Account Number..."
                              size="medium"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>IFSC Code</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="ifsc_code">
                            <Input
                              placeholder="Enter IFSC Code..."
                              size="medium"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>Bank Name</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="bank_name">
                            <Input
                              placeholder="Enter Bank Name..."
                              size="medium"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row align="middle" style={{ width: "100%" }}>
                        <Col lg={8}>
                          <h3>Name on the Account</h3>
                        </Col>
                        <Col lg={15} style={{ margin: "8px" }}>
                          <Form.Item name="name_on_acc">
                            <Input
                              placeholder="Enter Name on the Account..."
                              size="medium"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Col lg={24}>
                        <Button
                          type="ghost"
                          onClick={() => handleAddBankFields()}
                        >
                          <PlusCircleOutlined />
                          Add
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Col
                    lg={24}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(HomeOwner);
