import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  UserSwitchOutlined,
  UnorderedListOutlined,
  FileAddOutlined,
  MessageOutlined,
  CalendarOutlined,
  MoneyCollectOutlined,
  TagsOutlined,
  ReadOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import Logo from "../../../../assets/images/black_logo.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ toggle }) => {
  const user = localStorage.getItem("user_level");
  return (
    <Sider trigger={null} collapsible collapsed={toggle} className="sidebar">
      <div className="logo d-flex">
        <img
          src={Logo}
          alt="logo"
          height="30px"
          style={{ marginRight: "0.5rem" }}
        />
        {!toggle ? <h1 style={{ marginTop: "0.2rem" }}>Dashboard</h1> : ""}
      </div>
      <Menu mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="Home">
          <Link to={`/dashboard/${user}/home`}>
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="Users"
          title={
            <span>
              <UserSwitchOutlined />
              <span>Users</span>
            </span>
          }
        >
          <Menu.Item key="create-user">
            <Link to={`/dashboard/${user}/create-user`}>Create User</Link>
          </Menu.Item>
          <Menu.Item key="list-users">
            <Link to={`/dashboard/${user}/list-user`}>Check All Users</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Listings"
          title={
            <span>
              <UnorderedListOutlined />
              <span>Listing</span>
            </span>
          }
        >
          <Menu.Item key="create-listings">
            <Link to={`/dashboard/${user}/create-listing`}>Create Listing</Link>
          </Menu.Item>
          <Menu.Item key="listings">
            <Link to={`/dashboard/${user}/listing`}>Check All Listings</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Coupons"
          title={
            <span>
              <TagsOutlined />
              <span>Coupons</span>
            </span>
          }
        >
          <Menu.Item key="create-coupons">
            <Link to={`/dashboard/${user}/create/coupons`}>Create Coupons</Link>
          </Menu.Item>
          <Menu.Item key="listings">
            <Link to={`/dashboard/${user}/coupons`}>Check All Coupons</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="Create"
          title={
            <span>
              <FileAddOutlined />
              <span>Create</span>
            </span>
          }
        >
          <Menu.Item key="create-ammenities">
            <Link to={`/dashboard/${user}/create/ammenities`}>
              Create Ammenities
            </Link>
          </Menu.Item>
          <Menu.Item key="create-facilities">
            <Link to={`/dashboard/${user}/create/facilities `}>
              Create Facilities
            </Link>
          </Menu.Item>
          <Menu.Item key="create-location">
            <Link to={`/dashboard/${user}/create/location`}>
              Create Location
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Tax"
          title={
            <span>
              <MoneyCollectOutlined />
              <span>Tax</span>
            </span>
          }
        >
          <Menu.Item key="create-tax">
            <Link to={`/dashboard/${user}/create/tax`}>Create Tax</Link>
          </Menu.Item>
          <Menu.Item key="taxes">
            <Link to={`/dashboard/${user}/taxes`}>Check All Taxes</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Booking"
          title={
            <span>
              <CalendarOutlined />
              <span>Booking</span>
            </span>
          }
        >
          <Menu.Item key="create-booking">
            <Link to={`/dashboard/${user}/create/booking`}>Create Booking</Link>
          </Menu.Item>
          <Menu.Item key="taxes">
            <Link to={`/dashboard/${user}/bookings`}>Check All Bookings</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="chat">
          <Link to={`/dashboard/${user}/chat`}>
            <MessageOutlined />
            <span>Messages</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="cms">
          <Link to={`/dashboard/${user}/cms`}>
            <ReadOutlined />
            <span>CMS</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="testimonials">
          <Link to={`/dashboard/${user}/testimonials`}>
            <SoundOutlined />
            <span>Testimonials</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
