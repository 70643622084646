import React, { useState } from "react";
import { Select, Button, Row, Input } from "antd";
import {
  CaretRightOutlined,
  CaretLeftOutlined,
  SearchOutlined,
} from "@ant-design/icons";

function MonthDropdown(props) {
  const currentMonth = new Date().getMonth();
  var monthNumber = ("0" + (currentMonth + 1)).slice(-2);
  const [month, setMonth] = useState(monthNumber);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;
  const { myMonth } = props;

  function onChange(value) {
    setMonth(value);
    props.handleMonth(value);
  }

  const handleNext = () => {
    if (parseInt(myMonth) < 12) {
      const num = ("0" + (parseInt(myMonth) + 1)).slice(-2);
      setMonth(num);
      props.handleMonth(num);
    }
  };

  const handlePrev = () => {
    if (parseInt(myMonth) > 1) {
      const num = ("0" + (parseInt(myMonth) - 1)).slice(-2);
      setMonth(num);
      props.handleMonth(num);
    }
  };

  const handelSearch = (e) => {
    const val = e.target.value;
    props.handleSearchData(val);
  };

  console.log(month, "month state");

  return (
    <Row justify="space-around" className="month-dropdown">
      <Button
        icon={<CaretLeftOutlined />}
        size="small"
        disabled={parseInt(month) > 1 ? false : true}
        onClick={() => handlePrev()}
      />{" "}
      <Select
        placeholder="Select Month"
        value={month}
        style={{ width: 115 }}
        onChange={onChange}
        size="small"
      >
        <Option value={"01"}>January</Option>
        <Option value={"02"}>Febraury</Option>
        <Option value={"03"}>March</Option>
        <Option value={"04"}>April</Option>
        <Option value={"05"}>May</Option>
        <Option value={"06"}>June</Option>
        <Option value={"07"}>July</Option>
        <Option value={"08"}>August</Option>
        <Option value={"09"}>September</Option>
        <Option value={"10"}>October</Option>
        <Option value={"11"}>November</Option>
        <Option value={"12"}>December</Option>
      </Select>
      <Button
        icon={<CaretRightOutlined />}
        onClick={() => handleNext()}
        disabled={parseInt(month) < 12 ? false : true}
        size="small"
      />
      <div style={{ position: "relative" }}>
        <Button
          icon={<SearchOutlined />}
          onClick={() => setVisible(!visible)}
          size="small"
        />
        <div
          style={{
            position: "absolute",
            width: 167.5,
            left: -170.2,
            top: 0,
            transition: "width 2s",
          }}
        >
          {visible ? (
            <Input
              placeholder="search..."
              size="small"
              onChange={handelSearch}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </Row>
  );
}

export default MonthDropdown;
