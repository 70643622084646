import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  Button,
  message,
  Skeleton,
  AutoComplete,
} from "antd";

import { Select } from "antd";

const { Option } = Select;

const { Text, Title } = Typography;

const CreateLocation = ({ history }) => {
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingState, setStateLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/location/countries",
        config
      );
      const data = await res.data;
      await setCountryOptions(data);
      console.log(data, "country");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    let json = {
      country: country,
      country_entities: [
        {
          state: state,
          state_entities: [
            {
              ...values,
            },
          ],
        },
      ],
    };
    console.log(json);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/location/add_entities",
        json,
        config
      );
      const data = await res.data;
      console.log(data, "data");
      if (data.status === 200) {
        message.success("Location added successfully.");
      }
      if (data.status === 400) {
        message.error("OOPS! location already exists ");
      }
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getStates = async (id) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/location/states/${id}`,
        config
      );
      const data = await res.data;
      await setStateOptions(data);
      console.log(data, "states");
    } catch (err) {
      console.log(err);
    }
    setStateLoading(false);
  };

  function onChangeCountry(values) {
    setCountry(values);
    const getCid = countryOptions.filter((x) => x.country === values);
    const cid = getCid.map((x) => x.country_id)[0];
    getStates(cid);
  }

  function onChangeState(value) {
    setState(value);
  }

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title
            level={2}
            style={{
              textAlign: "center",
            }}
          >
            <Text strong>Create Location</Text>
          </Title>
        }
        bordered={true}
      >
        <Row>
          <Col offset={8} lg={8} style={{ padding: "8px" }}>
            <AutoComplete
              style={{ width: "100%" }}
              size="large"
              placeholder="Enter Country"
              onChange={(e) => onChangeCountry(e)}
            >
              {countryOptions.map((country, i) => {
                return (
                  <Option key={i} value={country.country}>
                    {country.country}
                  </Option>
                );
              })}
            </AutoComplete>
          </Col>

          <Col offset={8} lg={8} style={{ padding: "8px" }}>
            <AutoComplete
              style={{ width: "100%" }}
              size="large"
              placeholder="Enter State"
              onChange={onChangeState}
            >
              {stateOptions.map((state, i) => {
                return (
                  <Option key={i} value={state.state}>
                    {state.state}
                  </Option>
                );
              })}
            </AutoComplete>
          </Col>
          <Col offset={8} lg={8} style={{ padding: "8px" }}>
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                name="area"
                rules={[{ required: true, message: "Please enter Area!" }]}
              >
                <Input placeholder="Area" size="large" />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" loading={buttonLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default withRouter(CreateLocation);
