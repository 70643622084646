import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Form, Input, Typography, message, Button } from "antd";

const { Text, Title } = Typography;

const CreateAmmenities = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState([{}]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    getAmenities();
  }, []);

  const onFinish = async (values) => {
    setButtonLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/listing/amenities/create",
        values,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success("Ammenities added successfully.");
      }
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const getAmenities = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const query = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/listing/amenities",
        config
      );
      const res = await query.data;
      setData(res.amenities);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log(data, "ssad");
  return (
    <Fragment>
      <Card
        style={{ margin: "15px", borderRadius: "5px" }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Create Ammenities</Text>
          </Title>
        }
        bordered={true}
      >
        <Row>
          <Col offset={8} lg={8}>
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name="name_value"
                  rules={[
                    { required: true, message: "Please enter Ammenities!" },
                  ]}
                >
                  <Input placeholder="Ammenities" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" loading={buttonLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <Col>
              <h1>List of Amenities</h1>
              {data.map((x, i) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>
                    <b> {x.name} </b>
                  </p>
                  <p>{x.enabled ? "Active" : "InActive"}</p>
                </div>
              ))}
            </Col>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default withRouter(CreateAmmenities);
