import React, { useEffect, Fragment, memo, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { StepperMediaCreate } from "../../../../Redux/actions/stepper_actions";
import { DatePicker } from "antd";
import moment from "moment";
import RLDD from "react-list-drag-and-drop/lib/RLDD";

import {
  Form,
  Input,
  Select as Select1,
  Card,
  InputNumber,
  Row,
  Col,
  Skeleton,
  Typography,
  message,
  Divider,
  Radio,
  Button,
  Rate,
} from "antd";
import Select from "react-select";
import {
  PlusOutlined,
  DeleteColumnOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Upload, Modal } from "antd";
import States from "../Listing/ListingCreate/Location/states";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Text, Title } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const Stepper = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const listing_id = localStorage.getItem("listing_id");

  const [test, setTest] = useState(false);

  const [defaultAmmenities, setDefaultAmmenities] = useState([]);
  const [defaultFacilities, setDefaultFacilities] = useState([]);
  const [image, setImage] = useState();
  const [featured, setFeatured] = useState();
  const [loading, setLoading] = useState(true);
  const [stateVal, setStateVal] = useState("");

  const [call, setCall] = useState(false);

  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);

  const [stateImage, setState] = useState({
    previewVisible: false,
    previewImage: "",
    fileList: [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url:
          "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      },
    ],
  });

  const [pdesc, setPdesc] = useState("");

  const [data, setData] = useState({
    facilities: [],
    amenities: [],
  });

  const [forms, changeForm] = useState({
    author: "",
    author_id: "",
    seo_title: "",
    keywords: "",
    seo_description: "",
    property_title: "",
    type_property: "",
    property_desc: "",
    type_of_listing: "",
    num_of_bedroom: "",
    num_of_guests: "",
    num_of_beds: "",
    num_of_bathrooms: "",
    num_of_rooms: "",
    size_of_rooms: "",
    unit_of_measure: "",
    internal_rating: "",
    bedroom: [
      {
        number_guests: "",
        number_beds: "",
        bed_type: [],
      },
    ],
    custom_period: [
      {
        start_date: "",
        end_date: "",
        price_per_night: "",
        weekends_price: "",
        weekend_days: [],
      },
    ],
  });

  const dateFormat = "YYYY/MM/DD";

  const array = [
    // { name: "Author", value: "author" },
    // { name: "Internal Rating", value: "internal_rating" },
    { name: "SEO Title", value: "seo_title" },
    // { name: "Keywords", value: "keywords" },
    // { name: "SEO Description", value: "seo_description" }
  ];

  const nestedArray2 = [
    { name: "Address", value: "address", type: "textarea" },
    { name: "Zip Code", value: "zip_code", type: "number" },
    { name: "Country", value: "country", type: "dropdown" },
    { name: "State", value: "state", type: "dropdown" },
    { name: "Latitude", value: "latitude", type: "number" },
    { name: "Longitude", value: "longitude", type: "number" },
    {
      name: "Minimum Days Of Booking",
      value: "minimum_days_book",
      type: "number",
    },
    {
      name: "Maximum Days Of Booking",
      value: "maximum_days_book",
      type: "number",
    },
    { name: "Check In", value: "check_in", type: "string" },
    { name: "Check Out", value: "check_out", type: "string" },
    { name: "Additional Rules", value: "additional_rules", type: "textarea" },
    { name: "Smoking", value: "smoking", type: "checkbox" },
    { name: "Party", value: "party", type: "checkbox" },
    { name: "Pets", value: "pets", type: "checkbox" },
    { name: "Children", value: "children", type: "checkbox" },
  ];

  const nestedArray = [
    { name: "Property Title", value: "property_title", type: "string" },
    { name: "Proprrty Type", value: "type_property", type: "dropdown" },
    { name: "Property Description", value: "property_desc", type: "textarea" },
    { name: "Type Of Listing", value: "type_of_listing", type: "dropdown1" },
    { name: "Number Of Bedrooms", value: "num_of_bedroom", type: "number" },
    { name: "Number Of Guests", value: "num_of_guests", type: "number" },
    { name: "Number Of Beds", value: "num_of_beds", type: "number" },
    { name: "Number Of Bathrooms", value: "num_of_bath", type: "number" },
    { name: "Number Of Rooms", value: "num_of_rooms", type: "number" },
    { name: "Size Of Property", value: "size_of_rooms", type: "number" },
    {
      name: "Measurement Of Room",
      value: "unit_of_measure",
      type: "dropdown2",
    },
  ];

  const prop_type_vals = [
    "Apartment",
    "Bed & Breakfast",
    "Boutique Room",
    "Cabin",
    "Castle / Fortress / Palace",
    "Cottage",
    "Farmhouse",
    "Hotel",
    "House",
    "Island",
    "Lodge",
    "Studio",
    "Tent",
    "Villa",
    "Tree House",
  ];

  const bed_types = [
    "King",
    "Queen",
    "California King",
    "Double",
    "Triple",
    "Bunk",
    "Floor Mattress",
    "Sofa Cum Bed",
    "Single",
    "Twin",
  ];

  const unit_of_measureVal = ["sq/ft", "sq/m"];

  const type_of_listings = ["Entire Place", "Shared", "Private Room"];

  const nestedArray3 = [
    {
      name: "Additional Guests Allowed",
      value: "additional_guests_allowed",
      type: "checkbox",
    },
    { name: "Instant Booking", value: "instant_book", type: "checkbox" },

    { name: "Price Per Night", value: "price_night", type: "number" },
    { name: "Weekend Price", value: "weekends_price", type: "number" },
    { name: "Weekend Days", value: "weekend_days", type: "dropdown" },
    { name: "Weekly Discount", value: "discount_seven", type: "numberD" },
    {
      name: "Fortnightly Discount",
      value: "discount_fifteen",
      type: "numberD",
    },
    { name: "Monthly Discount", value: "discount_thirty", type: "numberD" },
    {
      name: "Additional Guests Number",
      value: "additional_guests",
      type: "number",
    },
    {
      name: "Additional Guests Amount",
      value: "additional_guests_amount",
      type: "number",
    },
    { name: "Cleaning Fee", value: "cleaning_fee", type: "number" },
    { name: "City Fee", value: "city_fee", type: "number" },
    { name: "Security Deposit", value: "security_deposit", type: "number" },
  ];

  useEffect(() => {
    fetchAPI();
  }, []);

  const onSelectAmmenities = (e) => {
    setDefaultAmmenities(e);
  };

  const onSelectFacilities = (e) => {
    setDefaultFacilities(e);
  };

  async function fetchAPI() {
    setLoading(true);

    const config = {
      headers: {
        listing_id,
        token,
      },
    };

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/view_listing/${listing_id}`,
        config
      );
      const data = await res.data;
      const resAmen = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/amenities`,
        config
      );
      const resFacili = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/facilities`,
        config
      );
      const amenitiesData = await resAmen.data.amenities;
      const facilitiesData = await resFacili.data.facilities;
      setData({
        facilities: facilitiesData,
        amenities: amenitiesData,
      });
      console.log(data);
      changeForm(data);
      setStateVal(data.state);
      setPdesc(data.property_desc);
      const array = data.imgs.map((y) => {
        return y.img_1920.map((item) => {
          return {
            uid: item.id,
            name: item.id,
            status: "done",
            url: item.url,
          };
        });
      });
      const listData = data.imgs[0].img_1920.map((x, i) => {
        return { id: i, url: x.url, key: x.id };
      });
      setList(listData);
      const ammenities = data.amenities.map((x) => {
        return {
          value: x,
          label: x,
        };
      });
      const facilties = data.facilities.map((x) => {
        return {
          value: x,
          label: x,
        };
      });
      setDefaultAmmenities(ammenities);
      setDefaultFacilities(facilties);
      const array2 = data.featured_img.map((y) => {
        return y.img_1920.map((item) => {
          return { imageUrl: item.url };
        });
      });
      setState({
        fileList: array[0],
      });
      setImage(...array2[0]);
    } catch (e) {
      console.log(e);
      message.error("Something went wrong with our server.");
    }
    setLoading(false);
  }

  function render(data) {
    return data.map((x) => {
      return {
        value: x.name,
        label: x.name,
      };
    });
  }

  const renderAmmenities = render(data.amenities);
  const renderFacilities = render(data.facilities);

  const handleCancel = () => setState({ previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleChange = ({ fileList }) => {
    setCall(true);
    setState({ fileList });
  };
  const { previewVisible, previewImage, fileList } = stateImage;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleFeaturedChange = (info) => {
    setFeatured(info.file.originFileObj);
    getBase64(info.file.originFileObj, (imageUrl) => setImage({ imageUrl }));
  };

  function handleAdd(e) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.bedroom = [
      ...copy.bedroom,
      {
        number_guests: "",
        number_beds: "",
        bed_type: [],
      },
    ];
    changeForm(copy);
  }

  function handleGuests(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.bedroom[i].number_guests = parseInt(e.target.value);
    changeForm(copy);
  }

  function handleBeds(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.bedroom[i].number_beds = parseInt(e.target.value);
    changeForm(copy);
  }

  function handleBedType(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.bedroom[i].bed_type = [e];
    changeForm(copy);
  }

  function handleAddPricing(e) {
    let step_6Copy = JSON.parse(JSON.stringify(forms));
    step_6Copy.custom_period = [
      ...step_6Copy.custom_period,
      {
        start_date: new Date(),
        end_date: new Date(),
        price_per_night: "",
        weekends_price: "",
        weekend_days: [],
      },
    ];
    changeForm(step_6Copy);
  }

  function handleStartDate(e, i) {
    let start_date_copy = JSON.parse(JSON.stringify(forms));
    start_date_copy.custom_period[i].start_date = new Date(e);
    changeForm(start_date_copy);
  }

  function handleEndDate(e, i) {
    let end_date_copy = JSON.parse(JSON.stringify(forms));
    end_date_copy.custom_period[i].end_date = new Date(e);
    changeForm(end_date_copy);
  }

  function handlePriceNight(e, i) {
    let price_night_copy = JSON.parse(JSON.stringify(forms));
    price_night_copy.custom_period[i].price_per_night = e.target.value;
    changeForm(price_night_copy);
  }

  function handleWeekendPrice(e, i) {
    let weekends_price_copy = JSON.parse(JSON.stringify(forms));
    weekends_price_copy.custom_period[i].weekends_price = e.target.value;
    changeForm(weekends_price_copy);
  }

  const handleDeleteCP = (index) => {
    setTest(true);
    let copy = forms;
    copy.custom_period.splice(index, 1);
    changeForm(copy);

    setTimeout(() => {
      setTest(false);
    }, 100);

    console.log(copy, "forms updated");
  };

  function handleDays(e, i) {
    let weerknd_day_copy = JSON.parse(JSON.stringify(forms));
    weerknd_day_copy.custom_period[i].weekend_days = e;
    changeForm(weerknd_day_copy);
  }

  const {
    seo_title,
    keywords,
    property_title,
    property_desc,
    type_property,
    type_of_listing,
    num_of_bath,
    num_of_bedroom,
    num_of_beds,
    num_of_guests,
    num_of_rooms,
    unit_of_measure,
    size_of_rooms,
    address,
    zip_code,
    country,
    latitude,
    longitude,
    minimum_days_book,
    maximum_days_book,
    check_in,
    check_out,
    smoking,
    party,
    pets,
    children,
    additional_rules,
    instant_book,
    price_night,
    weekends_price,
    weekend_days,
    discount_seven,
    discount_fifteen,
    discount_thirty,
    additional_guests_allowed,
    additional_guests,
    additional_guests_amount,
    cleaning_fee,
    city_fee,
    security_deposit,
    internal_rating,
  } = forms;

  const sendData = (data) => {
    return data.map((x) => {
      return x.value;
    });
  };

  const getStates = (val) => {
    setStateVal(val);
  };

  const arrayAmmen = sendData(defaultAmmenities);
  const arrayFaci = sendData(defaultFacilities);

  const onFinish = async (values) => {
    const {
      seo_title,
      property_title,
      type_property,
      type_of_listing,
      num_of_bath,
      num_of_bedroom,
      num_of_beds,
      num_of_guests,
      num_of_rooms,
      unit_of_measure,
      size_of_rooms,
      address,
      zip_code,
      country,
      latitude,
      longitude,
      minimum_days_book,
      maximum_days_book,
      check_in,
      check_out,
      additional_rules,
      smoking,
      party,
      pets,
      children,
      instant_book,
      price_night,
      weekends_price,
      weekend_days,
      discount_seven,
      discount_fifteen,
      discount_thirty,
      additional_guests_allowed,
      additional_guests_amount,
      additional_guests,
      cleaning_fee,
      city_fee,
      security_deposit,
      internal_rating,
    } = values;
    const dataTo = {
      seo_title,
      property_title,
      property_desc: pdesc,
      type_property,
      type_of_listing,
      num_of_bath,
      num_of_bedroom,
      num_of_beds,
      num_of_guests,
      num_of_rooms,
      unit_of_measure,
      size_of_rooms,
      amenities: arrayAmmen,
      facilities: arrayFaci,
      address,
      zip_code,
      country,
      latitude,
      longitude,
      state: stateVal,
      minimum_days_book: minimum_days_book ? minimum_days_book : "",
      maximum_days_book: maximum_days_book ? maximum_days_book : "",
      check_in,
      check_out,
      additional_rules,
      smoking,
      party,
      pets,
      children,
      keywords: forms.keywords,
      bedroom: forms.bedroom,
      instant_book,
      price_night,
      weekends_price,
      weekend_days,
      discount_seven,
      discount_fifteen,
      discount_thirty,
      additional_guests_allowed,
      additional_guests,
      additional_guests_amount,
      cleaning_fee,
      city_fee,
      security_deposit,
      custom_period: forms.custom_period,
      internal_rating,
    };

    const config = {
      headers: {
        listing_id,
        token,
      },
    };

    // image upload formData-------------
    const formData = new FormData();
    if (fileList) {
      formData.append("step", 3);
      fileList.map((x) => {
        if (x.originFileObj) {
          formData.append("upl", x.originFileObj);
        } else {
          formData.append("upl", x);
        }
      });
    }

    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/edit/1`,
        dataTo,
        config
      );
      if (call) {
        const imageRes = await axios.post(
          `http://18.217.42.86:8000/api/v1/private/listing/listings/edit/1/multi_page`,
          formData,
          config
        );
        const imageData = await imageRes.data;
        console.log(imageData, "imageData");
      }

      const data = await res.data;
      console.log(data, "data");

      if (data.status === 200) {
        message.success(data.remarks);
        fetchAPI();
      }
    } catch (e) {
      console.log(e);
      message.error("Something went wrong with our server.");
    }
    console.log(dataTo, "dataTo");
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
  };

  const handleSubmitImage = async () => {
    // const formData = new FormData();
    // formData.append("step", 3);
    // for (let file of fileList) {
    //   formData.append("upl", file.originFileObj);
    // }

    const formData2 = new FormData();
    formData2.append("upl", featured);

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
        listing_id: localStorage.getItem("listing_id"),
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/listing/listings/edit/1/featured_image",
        formData2,
        config
      );
      const data = await res.data;
      console.log(data, "featured");
    } catch (err) {
      console.log(err);
    }
  };

  const handleRLDDChange = async (val) => {
    console.log(val);
    setList(val);
  };

  const handleSort = async () => {
    const config = {
      headers: {
        listing_id,
        token,
      },
    };
    const sortList = list.map((x) => x.key);

    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/change_pos`,
        { image_order: sortList },
        config
      );
      const resData = res.data;
      if (resData.status) {
        message.success(resData.remarks);
        fetchAPI();
        setModal(false);
      }
      console.log(resData);
    } catch (e) {
      console.log(e);
      message.error("Action Failed");
    }
  };

  const ModalContainer = () => {
    return (
      <Modal
        style={{ top: 0 }}
        title="Change Image Order"
        visible={modal}
        onOk={() => handleSort()}
        onCancel={() => setModal(false)}
      >
        <div>
          <RLDD
            items={list}
            layout="horizontal"
            itemRenderer={(x, i) =>
              list.length > 0 ? (
                <Col key={i} lg={6} style={{ marginBottom: "1rem" }}>
                  <img src={x.url} alt="test" height="100px" />
                </Col>
              ) : (
                ""
              )
            }
            onChange={handleRLDDChange}
          />
        </div>
      </Modal>
    );
  };

  const handleDeleteImage = async (image_url) => {
    const config = {
      headers: {
        listing_id,
        token,
      },
    };

    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/listing/listings/edit/1/delete_image`,
        { image_url },
        config
      );
      const resData = res.data;
      if (resData.status) {
        message.success(resData.remarks);
        // fetchAPI();
      }
      console.log(resData);
    } catch (e) {
      console.log(e);
      message.error("Action Failed");
    }
  };

  return (
    <Fragment>
      <Card
        title={
          <Title level={2}>
            <Text strong>Edit Published Listing</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
        loading={loading ? <Skeleton active /> : null}
      >
        <Row>
          <Form
            initialValues={{
              seo_title,
              keywords,
              property_title,
              property_desc,
              type_property,
              type_of_listing,
              num_of_bath,
              num_of_bedroom,
              num_of_beds,
              num_of_guests,
              num_of_rooms,
              unit_of_measure,
              size_of_rooms,
              address,
              zip_code,
              country,
              latitude,
              longitude,
              state: forms.state,
              minimum_days_book,
              maximum_days_book,
              check_in,
              check_out,
              additional_rules,
              smoking,
              party,
              pets,
              children,
              instant_book,
              price_night,
              weekends_price,
              weekend_days,
              discount_seven,
              discount_fifteen,
              discount_thirty,
              additional_guests_allowed,
              additional_guests,
              additional_guests_amount,
              cleaning_fee,
              city_fee,
              security_deposit,
              internal_rating,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            <Divider orientation="left">Basic</Divider>

            {array.map((x) => {
              return (
                <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                  <div style={{ textAlign: "left" }}>
                    <label>{x.name}</label>
                  </div>
                  <Form.Item
                    name={x.value}
                    rules={[
                      {
                        required: true,
                        message: `Please input ${x.name}!`,
                      },
                    ]}
                  >
                    <Input placeholder={x.name} />
                  </Form.Item>
                </Col>
              );
            })}
            <Col lg={24} style={{ padding: "8px" }}>
              <div style={{ textAlign: "left" }}>
                <label>Internal Rating</label>
              </div>
              <Row justify="start">
                <Form.Item name="internal_rating">
                  <Rate />
                </Form.Item>
              </Row>
            </Col>

            <Divider />
            {nestedArray.map((x, i) => {
              if (x.type === "string") {
                return (
                  <Col
                    lg={12}
                    style={{ padding: "8px", textAlign: "center" }}
                    key={x.name}
                  >
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px" }} key={x.name}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    {/* <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    > */}
                    <CKEditor
                      editor={ClassicEditor}
                      data={pdesc}
                      onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "undo",
                          "redo",
                        ],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setPdesc(data);
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />{" "}
                    {/* </Form.Item> */}
                  </Col>
                );
              }

              if (x.type === "dropdown") {
                return (
                  <Col lg={12} key={i} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Type of Property</label>
                    </div>
                    <Form.Item
                      name="type_property"
                      rules={[
                        {
                          required: true,
                          message: `Please input type_property!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Type OF Listing">
                        {prop_type_vals.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }

              if (x.type === "dropdown1") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Type of Listing</label>
                    </div>
                    <Form.Item
                      name="type_of_listing"
                      rules={[
                        {
                          required: true,
                          message: `Please input type_of_listing!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Type OF Listing">
                        {type_of_listings.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown2") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Unit of Measurement</label>
                    </div>
                    <Form.Item
                      name="unit_of_measure"
                      rules={[
                        {
                          required: true,
                          message: `Please input unit_of_measure!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="unit of measure">
                        {unit_of_measureVal.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Divider orientation="left">Ammenities & Facilities</Divider>
            <Col lg={12} style={{ padding: "16px" }}>
              <Select
                defaultValue={defaultAmmenities}
                isMulti
                name="colors"
                options={renderAmmenities}
                className="basic-multi-select"
                classNamePrefix="Select Ammenities"
                onChange={(e) => onSelectAmmenities(e)}
              />
            </Col>
            <Col lg={12} style={{ padding: "16px" }}>
              <Select
                defaultValue={defaultFacilities}
                isMulti
                name="colors"
                options={renderFacilities}
                className="basic-multi-select"
                classNamePrefix="Select Facilities"
                onChange={(e) => onSelectFacilities(e)}
              />
            </Col>
            <Divider orientation="left">Images</Divider>
            <div className="clearfix">
              <div style={{ marginBottom: "1rem" }}>
                <Button type="primary" onClick={() => setModal(true)}>
                  Rearrange Images
                </Button>
                {ModalContainer()}
              </div>
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                multiple={true}
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={(e) => handleDeleteImage(e.uid)}
              >
                {fileList.length >= 10 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
            <Divider orientation="left">Featured Images</Divider>
            <Upload
              action="//jsonplaceholder.typicode.com/posts/"
              className="avatar-uploader"
              name="avatar"
              showUploadList={false}
              onChange={handleFeaturedChange}
            >
              {image ? (
                <img src={image.imageUrl} alt="" className="avatar" />
              ) : (
                <PlusOutlined type="plus" className="avatar-uploader-trigger" />
              )}
            </Upload>
            <Col>
              <Button onClick={handleSubmitImage}>Submit</Button>
            </Col>
            <Divider orientation="left">Location</Divider>
            {nestedArray2.map((x) => {
              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px" }} key={x.name}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input.TextArea placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col
                    lg={12}
                    style={{ padding: "8px", textAlign: "center" }}
                    key={x.name}
                  >
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: `Please input ${x.name}!`,
                      //   },
                      // ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown") {
                if (x.value === "state") {
                  return (
                    <Col lg={12} style={{ padding: "8px" }}>
                      <States getStatesVal={getStates} stateVal={stateVal} />
                    </Col>
                  );
                } else {
                  return (
                    <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                      <div style={{ textAlign: "left" }}>
                        <label>{x.name}</label>
                      </div>
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: `Please input Country!`,
                          },
                        ]}
                      >
                        <Select1 placeholder="Country">
                          <Select1.Option value="india">India</Select1.Option>
                          <Select1.Option value="usa">USA</Select1.Option>
                          <Select1.Option value="uk">UK</Select1.Option>
                          <Select1.Option value="russia">Russia</Select1.Option>
                        </Select1>
                      </Form.Item>
                    </Col>
                  );
                }
              }
              if (x.type === "checkbox") {
                return (
                  <Col lg={6} style={{ padding: "8px" }} key={x.name}>
                    <Form.Item
                      label={x.name}
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>True</Radio>
                        <Radio value={false}>False</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "string") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Divider orientation="left">Bedrooms</Divider>
            <Col lg={24}>
              <Row justify="start">
                <Col lg={3} style={{ padding: "8px", textAlign: "center" }}>
                  <Button type="primary" onClick={handleAdd}>
                    Add +
                  </Button>
                </Col>
              </Row>
            </Col>

            {forms
              ? forms.bedroom.map((x, index) => (
                  <Row key={index}>
                    <Col lg={24}>
                      <h1>Bedroom #{index + 1}</h1>
                    </Col>
                    <Col lg={7} style={{ padding: "8px" }}>
                      <div style={{ textAlign: "left" }}>
                        <label>Number Of Guests</label>
                      </div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: `Please input Number Of Guests!`,
                          },
                        ]}
                      >
                        <Input
                          style={{ width: "100%" }}
                          value={x.number_guests}
                          placeholder="Number Of Guests"
                          onChange={(e) => handleGuests(e, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={7} style={{ padding: "8px" }}>
                      <div style={{ textAlign: "left" }}>
                        <label>Number Of Beds</label>
                      </div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: `Please input Number Of Beds!`,
                          },
                        ]}
                      >
                        <Input
                          style={{ width: "100%" }}
                          value={x.number_beds}
                          placeholder="Number Of Beds"
                          onChange={(e) => handleBeds(e, index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={7} style={{ padding: "8px" }}>
                      <div style={{ textAlign: "left" }}>
                        <label>Bed Type</label>
                      </div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: `Please input Bed Type!`,
                          },
                        ]}
                      >
                        <Select1
                          style={{ width: "100%" }}
                          placeholder="Bed Type"
                          value={x.bed_type}
                          onChange={(e) => handleBedType(e, index)}
                        >
                          {bed_types.map((x, i) => (
                            <Select1.Option key={i} value={x}>
                              {x}
                            </Select1.Option>
                          ))}
                        </Select1>
                      </Form.Item>
                    </Col>
                  </Row>
                ))
              : ""}
            <Divider orientation="left">Pricing</Divider>
            {nestedArray3
              ? nestedArray3.map((x) => {
                  if (x.type === "number") {
                    return (
                      <Col
                        lg={12}
                        style={{ padding: "8px", textAlign: "center" }}
                        key={x.name}
                      >
                        <div style={{ textAlign: "left" }}>
                          <label>{x.name}</label>
                        </div>
                        <Form.Item
                          name={x.value}
                          rules={[
                            {
                              required: true,
                              message: `Please input ${x.name}!`,
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder={x.name}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                  if (x.type === "numberD") {
                    return (
                      <Col
                        lg={8}
                        style={{ padding: "8px", textAlign: "center" }}
                        key={x.name}
                      >
                        <div style={{ textAlign: "left" }}>
                          <label>{x.name}</label>
                        </div>
                        <Form.Item
                          name={x.value}
                          rules={[
                            {
                              required: true,
                              message: `Please input ${x.name}!`,
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            placeholder={x.name}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                  if (x.type === "textarea") {
                    return (
                      <Col
                        lg={24}
                        style={{ padding: "8px", textAlign: "center" }}
                        key={x.name}
                      >
                        <div style={{ textAlign: "left" }}>
                          <label>{x.name}</label>
                        </div>
                        <Form.Item
                          name={x.value}
                          rules={[
                            {
                              required: true,
                              message: `Please input ${x.name}!`,
                            },
                          ]}
                        >
                          <Input.TextArea placeholder={x.name} />
                        </Form.Item>
                      </Col>
                    );
                  }
                  if (x.type === "checkbox") {
                    return (
                      <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                        <div style={{ textAlign: "left" }}>
                          <label>{x.name}</label>
                        </div>
                        <Form.Item
                          name={x.value}
                          rules={[
                            {
                              required: true,
                              message: `Please input ${x.name}!`,
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    );
                  }
                  if (x.type === "dropdown") {
                    return (
                      <Col
                        lg={24}
                        style={{ padding: "8px", textAlign: "center" }}
                        key={x.name}
                      >
                        <div style={{ textAlign: "left" }}>
                          <label>{x.name}</label>
                        </div>
                        <Form.Item
                          name="weekend_days"
                          rules={[
                            {
                              required: true,
                              message: `Please input Weekend Days!`,
                            },
                          ]}
                        >
                          <Select1 placeholder="Weekend Days" mode="multiple">
                            <Select1.Option value={0}>Sunday</Select1.Option>
                            <Select1.Option value={1}>Monday</Select1.Option>
                            <Select1.Option value={2}>Tuesday</Select1.Option>
                            <Select1.Option value={3}>Wednesday</Select1.Option>
                            <Select1.Option value={4}>Thursday</Select1.Option>
                            <Select1.Option value={5}>Friday</Select1.Option>
                            <Select1.Option value={6}>Saturday</Select1.Option>
                          </Select1>
                        </Form.Item>
                      </Col>
                    );
                  }
                })
              : ""}
            <Divider orientation="left">Custom Period</Divider>
            <Col lg={3} style={{ padding: "8px", textAlign: "center" }}>
              <Button type="primary" onClick={handleAddPricing}>
                Add +
              </Button>
            </Col>
            {!test ? (
              forms ? (
                forms.custom_period.map((x, index) => (
                  <div key={index}>
                    <Form layout="inline">
                      <Col lg={12} style={{ padding: "8px" }}>
                        <Form.Item
                          label="Start Date"
                          rules={[
                            {
                              required: true,
                              message: `Please input Number Of Guests!`,
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Start Date"
                            onChange={(value, dateString) =>
                              handleStartDate(dateString, index)
                            }
                            defaultValue={moment(
                              forms.custom_period[index].start_date,
                              dateFormat
                            )}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12} style={{ padding: "8px" }}>
                        <Form.Item
                          label="End Date"
                          rules={[
                            {
                              required: true,
                              message: `Please input Number Of Guests!`,
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="End Date"
                            onChange={(value, dateString) =>
                              handleEndDate(dateString, index)
                            }
                            defaultValue={moment(
                              forms.custom_period[index].end_date,
                              dateFormat
                            )}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={7} style={{ padding: "8px" }}>
                        <div style={{ textAlign: "left" }}>
                          <label>Price Per Night</label>
                        </div>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: `Please input Price Per Night!`,
                            },
                          ]}
                        >
                          <Input
                            style={{ width: "100%" }}
                            value={x.price_per_night}
                            placeholder="Please input Price Per Night"
                            onChange={(e) => handlePriceNight(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={7} style={{ padding: "8px" }}>
                        <div style={{ textAlign: "left" }}>
                          <label>Weekend Price</label>
                        </div>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: `Please input Weekend Price!`,
                            },
                          ]}
                        >
                          <Input
                            style={{ width: "100%" }}
                            value={x.weekends_price}
                            placeholder="Please input Weekend Price"
                            onChange={(e) => handleWeekendPrice(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={7} style={{ padding: "8px" }}>
                        <div style={{ textAlign: "left" }}>
                          <label>Weekend Days</label>
                        </div>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: `Please input Weekend Days!`,
                            },
                          ]}
                        >
                          <Select1
                            style={{ width: "100%" }}
                            placeholder="Weekend Days"
                            value={x.weekend_days}
                            onChange={(e) => handleDays(e, index)}
                            mode="multiple"
                          >
                            <Select1.Option value={0}>Sunday</Select1.Option>
                            <Select1.Option value={1}>Monday</Select1.Option>
                            <Select1.Option value={2}>Tuesday</Select1.Option>
                            <Select1.Option value={3}>Wednesday</Select1.Option>
                            <Select1.Option value={4}>Thursday</Select1.Option>
                            <Select1.Option value={5}>Friday</Select1.Option>
                            <Select1.Option value={6}>Saturday</Select1.Option>
                          </Select1>
                        </Form.Item>
                      </Col>
                    </Form>

                    <Divider orientation="center">
                      <Button
                        onClick={() => handleDeleteCP(index)}
                        shape="round"
                        danger
                        icon={<DeleteFilled />}
                      >
                        Delete
                      </Button>
                    </Divider>
                  </div>
                ))
              ) : (
                <Col lg={24}>
                  <Skeleton /> <Skeleton />{" "}
                </Col>
              )
            ) : (
              <Col lg={24}>
                <Skeleton /> <Skeleton />{" "}
              </Col>
            )}
            <Divider />
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Card>
    </Fragment>
  );
};

export default memo(Stepper);
