import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Card,
  Row,
  Typography,
  Col,
  Select,
  Divider,
  message,
} from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    number: "${label} is not a validate number!",
  },
  number: {
    range: "Number must be between ${min} and ${max}",
  },
};

const CreateTax = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [country, setCountry] = useState({
    country_id: "",
    country: "",
  });
  const token = localStorage.getItem("token");

  const [countryOptions, setCountryOptions] = useState([]);
  const [forms, setForms] = useState({
    taxes: [
      {
        type: "",
        percentage: "",
        applicable_on: "",
      },
    ],
  });

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/location/countries",
        config
      );
      const data = await res.data;
      await setCountryOptions(data);
      console.log(data, "country");
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const dataTo = {
      ...values,
      ...country,
      ...forms,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/tax_class/add",
        dataTo,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
      }
      if (data.status === 400) {
        message.error(data.remarks);
      }
    } catch (err) {
      console.log(err);
    }
    setButtonLoading(false);
  };

  function onChangeCountry(values) {
    console.log(values);
    let [id, country] = values.split("---");
    setCountry({
      country_id: id,
      country: country,
    });
  }

  function handleAdd(e) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.taxes = [
      ...copy.taxes,
      {
        type: "",
        percentage: "",
        applicable_on: "",
      },
    ];
    setForms(copy);
  }

  function handletype(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.taxes[i].type = e;
    setForms(copy);
  }

  function handlePercentage(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.taxes[i].percentage = e;
    setForms(copy);
  }

  function handleApplicable(e, i) {
    let copy = JSON.parse(JSON.stringify(forms));
    copy.taxes[i].applicable_on = e;
    setForms(copy);
  }

  return (
    <Fragment>
      <Card
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>Create Tax</Text>
          </Title>
        }
      >
        <Row>
          <Col lg={24}>
            <Card
              style={{
                margin: "15px",
                borderRadius: "5px",
              }}
              title={
                <Title level={2}>
                  <Text strong>Tax Creator</Text>
                </Title>
              }
              bordered={true}
            >
              <Form onFinish={onFinish} validateMessages={validateMessages}>
                <Row>
                  <Col lg={12} style={{ padding: "5px" }}>
                    <Form.Item
                      name="tax_class_name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Tax Name" />
                    </Form.Item>
                  </Col>

                  <Col lg={12} style={{ padding: "5px" }}>
                    <Form.Item
                      name="price_start"
                      rules={[
                        {
                          type: "number",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Price Start"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} style={{ padding: "5px" }}>
                    <Form.Item
                      name="price_end"
                      rules={[
                        {
                          type: "number",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Price End"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} style={{ padding: "5px" }}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        autocomplete
                        showSearch
                        placeholder="Select a Country"
                        style={{ width: "100%" }}
                        onChange={(e) => onChangeCountry(e)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countryOptions.map((country) => {
                          return (
                            <Option
                              key={country.country_id}
                              value={`${country.country_id}---${country.country}`}
                            >
                              {country.country}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Divider />
                  <Col lg={3} style={{ margin: "8px" }}>
                    <Button icon={<PlusOutlined />} onClick={handleAdd}>
                      Add Gst
                    </Button>
                  </Col>
                  {forms.taxes.map((x, index) => (
                    <Col lg={24} style={{ margin: "8px" }}>
                      <Form onFinish={onFinish} layout="inline">
                        <Col lg={7}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: `Please input GST Type!`,
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="GST Type"
                              value={x.bed_type}
                              onChange={(e) => handletype(e, index)}
                            >
                              <Select.Option value="IGST">IGST</Select.Option>
                              <Select.Option value="SGST">SGST</Select.Option>
                              <Select.Option value="CGST">CGST</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={7}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: `Please input Number Of Beds!`,
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              style={{ width: "100%" }}
                              value={x.percentage}
                              placeholder="Percentage"
                              onChange={(e) => handlePercentage(e, index)}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={7}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: `Please input Applicable on!`,
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Applicable on"
                              value={x.bed_type}
                              onChange={(e) => handleApplicable(e, index)}
                            >
                              <Select.Option value="Inter">Inter</Select.Option>
                              <Select.Option value="Intra">Intra</Select.Option>
                              <Select.Option value="All">All</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={3}>
                          <Button
                            color="secondary"
                            icon={<CloseOutlined />}
                            onClick={() => {
                              const data = [...forms.taxes];
                              console.log(data);
                              data.splice(index, 1);
                              setForms({ taxes: [...data] });
                            }}
                          ></Button>
                        </Col>
                      </Form>
                    </Col>
                  ))}
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={buttonLoading}
                    style={{ marginTop: "1rem" }}
                  >
                    Submit
                  </Button>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default withRouter(CreateTax);
