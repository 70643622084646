import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Card,
  Row,
  Typography,
  Col,
  Select,
} from "antd";

const { Title, Text } = Typography;

const CMS = () => {
  const { TextArea } = Input;
  const onFinish = (values) => {
    const user = localStorage.getItem("email");
    const post = {
      ...values,
      "modified-by": user,
      "modified-on": Date.now(),
    };
    console.log(post);
  };

  return (
    <Card
      style={{
        borderRadius: "5px",
        padding: "1rem 3rem",
      }}
      title={
        <Title level={2} style={{ textAlign: "center" }}>
          <Text strong style={{ marginRight: "7rem" }}>
            Add Content
          </Text>
        </Title>
      }
    >
      <Col xs={22} md={15} lg={15} style={{ margin: "0 auto" }}>
        <Form onFinish={onFinish}>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="content-title"
                rules={[
                  {
                    required: true,
                    message: "Please input Title",
                  },
                ]}
              >
                <Input placeholder="Title" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="content-subtitle"
                rules={[
                  {
                    required: true,
                    message: "Please input Sub Title",
                  },
                ]}
              >
                <Input placeholder="Sub Title" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="content-description"
                rules={[
                  {
                    required: true,
                    message: "Please input Testimonial Description",
                  },
                ]}
              >
                <TextArea rows={5} placeholder="Add Description" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="content-key"
                rules={[
                  {
                    required: true,
                    message: "Please input Content Key",
                  },
                ]}
              >
                <Input placeholder="Content Key" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              lg={20}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Card>
  );
};
export default CMS;
