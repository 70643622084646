import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Skeleton, message, Typography, Button } from "antd";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { StepperMediaCreate } from "../../../../../../Redux/actions/stepper_actions";
const { Title, Text } = Typography;
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }
const token = localStorage.getItem("token");
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const Image = () => {
  const dispatch = useDispatch();
  const listing_id = localStorage.getItem("listing_id");

  const [image, setImage] = useState();
  const [featured, setFeatured] = useState();
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    fileList: [],
  });
  const handleCancel = () => setState({ previewVisible: false });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };
  const handleChange = ({ fileList }) => setState({ fileList });
  const { previewVisible, previewImage, fileList } = state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("step", 3);
    for (let file of fileList) {
      formData.append("upl", file.originFileObj);
    }
    dispatch(StepperMediaCreate(formData));
    const formData2 = new FormData();
    formData2.append("step", 3);
    formData2.append("upl", featured);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
        listing_id,
      },
    };
    console.log(listing_id, "listingId");
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/listing/create_draft/individual/uploadSingle",
        formData2,
        config
      );
      const data = await res.data;
      console.log(data, "featured");
    } catch (err) {
      console.log(err);
    }
  };
  const handleFeaturedChange = (info) => {
    setFeatured(info.file.originFileObj);
    getBase64(info.file.originFileObj, (imageUrl) => setImage({ imageUrl }));
  };
  return (
    <Card style={{ margin: "15px", borderRadius: "5px" }}>
      {" "}
      <div className="clearfix">
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          multiple={true}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
      <br />
      <br />
      <Title>
        <Text>Featured Image</Text>
      </Title>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        className="avatar-uploader"
        name="avatar"
        showUploadList={false}
        onChange={handleFeaturedChange}
      >
        {image ? (
          <img src={image.imageUrl} alt="" className="avatar" />
        ) : (
          <PlusOutlined type="plus" className="avatar-uploader-trigger" />
        )}
      </Upload>
      <Button
        className="stepper-submit"
        type="primary"
        htmlType="submit"
        onClick={handleSubmit}
      >
        Submit Details
      </Button>{" "}
    </Card>
  );
};
export default Image;
