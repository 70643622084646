import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  message,
  Form,
  Input,
  InputNumber,
  Button,
  Card,
  Row,
  Typography,
  Col,
  Select,
  Rate,
  Upload,
} from "antd";
import { DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const Testimonials = () => {
  const [search, setSearch] = useState([]);
  const [property, setProperty] = useState();
  const [date, setDate] = useState();
  const [file, setFile] = useState();
  const [propImage, setPropImage] = useState("");

  const handleSearch = async (search) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/public/listing/search/${search}`,
        config
      );
      const data = await res.data;
      setSearch(data);
      console.log(data, "search");
    } catch (err) {
      console.log(err);
    }
  };

  const getPropertyDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    console.log(property, "proprop");
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/dashboard/quick_listing/${search[0].listing_id}`,
        config
      );
      const data = await res.data;

      if (res.status === 200) {
        console.log(data, "quick data");
        setPropImage(data["featured_image"][0]["img_960"][0]["url"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { TextArea } = Input;

  const onFinish = async (values) => {
    const dataTo = {
      ...file,
      ...property,
      ...date,
      ...values,
      property_img: propImage,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/testimonial/create",
        dataTo,
        config
      );
      const data = await res.data;
      console.log(data, "search");
    } catch (err) {
      console.log(err);
    }
    console.log(dataTo);
  };

  const handleProperty = (e) => {
    const [id, name] = e.split("---");
    setProperty({ property_id: id, property_name: name });
    getPropertyDetails();
  };

  console.log(propImage, "propImage");

  function onChange(dateStrings) {
    setDate({ stay_from: dateStrings[0], stay_to: dateStrings[1] });
  }
  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
  };

  const onChangeImg = async (info) => {
    if (info.file.status === "done") {
      console.log(info.file.originFileObj);
      const form = new FormData();
      form.append("upl", info.file.originFileObj);
      form.append("usage_for", "misc");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      };
      try {
        const res = await axios.post(
          "http://18.217.42.86:8000/api/v1/private/misc/media/upload",
          form,
          config
        );
        const data = await res.data;
        if (data.status == "200") {
          console.log("inside");
          setFile({
            user_img: `https://roamhometechtest.s3.us-east-2.amazonaws.com/${data.key}`,
          });
        }
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Card
      style={{
        borderRadius: "5px",
        padding: "1rem 3rem",
      }}
      title={
        <Title level={2} style={{ textAlign: "center" }}>
          <Text strong style={{ marginRight: "7rem" }}>
            Add Testimonials
          </Text>
        </Title>
      }
    >
      <Col xs={22} md={15} lg={15} style={{ margin: "0 auto" }}>
        <Form onFinish={onFinish}>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item>
                <Select
                  showSearch
                  placeholder="Property Name/ID"
                  size="large"
                  onSearch={(e) => handleSearch(e)}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onChange={(e) => handleProperty(e)}
                  notFoundContent={null}
                >
                  {search.map((x) => {
                    return (
                      <Select.Option
                        key={`${x.listing_id}---${x.property_title}`}
                      >
                        {x.property_title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="property_img"
                rules={[
                  {
                    required: true,
                    message: "Please input Testimonial Property Image",
                  },
                ]}
              >
                <Input placeholder="Path to property image" size="large" />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input Name",
                  },
                ]}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                label="User Image"
                rules={[
                  {
                    required: true,
                    message: "Please input Name",
                  },
                ]}
              >
                <Upload {...props} onChange={onChangeImg}>
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="reviewer_type"
                rules={[
                  {
                    required: true,
                    message: "Please input Reviewer",
                  },
                ]}
              >
                {/* <Input placeholder="Reviewer Type" size="large" /> */}
                <Select size="large" placeholder="Reviewer Type">
                  <Select.Option value="user">User</Select.Option>
                  <Select.Option value="home_owner">Home Owner</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Testimonial Title",
                  },
                ]}
              >
                <Input placeholder="Testimonial Title" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Testimonial Description",
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  placeholder="Testimonial Description"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={10} style={{ padding: "5px" }}>
              <Form.Item
                label="Rating"
                style={{ marginBottom: "5px" }}
                name="rating"
                rules={[
                  {
                    required: true,
                    message: "Please input Rating Count",
                  },
                ]}
              >
                <Rate />
              </Form.Item>
            </Col>
            <Col lg={10} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                rules={[
                  {
                    required: true,
                    message: "Please input Dates",
                  },
                ]}
              >
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  onChange={(date, ds) => onChange(ds)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={20} style={{ padding: "5px" }}>
              <Form.Item
                style={{ marginBottom: "5px" }}
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Please input Stayed at Property",
                  },
                ]}
              >
                <Input placeholder="Location" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              lg={20}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Card>
  );
};
export default Testimonials;
