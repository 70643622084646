import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import CreateUsers from "../../components/Users/CreateUsers";
import UsersList from "../../components/Users/UsersList";
import Table from "../../components/Listing/Table";
import StepperCreate from "../../components/Listing/ListingCreate/StepperCreate";
import StepperEdit from "../../components/Listing/ListingEdit/StepperEdit";
import CreateAmenities from "../../components/Create/CreateAmmenities";
import CreateFacilities from "../../components/Create/CreateFacilities";
import CreateLocation from "../../components/Create/CreateLocation";
import CreateCoupon from "../../components/Coupon/CreateCoupon";
import CouponTable from "../../components/Coupon/CouponTable";
import Tax from "../../components/Tax/Table";
import TaxCreate from "../../components/Tax/CreateTax";
import Booking from "../../components/Booking/Booking";
import GST from "../../components/GstMaster";
import Testimonials from "../../components/Testimonials";
import CMS from "../../components/CMS";
import Messages from "../../components/Messages";
import Home from "./homeLayout";

const Routes = () => {
  const user = localStorage.getItem("user_level");
  return (
    <Switch>
      {" "}
      {/* dashboard home routes for every user_level */}
      <Route exact path={`/dashboard/${user}/home`} component={Home} />
      <Route
        exact
        path={`/dashboard/${user}/create-user`}
        component={CreateUsers}
      />
      <Route
        exact
        path={`/dashboard/${user}/list-user`}
        component={UsersList}
      />
      <Route exact path={`/dashboard/${user}/listing`} component={Table} />
      <Route
        exact
        path={`/dashboard/${user}/create-listing`}
        component={StepperCreate}
      />
      <Route
        exact
        path={`/dashboard/${user}/edit-listing/`}
        component={StepperEdit}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/ammenities`}
        component={CreateAmenities}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/facilities`}
        component={CreateFacilities}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/location`}
        component={CreateLocation}
      />
      <Route
        exact
        path={`/dashboard/${user}/create/coupons`}
        component={CreateCoupon}
      />
      <Route
        exact
        path={`/dashboard/${user}/coupons`}
        component={CouponTable}
      />
      <Route exact path={`/dashboard/${user}/bookings`} component={Booking} />
      <Route
        exact
        path={`/dashboard/${user}/create/booking`}
        component={Booking}
      />
      <Route exact path={`/dashboard/${user}/taxes`} component={Tax} />
      <Route
        exact
        path={`/dashboard/${user}/create-tax`}
        component={TaxCreate}
      />
      <Route exact path={`/dashboard/${user}/gst`} component={GST} />
      <Route
        exact
        path={`/dashboard/${user}/testimonials`}
        component={Testimonials}
      />
      <Route exact path={`/dashboard/${user}/cms`} component={CMS} />
      <Route exact path={`/dashboard/${user}/chat`} component={Messages} />
    </Switch>
  );
};

export default Routes;
