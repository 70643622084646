import React from "react";
import { Row, Col } from "antd";
import Calendar from "../../components/Calendar";
import QuickListing from "../../components/QuickListing";
import Shortcut from "../../components/shortcut";
import Todo from "../../components/todoList";

const Home = () => {
  return (
    <div>
      <Row justify="space-around">
        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>Bookings</h1>
          <h1>3250</h1>
        </Col>

        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>Amount Spent</h1>
          <h1>1500 /-</h1>
        </Col>

        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>Booiking List </h1>
          <h1>15</h1>
        </Col>

        <Col className="col" xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>Message Center</h1>
          <h1>150000 /-</h1>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
