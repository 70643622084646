import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import App from "./App";

import SuperAdmin from "./Components/Dashboard/Roles/superAdmin";
import Admin from "./Components/Dashboard/Roles/admin";
import KeyAccounts from "./Components/Dashboard/Roles/keyAccounts";
import Finance from "./Components/Dashboard/Roles/finance";
import Operations from "./Components/Dashboard/Roles/operations";
import Auditor from "./Components/Dashboard/Roles/auditor";
import Cleaner from "./Components/Dashboard/Roles/cleaner";
import HomeOwner from "./Components/Dashboard/Roles/homeOwner";
import User from "./Components/Dashboard/Roles/users";
import Technical from "./Components/Dashboard/Roles/technical";

import NOPAGE from "./Utils/PageNotFound";
import UNAUTHORIZED from "./Utils/unAuthorized";

const Routes = () => {
  const user = localStorage.getItem("user_level");

  console.log(window.location.pathname, "propss");
  return (
    <Router>
      {!user ? <Route exact={true} path="/" component={App} /> : ""}
      {user ? (
        user === "1" ? (
          <Switch>
            <Route
              exact={true}
              path={`/dashboard/${user}/home`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create-user`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/list-user`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/listing`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/published-listings`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/edit/published/:id`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create-listing`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/edit/listing/:id`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create/ammenities`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create/facilities`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create/location`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create/coupons`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/coupons`}
              component={SuperAdmin}
            />
            <Route
              exact
              path={`/dashboard/${user}/booking/confirmed`}
              component={SuperAdmin}
            />
            <Route
              exact
              path={`/dashboard/${user}/booking/pending`}
              component={SuperAdmin}
            />
            <Route
              exact
              path={`/dashboard/${user}/booking/cancelled`}
              component={SuperAdmin}
            />
            <Route
              exact
              path={`/dashboard/${user}/booking/waiting`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/booking/create`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/booking/view/:id`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/booking/modify/:id/:index`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/taxes`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/create/tax`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/gst`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/gst/create`}
              component={SuperAdmin}
            />
            <Route
              exact
              path={`/dashboard/${user}/ota/master`}
              component={SuperAdmin}
            />
            <Route
              exact
              path={`/dashboard/${user}/ota/sync`}
              component={SuperAdmin}
            />

            <Route
              exact
              path={`/dashboard/${user}/propperty-mapper/:email/:name`}
              component={SuperAdmin}
            />

            <Route
              exact={true}
              path={`/dashboard/${user}/testimonials`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/testimonials/create`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/testimonials/:id`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/cms`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/cms/create`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/cms/:id`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/chat`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/payout`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/payout/add`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/stay-guide`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/payout/:id`}
              component={SuperAdmin}
            />
            <Route
              exact={true}
              path={`/dashboard/${user}/labels`}
              component={SuperAdmin}
            />

            <Route path="*" component={NOPAGE} />
          </Switch>
        ) : (
          ""
        )
      ) : window.location.pathname !== "/" ? (
        <Route component={UNAUTHORIZED} />
      ) : null}
      {user === "2" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={Admin}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={Admin}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "3" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={Finance}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={Finance}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/tax/create`}
            component={Finance}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={Finance}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst/create`}
            component={Finance}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "4" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={KeyAccounts}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={KeyAccounts}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "5" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={Operations}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={Operations}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "6" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={Auditor}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={Auditor}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "7" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={Cleaner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={Cleaner}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "8" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={HomeOwner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/profile`}
            component={HomeOwner}
          />

          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={HomeOwner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit/published/:id`}
            component={HomeOwner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={HomeOwner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/block-dates/:property_id`}
            component={HomeOwner}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/booking/:id`}
            component={HomeOwner}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "9" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={User}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={User}
          />
        </Switch>
      ) : (
        ""
      )}
      {user === "10" ? (
        <Switch>
          <Route
            exact={true}
            path={`/dashboard/${user}/home`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-user`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/list-user`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/listing`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create-listing`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/edit-listing/:id`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/ammenities`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/facilities`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/location`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/coupons`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/coupons`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/bookings`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/booking`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/taxes`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/create/tax`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/gst`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/testimonials`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/cms`}
            component={Technical}
          />
          <Route
            exact={true}
            path={`/dashboard/${user}/chat`}
            component={Technical}
          />
        </Switch>
      ) : (
        ""
      )}
    </Router>
  );
};

export default Routes;
