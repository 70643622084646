import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import {
  Form,
  Input,
  Select as Select1,
  Radio,
  Card,
  InputNumber,
  Row,
  Col,
} from "antd";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import { StepperLocationCreate } from "../../../../../../Redux/actions/stepper_actions";

import State from "./states";
import { DatePicker } from "antd";
import moment from "moment";
import States from "./states";

const { RangePicker } = DatePicker;

const Locations = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    check_in: "",
    check_out: "",
  });

  const [location, setLocation] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const [stateVal, setStateVal] = useState("");

  const nestedArray = [
    { name: "Address", value: "address", type: "textarea1" },
    { name: "Country", value: "country", type: "dropdown" },
    // { name: "State", value: "state", type: "dropdown" },
    { name: "Zip Code", value: "zip_code", type: "number" },

    { name: "Latitude", value: "latitude", type: "lat" },
    { name: "Longitude", value: "longitude", type: "long" },
    {
      name: "Minimum Days Of Booking",
      value: "minimum_days_book",
      type: "string",
    },
    {
      name: "Maximum Days Of Booking",
      value: "maximum_days_book",
      type: "string",
    },
    { name: "Check In", value: "check_in", type: "dropdown1" },
    { name: "Check Out", value: "check_out", type: "dropdown1" },
    { name: "Additional Rules", value: "additional_rules", type: "textarea" },
    { name: "Smoking", value: "smoking", type: "checkbox" },
    { name: "Party", value: "party", type: "checkbox" },
    { name: "Pets", value: "pets", type: "checkbox" },
    { name: "Children", value: "children", type: "checkbox" },
  ];

  const onFinish = (values) => {
    const {
      zip_code,
      country,

      minimum_days_book,
      maximum_days_book,
      check_in,
      check_out,
      smoking,
      party,
      pets,
      children,
      additional_rules,
    } = values;
    const dataTo = {
      step: 4,
      step_4: {
        address: location.address,
        zip_code,
        country,
        state: stateVal,
        latitude: location.lat.toString(),
        longitude: location.lng.toString(),
        minimum_days_book,
        maximum_days_book,
        check_in: check_in.toString(),
        check_out: check_out.toString(),
        smoking,
        party,
        pets,
        children,
        additional_rules,
      },
    };
    // console.log(dataTo, "dataTOOO");
    dispatch(StepperLocationCreate(dataTo));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getStates = (val) => {
    setStateVal(val);
  };

  let check_in_drpdwn = [];

  for (let i = 1; i < 13; i++) {
    check_in_drpdwn.push(`${i} am`);
  }
  for (let i = 1; i < 13; i++) {
    check_in_drpdwn.push(`${i} pm`);
  }

  return (
    <Fragment>
      <Card style={{ margin: "15px", borderRadius: "5px" }}>
        <Row>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            {nestedArray.map((x) => {
              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input.TextArea placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "textarea1") {
                return (
                  <Col lg={24} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Address</label>
                    </div>{" "}
                    <GooglePlacesAutocomplete
                      onSelect={({ description }) =>
                        geocodeByAddress(description)
                          .then((results) => getLatLng(results[0]))
                          .then(
                            ({ lat, lng }) =>
                              setLocation({ address: description, lat, lng })
                            // console.log(
                            //   "Successfully got latitude and longitude",
                            //   { lat, lng }
                            // )
                          )
                      }
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["in"],
                        },
                      }}
                    />
                  </Col>
                );
              }
              if (x.type === "lat") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item name={x.value}>
                      <Input
                        onChange={(e) =>
                          setLocation({ ...location, lat: e.target.value })
                        }
                        placeholder={location.lat}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "long") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item name={x.value}>
                      <Input
                        onChange={(e) =>
                          setLocation({ ...location, lng: e.target.value })
                        }
                        placeholder={location.lng}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }

              if (x.type === "dropdown1") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item name={x.value}>
                      <Select1 placeholder={x.name}>
                        {check_in_drpdwn.map((x, i) => (
                          <Select1.Option key={i} value={x.split(" ")[0]}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown") {
                return (
                  <>
                    <Col lg={12} style={{ padding: "8px" }}>
                      <div style={{ textAlign: "left" }}>
                        <label>{x.name}</label>
                      </div>{" "}
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: `Please input Country!`,
                          },
                        ]}
                      >
                        <Select1 placeholder="Country">
                          <Select1.Option value="India">India</Select1.Option>
                          <Select1.Option value="usa">USA</Select1.Option>
                          <Select1.Option value="uk">UK</Select1.Option>
                          <Select1.Option value="russia">Russia</Select1.Option>
                        </Select1>
                      </Form.Item>
                    </Col>

                    <Col lg={12} style={{ padding: "8px" }}>
                      <States getStatesVal={getStates} />
                    </Col>
                  </>
                );
              }

              if (x.type === "checkbox") {
                return (
                  <Col lg={6} style={{ padding: "8px" }}>
                    <Form.Item
                      label={x.name}
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "string") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
            })}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Card>
    </Fragment>
  );
};

export default Locations;
