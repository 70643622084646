import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Alert,
  Typography,
  message,
} from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { setSession } from "../../Utils/session";
const { Text, Title } = Typography;

const Login = ({ history }) => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(null);
  const style = {
    padding: "8px 0",
    margin: "16px",
    borderRadius: "4px",
    height: "100%",
  };

  const form = {
    float: "left",
    width: "100%",
    marginTop: "1rem",
  };

  const onFinish = async (values) => {
    setLoading(true);
    setIsAuth(true);
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/public/auth/login",
        values
      );
      const token = await res.headers.token;
      const data = await res.data;
      console.log(data, "data");
      const user_level = data.user_level;
      const sessionData = {
        token,
        email: data.email,
        user_level: data.user_level,
      };
      setSession(sessionData);
      if (data.status) {
        console.log("status true");
        setIsAuth(true);
        console.log("session true", localStorage);
        const getUser = localStorage.getItem("user_level");
        if (getUser.length > 0) {
          history.push(`/dashboard/${user_level}/home`);
          window.location.reload();
        }
      } else {
        setIsAuth(false);
      }
      setLoading(false);
    } catch (error) {
      message.error(`Network issue, try again later.`);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Fragment>
      <Row>
        <Col lg={16}>
          <Card
            style={style}
            title={
              <Title level={2}>
                <Text strong>Welcom To Dashboard</Text>
              </Title>
            }
            bordered={true}
          >
            <Text strong>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of
              the more obscure Latin words, consectetur, from a Lorem Ipsum
              passage, and going through the cites of the word in classical
              literature, discovered the undoubtable source. Lorem Ipsum comes
              from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
              Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
              BC. This book is a treatise on the theory of ethics, very popular
              during the Renaissance. The first line of Lorem Ipsum, "Lorem
              ipsum dolor sit amet..", comes from a line in section 1.10.32. The
              standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those interested. Sections 1.10.32 and 1.10.33 from "de
              Finibus Bonorum et Malorum" by Cicero are also reproduced in their
              exact original form, accompanied by English versions from the 1914
              translation by H. Rackham.
            </Text>
          </Card>{" "}
        </Col>
        <Col lg={8}>
          <Card
            style={style}
            title={
              <Title level={2}>
                <Text strong>Login</Text>
              </Title>
            }
            bordered={true}
          >
            {isAuth === false ? (
              <Alert message="Authentication Failed!" type="error" showIcon />
            ) : (
              ""
            )}
            <Form
              style={form}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>{" "}
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(Login);
