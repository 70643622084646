import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Button,
  Select,
  Input,
  InputNumber,
  Divider,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const { Text, Title } = Typography;
const PropertyMapper = (props) => {
  const [state, setState] = useState({
    data: [],
    value: [],
    fetching: false,
  });

  const { email, name } = props.match.params;

  console.log(email, name);

  const handleSearch = async (search) => {
    console.log(search);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/public/listing/search/${search}`,
        config
      );
      const data = await res.data;
      setState({ data: data });
      console.log(data, "search");
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values) => {
    console.log(values, "valllll");
    const dataTo = {
      property_owner: name,
      property_owner_email: email,
      property_owner_number: values.property_owner_number
        ? values.property_owner_number
        : "",
      property_owner_address: values.property_owner_address
        ? values.property_owner_address
        : "",
      properties: fields,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `http://18.217.42.86:8000/api/v1/private/misc/homeowner_sync`,
        dataTo,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
      }
      if (data.status === 400) {
        message.error(data.remarks);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleChangeProperty(e, i, name) {
    const values = [...fields];
    values[i][name] = e.value;
    setFields(values);
    console.log(e, i, name);
  }
  const [fields, setFields] = useState([{}]);
  function handleChange(i, event, name) {
    const values = [...fields];
    values[i][name] = event;
    setFields(values);
    console.log(i, event, name);
  }
  function handleAdd() {
    const values = [...fields];
    values.push({});
    setFields(values);
  }
  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  return (
    <Fragment>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col lg={17}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>Home owner to Property Mapper</Text>
              </Title>
            }
            bordered={true}
          >
            <Row justify="center">
              <Col lg={15}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <Col lg={24} style={{ padding: "8px" }}>
                    <Form.Item name="property_owner">
                      <Input
                        defaultValue={name}
                        disabled
                        placeholder="Enter Name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={24} style={{ padding: "8px" }}>
                    <Form.Item name="property_owner_email">
                      <Input
                        defaultValue={email}
                        disabled
                        placeholder="Enter Email"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={24} style={{ padding: "8px" }}>
                    <Form.Item name="property_owner_address">
                      <Input.TextArea placeholder="Address" size="large" />
                    </Form.Item>
                  </Col>
                  <Col lg={24} style={{ padding: "8px" }}>
                    <Form.Item name="property_owner_number">
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Mobile Number"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Divider orientation="center">Properties Section</Divider>

                  <div
                    style={{
                      padding: "1rem 2rem",
                    }}
                  >
                    <Button type="primary" onClick={() => handleAdd()}>
                      {" "}
                      Add Property
                    </Button>
                    {fields.map((field, idx) => {
                      return (
                        <Col lg={24}>
                          <div key={`${field}-${idx}`}>
                            <Divider orientation="center">
                              Property #{idx + 1}
                            </Divider>

                            <Form>
                              <Row>
                                <Col lg={24} style={{ padding: "8px" }}>
                                  <Form.Item name="property_id">
                                    <Select
                                      showSearch
                                      size="large"
                                      value={field.value || ""}
                                      defaultActiveFirstOption={false}
                                      showArrow={false}
                                      filterOption={false}
                                      labelInValue
                                      placeholder="Search Property"
                                      notFoundContent={
                                        state.fetching ? (
                                          <Spin size="small" />
                                        ) : null
                                      }
                                      onSearch={handleSearch}
                                      onChange={(e) =>
                                        handleChangeProperty(
                                          e,
                                          idx,
                                          "property_id"
                                        )
                                      }
                                      style={{ width: "100%" }}
                                    >
                                      {state.data.map((d) => (
                                        <Select.Option key={d.listing_id}>
                                          {d.property_title}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col lg={24} style={{ padding: "8px" }}>
                                  <Form.Item name="commission">
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      value={field.value || ""}
                                      onChange={(e) =>
                                        handleChange(idx, e, "commission")
                                      }
                                      placeholder="Commission"
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col lg={22}>
                                  <Row justify="end">
                                    <Col
                                      lg={3}
                                      style={{ padding: "8px 8px 0px 0px" }}
                                    >
                                      <Button
                                        type="danger"
                                        disabled={idx === 0 ? true : false}
                                        onClick={() => handleRemove(idx)}
                                      >
                                        <DeleteOutlined />
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      );
                    })}
                  </div>
                  <Form.Item>
                    <Button
                      style={{ marginTop: "1rem" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default withRouter(PropertyMapper);
