import React, { useState } from "react";
import { Menu, Avatar, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const { Text, Title } = Typography;

const Header = () => {
  const [state, setState] = useState({
    current: "mail",
  });

  const handleClick = (e) => {
    console.log("click ", e);
    setState({
      current: e.key,
    });
  };

  return (
    <div>
      <Menu
        onClick={handleClick}
        selectedKeys={[state.current]}
        mode="horizontal"
      >
        <Menu.Item key="home">
          <Title level={2}>
            <Text strong>RoamHome Dashboard</Text>
          </Title>{" "}
        </Menu.Item>
        <Menu.Item key="mail" className="header-items">
          <Button>TO THE DOCUMENTATION</Button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Header;
