import React, { useState } from "react";
import axios from "axios";
import { message, Col, Row, Input, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function AddLabel() {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };

  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [file, setFile] = useState();

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
  };

  const postData = async () => {
    setLoading(true);
    const send = {
      ...file,
      ...data,
    };
    console.log(send, " final post data");
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/label/add_new",
        send,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
      }
    } catch (err) {
      console.log(err);
      message.error("Network error, please login again");
    }
    setLoading(false);
    window.location.reload();
  };

  const onChangeImg = async (info) => {
    if (info.file.status === "done") {
      setState(true);

      const form = new FormData();
      form.append("upl", info.file.originFileObj);
      form.append("usage_for", "misc");
      console.log(form, "form data");

      try {
        const res = await axios.post(
          "http://18.217.42.86:8000/api/v1/private/misc/media/upload",
          form,
          config
        );
        const data = await res.data;
        if (res.status === 200) {
          setFile({
            label_img: data.key,
          });
          message.success(data.remarks);
        }
        console.log(data);
        setState(false);
      } catch (err) {
        message.error("Network error, please login again");
      }
    }
  };

  return (
    <div>
      <Col lg={22}>
        <Row>
          <Col style={{ margin: "8px" }} lg={8}>
            <Input
              placeholder="label name"
              onChange={(e) => setData({ label_name: e.target.value })}
            />
          </Col>
          <Col style={{ margin: "8px" }} lg={4}>
            <Upload {...props} onChange={onChangeImg}>
              <Button>
                <UploadOutlined /> Click to Upload
              </Button>
            </Upload>
          </Col>
          <Col style={{ margin: "8px" }} lg={4}>
            <Button
              loading={loading}
              disabled={state}
              onClick={() => postData()}
              type="primary"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
export default AddLabel;
