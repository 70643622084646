import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import axios from "axios";
import uuid from "uuid/v4";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const { Title, Text } = Typography;

function CouponTable() {
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState([]);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/booking/coupon/list`,
        config
      );
      const data = await res.data;
      setCoupon(data.result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("Coupons Loaded Successfully.");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Coupon Type",
      key: `${uuid()}`,
      className: "table",
      width: "10%",
      render: (x) => {
        return <p>{x.coupon_type.name}</p>;
      },
      filters: [
        {
          text: "Last Minute Deals",
          value: "Last Minute Deals",
        },
        {
          text: "Buy X Get Y",
          value: "Buy X Get Y",
        },
        {
          text: "Discount Value By Value",
          value: "Discount Value By Value",
        },
        {
          text: "Discount Value By %",
          value: "Discount Value By %",
        },
      ],
      onFilter: (value, x) => x.coupon_type.name.indexOf(value) == 0,
    },
    {
      title: "Coupon Name",
      dataIndex: "coupon_name",
      key: `${uuid()}`,
      className: "table",
      width: "10%",
      ...getColumnSearchProps("coupon_name"),
    },
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      key: `${uuid()}`,
      className: "table",

      width: "10%",
      ...getColumnSearchProps("coupon_code"),
    },
    {
      title: "Coupon Start",
      key: `${uuid()}`,
      // dataIndex: `coupon_start`,
      className: "table",
      width: "10%",
      render: (x) => {
        return <p>{moment(x.coupon_start).format("MM-DD-YYYY")}</p>;
      },
      // ...getColumnSearchProps(`coupon_start`)
    },
    {
      title: "Coupon End",
      dataIndex: "coupon_end",
      key: `${uuid()}`,
      className: "table",
      width: "10%",
      render: (x) => {
        return <p>{moment(x.coupon_end).format("MM-DD-YYYY")}</p>;
      },
      //   ...getColumnSearchProps("email")
    },
    {
      title: "Max Coupon Valid For",
      dataIndex: "max_coupon_usable_for",
      key: `${uuid()}`,
      className: "table",

      width: "10%",
      //   ...getColumnSearchProps("email")
    },
    {
      title: "Coupon Value",
      children: [
        {
          title: "Value A",
          dataIndex: "coupon_value",
          key: `${uuid()}`,
          className: "table",
          width: "10%",
        },
        {
          title: "Value B",
          dataIndex: "coupon_value_2",
          key: `${uuid()}`,
          className: "table",
          width: "10%",
        },
      ],
      //   ...getColumnSearchProps("email")
    },
    {
      title: "Delete",
      key: `${uuid()}`,
      className: "table",
      render: (x) => {
        return (
          <Popconfirm
            title="Are you sure"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => handelDelete(x.coupon_id, e)}
          >
            <DeleteOutlineOutlinedIcon
              cursor="pointer"
              style={{ color: "red" }}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const handelDelete = async (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        coupon_id: id,
        token,
      },
    };

    try {
      const body = { delete: "coupon" };
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/booking/coupon/delete",
        body,
        config
      );
      const data = await res;
      if (data.status === 200) {
        const filteredData = coupon.filter((item) => item.coupon_id !== id);
        setCoupon(filteredData);
        message.success("Successfully deleted coupon.");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong while deleting coupon.");
    }
  };

  return (
    <Fragment>
      <Card
        title={
          <Title level={2}>
            <Text strong>Coupons</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
        loading={loading ? <Skeleton active /> : null}
      >
        <Row>
          <Col lg={24} md={24}>
            <AntdTable columns={columns} dataSource={coupon} bordered />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}

export default CouponTable;
