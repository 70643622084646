import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { StepperPricingCreate } from "../../../../../../Redux/actions/stepper_actions";
import {
  TextField,
  Box,
  Grid,
  Paper,
  FormControl,
  Button,
  InputLabel,
  Select as MaterialSelect,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import {
  Form,
  Input,
  Button as Button1,
  Select as Select1,
  Divider,
  Radio,
  Card,
  InputNumber,
  Row,
  Col,
} from "antd";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker } from "antd";
import moment from "moment";

const nestedArray = [
  {
    name: "Additional Guests Allowed",
    value: "additional_guests_allowed",
    type: "checkbox",
  },
  { name: "Instant Booking", value: "instant_book", type: "checkbox" },

  { name: "Price Per Night", value: "price_night", type: "number" },
  { name: "Weekend Price", value: "weekends_price", type: "number" },
  { name: "Weekend Days", value: "weekend_days", type: "dropdown" },
  { name: "Weekly Discount", value: "discount_seven", type: "numberD" },
  { name: "Fortnightly Discount", value: "discount_fifteen", type: "numberD" },
  { name: "Monthly Discount", value: "discount_thirty", type: "numberD" },
  {
    name: "Additional Guests Number",
    value: "additional_guests",
    type: "number",
  },
  {
    name: "Additional Guests Amount",
    value: "additional_guests_amount",
    type: "string",
  },
  { name: "Cleaning Fee", value: "cleaning_fee", type: "number" },
  { name: "City Fee", value: "city_fee", type: "number" },
  { name: "Security Deposit", value: "security_deposit", type: "number" },
];

const PricingPage = () => {
  const dispatch = useDispatch();
  const [forms, changeForm] = useState({
    custom_period: [
      {
        start_date: "DD/MM/YYYY",
        end_date: "DD/MM/YYYY",
        price_per_night: "",
        weekends_price: "",
        weekend_days: [],
      },
    ],
  });

  function handleAdd(e) {
    let step_6Copy = JSON.parse(JSON.stringify(forms));
    step_6Copy.custom_period = [
      ...step_6Copy.custom_period,
      {
        start_date: "DD/MM/YYYY",
        end_date: "DD/MM/YYYY",
        price_per_night: "",
        weekends_price: "",
        weekend_days: [],
      },
    ];
    changeForm(step_6Copy);
  }

  function handleStartDate(e, i) {
    let start_date_copy = JSON.parse(JSON.stringify(forms));
    start_date_copy.custom_period[i].start_date = new Date(e);
    changeForm(start_date_copy);
  }

  function handleEndDate(e, i) {
    let end_date_copy = JSON.parse(JSON.stringify(forms));
    end_date_copy.custom_period[i].end_date = new Date(e);
    changeForm(end_date_copy);
  }

  function handlePriceNight(e, i) {
    let price_night_copy = JSON.parse(JSON.stringify(forms));
    price_night_copy.custom_period[i].price_per_night = parseInt(
      e.target.value
    );
    changeForm(price_night_copy);
  }

  function handleWeekendPrice(e, i) {
    let weekends_price_copy = JSON.parse(JSON.stringify(forms));
    weekends_price_copy.custom_period[i].weekends_price = parseInt(
      e.target.value
    );
    changeForm(weekends_price_copy);
  }

  function handleDays(e, i) {
    let weerknd_day_copy = JSON.parse(JSON.stringify(forms));
    weerknd_day_copy.custom_period[i].weekend_days = e;
    changeForm(weerknd_day_copy);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const dateFormat = "YYYY/MM/DD";

  const onFinish = (values) => {
    const dataTo = {
      ...values,
      custom_period: [...forms.custom_period],
    };
    dispatch(StepperPricingCreate(dataTo));
    console.log("values:", dataTo);
  };

  return (
    <Fragment>
      <Card style={{ margin: "15px", borderRadius: "5px" }}>
        <Row>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            {nestedArray.map((x) => {
              if (x.type === "string") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "numberD") {
                return (
                  <Col lg={8} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input.TextArea placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "checkbox") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <Form.Item
                      label={x.name}
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown") {
                return (
                  <Col lg={24} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name="weekend_days"
                      rules={[
                        {
                          required: true,
                          message: `Please input Weekend Days!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Weekend Days" mode="multiple">
                        <Select1.Option value={0}>Sunday</Select1.Option>
                        <Select1.Option value={1}>Monday</Select1.Option>
                        <Select1.Option value={2}>Tuesday</Select1.Option>
                        <Select1.Option value={3}>Wednesday</Select1.Option>
                        <Select1.Option value={4}>Thursday</Select1.Option>
                        <Select1.Option value={5}>Friday</Select1.Option>
                        <Select1.Option value={6}>Saturday</Select1.Option>
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Divider orientation="left">Seasonal Pricing</Divider>
            <div>
              {forms.custom_period.map((x, index) => (
                <Form layout="inline">
                  <Col lg={12} style={{ padding: "8px" }}>
                    <Form.Item
                      label="Start Date"
                      rules={[
                        {
                          required: true,
                          message: `Please input Number Of Guests!`,
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Start Date"
                        onChange={(value, dateString) =>
                          handleStartDate(value, index)
                        }
                        // defaultValue={moment(moment().toDate(), dateFormat)}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} style={{ padding: "8px" }}>
                    <Form.Item
                      label="End Date"
                      rules={[
                        {
                          required: true,
                          message: `Please input Number Of Guests!`,
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="End Date"
                        onChange={(value, dateString) =>
                          handleEndDate(dateString, index)
                        }
                        // defaultValue={moment(moment().toDate(), dateFormat)}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={7} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Price Per night</label>
                    </div>{" "}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: `Please input Price Per Night!`,
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        value={x.price_per_night}
                        placeholder="Please input Price Per Night"
                        onChange={(e) => handlePriceNight(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={7} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Weekend Price</label>
                    </div>{" "}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: `Please input Weekend Price!`,
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        value={x.weekends_price}
                        placeholder="Please input Weekend Price"
                        onChange={(e) => handleWeekendPrice(e, index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={7} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Weekend Days</label>
                    </div>{" "}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: `Please input Weekend Days!`,
                        },
                      ]}
                    >
                      <Select1
                        style={{ width: "100%" }}
                        placeholder="Weekend Days"
                        value={x.weekend_days}
                        onChange={(e) => handleDays(e, index)}
                        mode="multiple"
                      >
                        <Select1.Option value={0}>Sunday</Select1.Option>
                        <Select1.Option value={1}>Monday</Select1.Option>
                        <Select1.Option value={2}>Tuesday</Select1.Option>
                        <Select1.Option value={3}>Wednesday</Select1.Option>
                        <Select1.Option value={4}>Thursday</Select1.Option>
                        <Select1.Option value={5}>Friday</Select1.Option>
                        <Select1.Option value={6}>Saturday</Select1.Option>
                      </Select1>
                    </Form.Item>
                  </Col>
                  <Col
                    lg={3}
                    style={{
                      padding: "8px",
                      textAlign: "center",
                      marginTop: "1.3rem",
                    }}
                  >
                    <AddIcon onClick={handleAdd} cursor="pointer" />
                  </Col>
                </Form>
              ))}
            </div>
            <Form.Item>
              <Button type="primary" onClick={onFinish}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Card>{" "}
    </Fragment>
  );
};
export default PricingPage;
