import React, { useState, Fragment, useEffect, memo } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import {
  Form,
  Input,
  Select as Select1,
  Radio,
  Card,
  InputNumber,
  Row,
  Col,
  Skeleton,
} from "antd";
import { StepperLocationCreate } from "../../../../../../Redux/actions/stepper_actions";
import States from "../../ListingCreate/Location/states";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const Locations = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({});
  const [forms, changeForm] = useState({
    step: 4,
    step_4: {
      address: "",
      zip_code: "",
      country: "",
      state: "",
      latitude: "",
      longitude: "",
      minimum_days_book: "",
      maximum_days_book: "",
      check_in: "",
      check_out: "",
      smoking: "",
      party: "",
      pets: "",
      children: "",
      additional_rules: "",
    },
  });
  const [date, setDate] = useState({
    check_in: "",
    check_out: "",
  });
  const token = localStorage.getItem("token");
  const [stateVal, setStateVal] = useState("");

  async function fetchAPI() {
    setLoading(true);

    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/preview/4`,
        config
      );
      const data = await res.data;
      console.log("data", data);
      changeForm(data);
      setDate({
        check_in: data.step_4.check_in,
        check_out: data.step_4.check_out,
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchAPI();
  }, []);

  const nestedArray = [
    { name: "Address", value: "address", type: "textarea" },
    { name: "Zip Code", value: "zip_code", type: "number" },
    { name: "Country", value: "country", type: "dropdown" },
    { name: "State", value: "state", type: "dropdown" },
    { name: "Latitude", value: "latitude", type: "number" },
    { name: "Longitude", value: "longitude", type: "number" },
    {
      name: "Minimum Days Of Booking",
      value: "minimum_days_book",
      type: "number",
    },
    {
      name: "Maximum Days Of Booking",
      value: "maximum_days_book",
      type: "number",
    },
    { name: "Check In", value: "check_in", type: "dropdown1" },
    { name: "Check In", value: "check_out", type: "dropdown1" },
    { name: "Additional Rules", value: "additional_rules", type: "textarea" },
    { name: "Smoking", value: "smoking", type: "checkbox" },
    { name: "Party", value: "party", type: "checkbox" },
    { name: "Pets", value: "pets", type: "checkbox" },
    { name: "Children", value: "children", type: "checkbox" },
  ];

  const onFinish = (values) => {
    const {
      address,
      zip_code,
      country,
      state,
      latitude,
      longitude,
      minimum_days_book,
      maximum_days_book,
      check_in,
      check_out,
      smoking,
      party,
      pets,
      children,
      additional_rules,
    } = values;
    const dataTo = {
      step: 4,
      step_4: {
        address,
        zip_code,
        country,
        state: stateVal,
        latitude,
        longitude,
        minimum_days_book,
        maximum_days_book,
        check_in,
        check_out,
        smoking,
        party,
        pets,
        children,
        additional_rules,
      },
    };
    console.log(dataTo);
    dispatch(StepperLocationCreate(dataTo));
  };

  const {
    address,
    zip_code,
    country,
    latitude,
    longitude,
    minimum_days_book,
    maximum_days_book,
    check_in,
    check_out,
    smoking,
    party,
    pets,
    children,
    additional_rules,
  } = forms.step_4 ? forms.step_4 : "";

  const onFinishFailed = (errorInfo) => {
    console.log(forms);
    console.log("Failed:", errorInfo);
  };

  const getStates = (val) => {
    setStateVal(val);
  };

  let check_in_drpdwn = [];

  for (let i = 1; i < 13; i++) {
    check_in_drpdwn.push(`${i} am`);
  }
  for (let i = 1; i < 13; i++) {
    check_in_drpdwn.push(`${i} pm`);
  }

  const dateFormat = "YYYY/MM/DD";
  return (
    <Fragment>
      <Card
        style={{ margin: "15px", borderRadius: "5px" }}
        loading={loading ? <Skeleton active /> : null}
      >
        <Row>
          <Form
            initialValues={{
              address,
              zip_code,
              country,
              latitude,
              longitude,
              minimum_days_book,
              maximum_days_book,
              check_in,
              check_out,
              smoking,
              party,
              pets,
              children,
              additional_rules,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            {nestedArray.map((x) => {
              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px" }} key={x.name}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input.TextArea placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col
                    lg={12}
                    style={{ padding: "8px", textAlign: "center" }}
                    key={x.name}
                  >
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown") {
                if (x.value === "state") {
                  return (
                    <Col lg={12} style={{ padding: "8px" }}>
                      <States getStatesVal={getStates} stateVal={stateVal} />
                    </Col>
                  );
                } else {
                  return (
                    <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                      <div style={{ textAlign: "left" }}>
                        <label>{x.name}</label>
                      </div>{" "}
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: `Please input Country!`,
                          },
                        ]}
                      >
                        <Select1 placeholder="Country">
                          <Select1.Option value="India">India</Select1.Option>
                          <Select1.Option value="usa">USA</Select1.Option>
                          <Select1.Option value="uk">UK</Select1.Option>
                          <Select1.Option value="russia">Russia</Select1.Option>
                        </Select1>
                      </Form.Item>
                    </Col>
                  );
                }
              }
              if (x.type === "dropdown1") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item name={x.value}>
                      <Select1 placeholder={x.name}>
                        {check_in_drpdwn.map((x, i) => (
                          <Select1.Option key={i} value={x.split(" ")[0]}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "checkbox") {
                return (
                  <Col lg={6} style={{ padding: "8px" }} key={x.name}>
                    <Form.Item
                      label={x.name}
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "string") {
                return (
                  <Col lg={12} style={{ padding: "8px", textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>{" "}
                    <Form.Item
                      name={x.value}
                      // value={}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
            })}

            <Col lg={24}>
              <Form.Item>
                <Button type="submit" htmlype="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Card>
    </Fragment>
  );
};

export default memo(Locations);
