import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Button,
  Divider,
  Skeleton,
  AutoComplete,
  Select,
  Input,
  Popconfirm,
  Table as AntdTable,
} from "antd";
import Highlighter from "react-highlight-words";

import { SearchOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { Option } = Select;

const OtaSync = ({ history }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [ota, setOta] = useState([]);
  const [property, setProperty] = useState([]);
  const [form, setForm] = useState({
    ota_name: "",
    ota_id: "",
    path_to_property: "",
    property_id: "",
  });

  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAPI();
    getOtas();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/sync_master/list/100/0",
        config
      );
      const data = await res.data;
      setData(data.responses_);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getOtas = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/ota_master/list",
        config
      );
      const data = await res.data;
      setOta(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/sync_master/delete",
        { sync_id: id },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success("Successfully deleted OTA.");
        fetchAPI();
      } else {
        message.success("Error while deleting OTA.");
      }
    } catch (err) {
      console.log(err);
      message.success("Error while deleting OTA.");
    }
    setLoading(false);
  };

  const onFinish = async () => {
    setButtonLoading(true);
    console.log(form, "submitting");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/sync_master",
        form,
        config
      );
      const data = await res.data;
      console.log(data);
      if (data.status === 200) {
        message.success("OTA added successfully.");
        fetchAPI();
      }
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeOTA = (name) => {
    let getOta_id = "";
    if (name) {
      getOta_id = ota.filter((x) => x.ota_name === name)[0].ota_id;
    }
    setForm({ ota_name: name, ota_id: getOta_id });
  };

  const onChangeProperty = async (name) => {
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/public/listing/search/${name}`
      );
      const data = await res.data;
      console.log(data, "dfdaf");
      setProperty(data);
    } catch (err) {
      console.log(err);
    }

    const getId = property.filter((x) => x.property_title === name);
    const id = getId.length > 0 ? getId[0].listing_id : "";
    setForm({ ...form, property_id: id });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "OTA Name",
      dataIndex: "ota_name",
      key: "ota_name",
      className: "table",
      width: "12%",
      ...getColumnSearchProps("ota_name"),
    },
    {
      title: "Sync ID",
      dataIndex: "sync_id",
      key: "sync_id",
      className: "table",

      width: "15%",
      ...getColumnSearchProps("sync_id"),
    },
    {
      title: "Path to Property",
      dataIndex: "path_to_property",
      key: "path_to_property",
      className: "table",
      ...getColumnSearchProps("path_to_property"),
    },
    {
      title: "Property Title",
      dataIndex: "property",
      className: "table",
      key: "property",
      ...getColumnSearchProps("property"),
      render: (x) => {
        return <span>{x[0].property_title}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      className: "table",

      render: (x) => {
        return (
          <Row justify="space-between">
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(x.sync_id)}
            >
              <Button danger>Delete</Button>{" "}
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Row justify="center">
        <Col lg={24}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>OTA SYNC</Text>
              </Title>
            }
            bordered={true}
          >
            <Row>
              <Col lg={24}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <Col lg={6} style={{ margin: "8px" }}>
                    <AutoComplete
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Select OTA to sync"
                      onChange={(e) => onChangeOTA(e)}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    >
                      {ota.map((x, i) => {
                        return (
                          <Option key={i} value={x.ota_name}>
                            {x.ota_name}
                          </Option>
                        );
                      })}
                    </AutoComplete>
                  </Col>
                  <Col lg={6} style={{ margin: "8px" }}>
                    <Input
                      size="large"
                      name="path_to_property"
                      placeholder="Enter path to property"
                      onChange={(e) =>
                        setForm({ ...form, path_to_property: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg={6} style={{ margin: "8px" }}>
                    <AutoComplete
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Select Property"
                      onChange={onChangeProperty}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    >
                      {property.map((x, i) => {
                        return (
                          <Option key={i} value={x.property_title}>
                            {x.property_title}
                          </Option>
                        );
                      })}
                    </AutoComplete>
                  </Col>
                  <Form.Item style={{ margin: "8px" }}>
                    <Button
                      htmlType="submit"
                      size="large"
                      loading={buttonLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Divider />
            {loading ? (
              <Skeleton active />
            ) : (
              <AntdTable columns={columns} dataSource={data} bordered />
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(OtaSync);
