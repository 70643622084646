import React from "react";
import { Col, Row, Button } from "antd";
import { Link } from "react-router-dom";
function Shortcut() {
  const user = localStorage.getItem("user_level");

  return (
    <div className="container-wrapper">
      <div className="shortcut-wrapper">
        <Col>
          <h2>Shortcuts</h2>
        </Col>
        <Row justify="space-around">
          <Col className="last-btn" lg={{ span: 6 }} xs={{ span: 22 }}>
            <Link to={`/dashboard/${user}/create-user`}>
              <Button type="primary">Create User</Button>
            </Link>
          </Col>
          <Col className="last-btn" lg={{ span: 6 }} xs={{ span: 22 }}>
            <Link to={`/dashboard/${user}/create/coupons`}>
              <Button type="primary">Create Coupons</Button>
            </Link>
          </Col>
          <Col className="last-btn" lg={{ span: 6 }} xs={{ span: 22 }}>
            <Link to={`/dashboard/${user}/create-listing`}>
              <Button type="primary">Create Listing</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Shortcut;
