import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  UserSwitchOutlined,
  UnorderedListOutlined,
  FileAddOutlined,
  MessageOutlined,
  CalendarOutlined,
  MoneyCollectOutlined,
  TagsOutlined,
  ReadOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Logo from "../../../../assets/images/black_logo.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ toggle }) => {
  const user = localStorage.getItem("user_level");
  return (
    <Sider trigger={null} collapsible collapsed={toggle} className="sidebar">
      <div className="logo d-flex">
        <img
          src={Logo}
          alt="logo"
          height="30px"
          style={{ marginRight: "0.5rem" }}
        />
        {!toggle ? <h1 style={{ marginTop: "0.2rem" }}>Dashboard</h1> : ""}
      </div>
      <Menu mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="Home">
          <Link to={`/dashboard/${user}/home`}>
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="Users"
          title={
            <span>
              <UserSwitchOutlined />
              <span>Users</span>
            </span>
          }
        >
          <Menu.Item key="create-user">
            <Link to={`/dashboard/${user}/create-user`}>Create User</Link>
          </Menu.Item>
          <Menu.Item key="list-users">
            <Link to={`/dashboard/${user}/list-user`}>Check All Users</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Listings"
          title={
            <span>
              <UnorderedListOutlined />
              <span>Listing</span>
            </span>
          }
        >
          <Menu.Item key="create-listings">
            <Link to={`/dashboard/${user}/create-listing`}>Create Listing</Link>
          </Menu.Item>
          <Menu.Item key="listings">
            <Link to={`/dashboard/${user}/listing`}>Check All Listings</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Coupons"
          title={
            <span>
              <TagsOutlined />
              <span>Promotions</span>
            </span>
          }
        >
          <Menu.Item key="create-coupons">
            <Link to={`/dashboard/${user}/create/coupons`}>
              Create Promotions
            </Link>
          </Menu.Item>
          <Menu.Item key="listings">
            <Link to={`/dashboard/${user}/coupons`}>Check All Promotions</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="Create"
          title={
            <span>
              <FileAddOutlined />
              <span>Create</span>
            </span>
          }
        >
          <Menu.Item key="create-ammenities">
            <Link to={`/dashboard/${user}/create/ammenities`}>
              Create Ammenities
            </Link>
          </Menu.Item>
          <Menu.Item key="create-facilities">
            <Link to={`/dashboard/${user}/create/facilities`}>
              Create Facilities
            </Link>
          </Menu.Item>
          <Menu.Item key="create-location">
            <Link to={`/dashboard/${user}/create/location`}>
              Create Location
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Tax"
          title={
            <span>
              <MoneyCollectOutlined />
              <span>Tax</span>
            </span>
          }
        >
          <Menu.Item key="create-tax">
            <Link to={`/dashboard/${user}/create/tax`}>Create Tax</Link>
          </Menu.Item>
          <Menu.Item key="taxes">
            <Link to={`/dashboard/${user}/taxes`}>Check All Taxes</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="GST"
          title={
            <span>
              <UnorderedListOutlined />
              <span>GST</span>
            </span>
          }
        >
          <Menu.Item key="create-gst">
            <Link to={`/dashboard/${user}/gst/create`}>Create GST</Link>
          </Menu.Item>
          <Menu.Item key="gst">
            <Link to={`/dashboard/${user}/gst`}>Check GST List</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="OTA"
          title={
            <span>
              <UnorderedListOutlined />
              <span>OTA</span>
            </span>
          }
        >
          <Menu.Item key="create-ota">
            <Link to={`/dashboard/${user}/ota/master`}>OTA Master</Link>
          </Menu.Item>
          <Menu.Item key="ota">
            <Link to={`/dashboard/${user}/ota/sync`}>OTA Sync</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="Booking"
          title={
            <span>
              <CalendarOutlined />
              <span>Booking</span>
            </span>
          }
        >
          <Menu.Item key="create-booking">
            <Link to={`/dashboard/${user}/booking/create`}>Create Booking</Link>
          </Menu.Item>
          <Menu.Item key="confirmed">
            <Link to={`/dashboard/${user}/booking/confirmed`}>
              Confirmed Bookings
            </Link>
          </Menu.Item>
          <Menu.Item key="pending">
            <Link to={`/dashboard/${user}/booking/pending`}>
              Payment Pending
            </Link>
          </Menu.Item>
          <Menu.Item key="cancelled">
            <Link to={`/dashboard/${user}/booking/cancelled`}>
              Cancelled Bookings
            </Link>
          </Menu.Item>
          <Menu.Item key="waiting">
            <Link to={`/dashboard/${user}/booking/waiting`}>
              Waiting for Approval
            </Link>
          </Menu.Item>
        </SubMenu>

        {/* <Menu.Item key="payout-list">
          <CalendarOutlined />
          <Link to={`/dashboard/${user}/payout`}>
            <span>Payouts</span>
          </Link>
        </Menu.Item> */}

        <Menu.Item key="chat">
          <Link to={`/dashboard/${user}/published-listings`}>
            <MessageOutlined />
            <span>Published Listings</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="stay">
          <Link to={`/dashboard/${user}/stay-guide`}>
            <UserOutlined />
            <span>Stay Guide</span>
          </Link>
        </Menu.Item> */}

        {/* <SubMenu
          key="cms"
          title={
            <span>
              <ReadOutlined />
              <span>CMS</span>
            </span>
          }
        >
          <Menu.Item key="cms-create">
            <Link to={`/dashboard/${user}/cms/create`}>
              <span>Create content</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="cms-list">
            <Link to={`/dashboard/${user}/cms`}>
              <span>View content</span>
            </Link>
          </Menu.Item>
        </SubMenu> */}

        <Menu.Item key="labels">
          <Link to={`/dashboard/${user}/labels`}>
            <HomeOutlined />
            <span>Lables</span>
          </Link>
        </Menu.Item>

        <SubMenu
          key="testimonials"
          title={
            <span>
              <SoundOutlined />
              <span>Testimonials</span>
            </span>
          }
        >
          <Menu.Item key="testimonials-create">
            <Link to={`/dashboard/${user}/testimonials/create`}>
              <span>Create</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="testimonials-view">
            <Link to={`/dashboard/${user}/testimonials`}>
              <span>View All</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
