import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import Calendar from "../../components/Calendar";
import axios from "axios";

const Home = () => {
  const [state, setState] = useState({});
  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/dashboard`,
        config
      );
      const data = res.data;
      if (res.status === 200) {
        message.success("content Loaded Successfully.");
        console.log(data, "dashbord adat");
        setState(data);
      }
    } catch (error) {
      message.error("Network error, please try again later.");
    }
  };

  const date = new Date(); // 2009-11-10
  const month = date.toLocaleString("default", { month: "long" });
  return (
    <div>
      <Row justify="space-around">
        <Col xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>2020 Earnings</h1>

          <div className="col">
            <h1>Rs. {state.total_billed}</h1>
          </div>
        </Col>

        <Col xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>{month} Earnings</h1>
          <div className="col">
            <h1>Rs. {state.commision}</h1>
          </div>
        </Col>

        <Col xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>{month} Paid Out</h1>
          <div className="col">
            <h1>Rs. {state.total_billed - state.commision}</h1>
          </div>
        </Col>

        <Col xs={{ span: 22 }} lg={{ span: 5 }}>
          <h1>No.of Bookings</h1>
          <div className="col">
            <h1>{state.no_of_bookings}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Calendar />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
