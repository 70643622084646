import React, { useEffect, Fragment, memo, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { StepperBasicEdit } from "../../../../../../Redux/actions/stepper_actions";
import {
  Form,
  Input,
  Select as Select1,
  Divider,
  Card,
  InputNumber,
  Row,
  Col,
  Skeleton,
  Typography,
  message,
} from "antd";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Text, Title } = Typography;

const Basic = () => {
  const [pdesc, setPdesc] = useState("");

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(true);
  const [forms, changeForm] = useState({
    step: 1,
    author: "",
    author_id: "",
    seo_title: "",
    keywords: "",
    seo_description: "",
    step_1: {
      property_title: "",
      type_property: "",
      property_desc: "",
      type_of_listing: "",
      num_of_bedroom: "",
      num_of_guests: "",
      num_of_beds: "",
      num_of_bathrooms: "",
      num_of_rooms: "",
      size_of_rooms: "",
      unit_of_measure: "",
    },
  });

  const array = [
    // { name: "Author", value: "author" },
    { name: "Author ID", value: "author_id" },
    { name: "SEO Title", value: "seo_title" },
    // { name: "Keywords", value: "keywords" },
    { name: "SEO Description", value: "seo_description" },
  ];

  const nestedArray = [
    { name: "Property Title", value: "property_title", type: "string" },
    { name: "Proprrty Type", value: "type_property", type: "dropdown" },
    { name: "Property Description", value: "property_desc", type: "textarea" },
    { name: "Type Of Listing", value: "type_of_listing", type: "dropdown1" },
    { name: "Number Of Bedrooms", value: "num_of_bedroom", type: "number" },
    { name: "Number Of Guests", value: "num_of_guests", type: "number" },
    { name: "Number Of Beds", value: "num_of_beds", type: "number" },
    { name: "Number Of Bathrooms", value: "num_of_bathrooms", type: "number" },
    { name: "Number Of Rooms", value: "num_of_rooms", type: "number" },
    { name: "Size Of Property", value: "size_of_rooms", type: "number" },
    {
      name: "Measurement Of Room",
      value: "unit_of_measure",
      type: "dropdown2",
    },
  ];

  const prop_type_vals = [
    "Apartment",
    "Bed & Breakfast",
    "Boutique Room",
    "Cabin",
    "Castle / Fortress / Palace",
    "Cottage",
    "Farmhouse",
    "Hotel",
    "House",
    "Island",
    "Lodge",
    "Studio",
    "Tent",
    "Villa",
    "Tree House",
  ];

  const unit_of_measureVal = ["sq/ft", "sq/m"];

  const type_of_listings = ["Entire Place", "Shared", "Private Room"];

  async function fetchAPI() {
    setLoading(true);
    const config = {
      headers: {
        listing_id: localStorage.getItem("listing_id"),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/listing/create_draft/preview/1`,
        config
      );
      const data = await res.data;
      console.log(data, "data");
      changeForm(data);
      setPdesc(data.step_1.property_desc);
    } catch (e) {
      console.log(e);
      message.error("Something went wrong with our server.");
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchAPI();
  }, []);

  const onFinish = (values) => {
    const {
      author,
      author_id,
      seo_description,
      seo_title,
      keywords,
      property_title,
      type_property,
      type_of_listing,
      num_of_bathrooms,
      num_of_bedroom,
      num_of_beds,
      num_of_guests,
      num_of_rooms,
      unit_of_measure,
      size_of_rooms,
    } = values;
    const dataTo = {
      step: 1,
      author,
      author_id,
      seo_title,
      keywords,
      seo_description,
      step_1: {
        property_title,
        type_property,
        property_desc: pdesc,
        type_of_listing,
        num_of_bedroom,
        num_of_guests,
        num_of_beds,
        num_of_bathrooms,
        num_of_rooms,
        size_of_rooms,
        unit_of_measure,
      },
    };
    dispatch(StepperBasicEdit(dataTo));
    console.log(dataTo);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const { author, author_id, seo_description, seo_title, keywords } = forms;
  const {
    property_title,
    type_property,
    type_of_listing,
    num_of_bathrooms,
    num_of_bedroom,
    num_of_beds,
    num_of_guests,
    num_of_rooms,
    unit_of_measure,
    size_of_rooms,
  } = forms.step_1;

  return (
    <Fragment>
      <Card
        title={
          <Title level={2}>
            <Text strong>Edit Listing</Text>
          </Title>
        }
        style={{ margin: "15px", borderRadius: "5px", textAlign: "center" }}
        loading={loading ? <Skeleton active /> : null}
      >
        <Row>
          <Form
            initialValues={{
              author,
              author_id,
              seo_description,
              seo_title,
              keywords,
              property_title,
              type_property,
              type_of_listing,
              num_of_bathrooms,
              num_of_bedroom,
              num_of_beds,
              num_of_guests,
              num_of_rooms,
              unit_of_measure,
              size_of_rooms,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="inline"
          >
            {array.map((x) => {
              return (
                <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                  <div style={{ textAlign: "left" }}>
                    <label>{x.name}</label>
                  </div>
                  <Form.Item
                    name={x.value}
                    rules={[
                      {
                        required: true,
                        message: `Please input ${x.name}!`,
                      },
                    ]}
                  >
                    <Input placeholder={x.name} />
                  </Form.Item>
                </Col>
              );
            })}

            <Divider />
            {nestedArray.map((x) => {
              if (x.type === "string") {
                return (
                  <Col
                    lg={12}
                    style={{ padding: "8px", textAlign: "center" }}
                    key={x.name}
                  >
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <Input placeholder={x.name} />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "number") {
                return (
                  <Col lg={12} style={{ padding: "8px" }} key={x.name}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={x.name}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "textarea") {
                return (
                  <Col lg={24} style={{ padding: "8px" }} key={x.name}>
                    <div style={{ textAlign: "left" }}>
                      <label>{x.name}</label>
                    </div>
                    {/* <Form.Item
                      name={x.value}
                      rules={[
                        {
                          required: true,
                          message: `Please input ${x.name}!`,
                        },
                      ]}
                    > */}
                    <CKEditor
                      editor={ClassicEditor}
                      data={pdesc}
                      onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "undo",
                          "redo",
                        ],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setPdesc(data);
                        console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                    {/* </Form.Item> */}
                  </Col>
                );
              }

              if (x.type === "dropdown") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Type of Property</label>
                    </div>
                    <Form.Item
                      name="type_property"
                      rules={[
                        {
                          required: true,
                          message: `Please input type_property!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Type OF Listing">
                        {prop_type_vals.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }

              if (x.type === "dropdown1") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Type of Listing</label>
                    </div>
                    <Form.Item
                      name="type_of_listing"
                      rules={[
                        {
                          required: true,
                          message: `Please input type_of_listing!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="Type OF Listing">
                        {type_of_listings.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
              if (x.type === "dropdown2") {
                return (
                  <Col lg={12} style={{ padding: "8px" }}>
                    <div style={{ textAlign: "left" }}>
                      <label>Unit of Measurement</label>
                    </div>
                    <Form.Item
                      name="unit_of_measure"
                      rules={[
                        {
                          required: true,
                          message: `Please input unit_of_measure!`,
                        },
                      ]}
                    >
                      <Select1 placeholder="unit of measure">
                        {unit_of_measureVal.map((x, i) => (
                          <Select1.Option key={i} value={x}>
                            {x}
                          </Select1.Option>
                        ))}
                      </Select1>
                    </Form.Item>
                  </Col>
                );
              }
            })}
            <Form.Item>
              <Button type="submit" htmltype="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Card>
    </Fragment>
  );
};

export default memo(Basic);
