import {
  STEPPER_GET_ALL,
  STEPPER_GET_ALL_FAIL,
  STEPPER_CREATE_BASIC,
  STEPPER_CREATE_BASIC_FAIL,
  STEPPER_GET_BASIC,
  STEPPER_CREATE_AMMENITIES,
  STEPPER_GET_AMMENITIES,
  STEPPER_GET_AMMENITIES_FAIL,
  STEPPER_CREATE_MEDIA,
  STEPPER_CREATE_MEDIA_FAIL,
  STEPPER_GET_MEDIA,
  STEPPER_GET_MEDIA_FAIL,
  STEPPER_GET_BASIC_FAIL,
  STEPPER_CREATE_BEDROOMS,
  STEPPER_CREATE_BEDROOMS_FAIL,
  STEPPER_GET_BEDROOMS,
  STEPPER_GET_BEDROOMS_FAIL,
  STEPPER_CREATE_LOCATION,
  STEPPER_GET_LOCATION,
  STEPPER_CREATE_LOCATION_FAIL,
  STEPPER_GET_LOCATION_FAIL,
  STEPPER_GET_PRICING,
  STEPPER_CREATE_PRICING,
  STEPPER_GET_PRICING_FAIL,
  STEPPER_CREATE_PRICING_FAIL,
  STEPPER_INDEX,
  STEPPER_EDIT_BASIC
} from "../actions/types";
import edit from "../../Utils/misc";

export const initialState = {
  data: [],
  deleteSucces: [],
  StepperShowData: {}
};
export default function(state = [], action) {
  switch (action.type) {
    case STEPPER_GET_ALL:
      return [...state, ...action.payload];

    case STEPPER_INDEX:
      return [...state, ...action.payload];

    case STEPPER_GET_ALL_FAIL:
    case STEPPER_CREATE_BASIC_FAIL:
    case STEPPER_GET_BASIC_FAIL:
    case STEPPER_GET_AMMENITIES_FAIL:
    case STEPPER_CREATE_MEDIA_FAIL:
    case STEPPER_GET_MEDIA_FAIL:
    case STEPPER_CREATE_BEDROOMS_FAIL:
    case STEPPER_GET_BEDROOMS_FAIL:
    case STEPPER_CREATE_LOCATION_FAIL:
    case STEPPER_GET_LOCATION_FAIL:
    case STEPPER_GET_PRICING_FAIL:
    case STEPPER_CREATE_PRICING_FAIL:
      return { ...state, error: true, loading: false };

    case STEPPER_CREATE_BASIC:
    case STEPPER_GET_BASIC:
      edit(action.payload.headers.listing_id);
      localStorage.setItem("listing_id", action.payload.headers.listing_id);
      return { ...state, StepperBasicData: action.payload, loading: false };

    case STEPPER_EDIT_BASIC:
      edit(action.payload.headers.listing_id);
      localStorage.setItem("listing_id", action.payload.headers.listing_id);
      return { ...state, StepperBasicData: action.payload, loading: false };

    case STEPPER_CREATE_AMMENITIES:
    case STEPPER_GET_AMMENITIES:
      return {
        ...state,
        StepperAmmenitiesData: action.payload,
        loading: false
      };

    case STEPPER_CREATE_MEDIA:
    case STEPPER_GET_MEDIA:
      return { ...state, StepperMediaData: action.payload, loading: false };

    case STEPPER_CREATE_BEDROOMS:
    case STEPPER_GET_BEDROOMS:
      return { ...state, StepperBedroomData: action.payload, loading: false };

    case STEPPER_CREATE_LOCATION:
    case STEPPER_GET_LOCATION:
      return { ...state, StepperLocationData: action.payload, loading: false };

    case STEPPER_GET_PRICING:
    case STEPPER_CREATE_PRICING:
      return { ...state, StepperPricingData: action.payload, loading: false };
    default:
      return state;
  }
}
