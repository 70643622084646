import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  UserSwitchOutlined,
  UnorderedListOutlined,
  FileAddOutlined,
  MessageOutlined,
  CalendarOutlined,
  MoneyCollectOutlined,
  TagsOutlined,
  ReadOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Logo from "../../../../assets/images/black_logo.svg";

const { Sider } = Layout;

const Sidebar = ({ toggle }) => {
  const user = localStorage.getItem("user_level");
  return (
    <Sider trigger={null} collapsible collapsed={toggle} className="sidebar">
      <div className="logo d-flex">
        <img
          src={Logo}
          alt="logo"
          height="30px"
          style={{ marginRight: "0.5rem" }}
        />
        {!toggle ? <h1 style={{ marginTop: "0.2rem" }}>Dashboard</h1> : ""}
      </div>
      <Menu mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="Home">
          <Link to={`/dashboard/${user}/home`}>
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="listings">
          <Link to={`/dashboard/${user}/listing`}>
            <span>
              <UnorderedListOutlined />
            </span>
            Live Listings
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
