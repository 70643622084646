import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import moment from "moment";
import {
  Table as AntdTable,
  Input,
  Button as Button1,
  Tooltip,
  Row,
  Col,
  message,
  Skeleton,
  Card,
  Typography,
  Badge,
  Button,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const { Text, Title } = Typography;

const GstTable = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPost] = useState([]);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
    searchInput: "",
  });
  const token = localStorage.getItem("token");

  const [verify] = useState({
    banned: false,
  });

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    try {
      const res = await axios.get(
        `http://18.217.42.86:8000/api/v1/private/misc/gst_master/list`,
        config
      );
      const data = await res.data;

      setPost(res.data);
      console.log(data, "data");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    message.success("GST List Loaded Successfully.");
  };

  const handleStatus = async (id, status) => {
    console.log(id, status, "i am testing");
    const dataTo = {
      gstin: id,
      boolen: !status,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    console.log(dataTo, "dato");
    const res = await axios.post(
      "http://18.217.42.86:8000/api/v1/private/misc/gst_master/status",
      dataTo,
      config
    );
    const data = await res.data;
    console.log(data);
    fetchAPI();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            state.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button1
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button1>
        <Button1
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button1>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => state.searchInput.select());
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "GST No",
      dataIndex: "gstin",
      key: "gstin",
      className: "table",
      width: "10%",
      ...getColumnSearchProps("gstin"),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      className: "table",
      width: "10%",
      ...getColumnSearchProps("country"),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      className: "table",
      width: "10%",
      ...getColumnSearchProps("state"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      className: "table",
      width: "20%",
      ...getColumnSearchProps("address"),
    },
    {
      title: "Date Added",
      className: "table",
      width: "15%",
      render: (x) => {
        return <p>{moment(x.date_added).format("MM-DD-YYYY")}</p>;
      },
      // ...getColumnSearchProps(`coupon_start`)
    },
    {
      title: "Added by",
      dataIndex: "added_by_user",
      key: "added_by_user",
      className: "table",
      width: "15%",
      ...getColumnSearchProps("added_by_user"),
    },
    {
      title: "Status",
      key: "status",
      className: "table",
      render: (x) => {
        if (x.active) {
          return (
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleStatus(x.gstin, x.active)}
            >
              <Tooltip placement="top" title="Deactivate">
                <Button>
                  <Badge status="success" text="Active" />
                </Button>
              </Tooltip>
            </Popconfirm>
          );
        } else {
          return (
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleStatus(x.gstin, x.active)}
            >
              <Tooltip placement="top" title="Activate">
                <Button>
                  <Badge status="error" text="Inactive" />
                </Button>
              </Tooltip>
            </Popconfirm>
          );
        }
      },
    },
  ];

  return (
    <Fragment>
      <Card
        loading={loading ? <Skeleton active /> : null}
        style={{
          margin: "15px",
          borderRadius: "5px",
        }}
        title={
          <Title level={2} style={{ textAlign: "center" }}>
            <Text strong>GST List</Text>
          </Title>
        }
      >
        <Row>
          <Col lg={24} md={24}>
            <AntdTable columns={columns} dataSource={posts} bordered />
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default GstTable;
