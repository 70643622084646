import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Button,
  Divider,
  Skeleton,
  Select,
  Input,
  DatePicker,
} from "antd";

import moment from "moment";

const { Text, Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const BlockDates = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [property, setProperty] = useState([]);

  const [form, setForm] = useState({
    date_to: "",
    date_from: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        "http://18.217.42.86:8000/api/v1/private/misc/homeowner/block_dates/list",
        config
      );
      const data = await res.data;
      if (data) {
        setData(data);
      } else {
        message.error(data.remarks);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleDelete = async (event_id, property_id) => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/homeowner/block_dates/delete",
        { event_id, property_id },
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success("Successfully deleted.");
        fetchAPI();
      } else {
        message.error(data.remarks);
      }
    } catch (err) {
      console.log(err);
      message.success("Error while deleting OTA.");
    }
    setLoading(false);
  };

  const onFinish = async () => {
    const dataTo = {
      ...form,
      property_id: props.match.params.property_id,
    };
    setButtonLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };

    console.log(dataTo, "dataTo");

    try {
      const res = await axios.post(
        "http://18.217.42.86:8000/api/v1/private/misc/homeowner/block_dates/add",
        dataTo,
        config
      );
      const data = await res.data;
      console.log(data);
      if (data.status === 200) {
        message.success("Dates Blocked successfully.");
        fetchAPI();
      }
      message.error(data.remarks);
    } catch (err) {
      console.log(err);
      message.error("OOPS! Something went wrong.");
    }
    setButtonLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const dateFormat = "YYYY/MM/DD";

  const handleDate = (d) => {
    setForm({ ...form, date_from: d[0], date_to: d[1] });
  };

  return (
    <Fragment>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Col lg={24}>
          <Card
            style={{ margin: "15px", borderRadius: "5px" }}
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>Block Dates</Text>
              </Title>
            }
            bordered={true}
          >
            <Row justify="center">
              <Col lg={24}>
                <Form
                  onFinish={onFinish}
                  layout="inline"
                  onFinishFailed={onFinishFailed}
                >
                  <Row align="middle" justify="space-around">
                    <Col lg={18}>
                      <RangePicker
                        format={dateFormat}
                        size="large"
                        onChange={(val, dateString) => handleDate(dateString)}
                      />
                    </Col>
                    <Col lg={2}>
                      <Form.Item style={{ margin: "8px" }}>
                        <Button
                          htmlType="submit"
                          size="large"
                          loading={buttonLoading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Divider />
            {loading ? (
              <Skeleton active />
            ) : (
              <Row>
                {data.length > 0
                  ? data.map((x, i) => {
                      return (
                        <Col lg={24} key={i}>
                          <Row
                            style={{
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid",
                              borderRadius: "5px",
                              marginBottom: "1rem",
                            }}
                          >
                            <Col lg={6} style={{ fontSize: "15px" }}>
                              <Text>
                                {" "}
                                <b> #{x.property_id}</b>
                              </Text>{" "}
                            </Col>

                            <Col lg={4} style={{ fontSize: "15px" }}>
                              <Text> {x.ota_type_name}</Text>{" "}
                            </Col>

                            <Col lg={6}>
                              <Text>
                                {" "}
                                From :{" "}
                                <b> {moment(x.ota_date_from).format("ll")} </b>
                              </Text>{" "}
                            </Col>
                            <Col lg={6}>
                              <Text>
                                {" "}
                                To :{" "}
                                <b> {moment(x.ota_date_to).format("ll")} </b>
                              </Text>{" "}
                            </Col>
                            <Col
                              lg={2}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                danger
                                onClick={() =>
                                  handleDelete(x.event_id, x.property_id)
                                }
                              >
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })
                  : "No records found!"}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(BlockDates);
